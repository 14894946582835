import { createSlice } from '@reduxjs/toolkit';

export interface IPointHistoryParams {
  agentId: string;
  isIncludeSubAgent: boolean;
  startDate: string;
  endDate: string;
  transId: string;
  type: string;
  subType: string[];
}

export interface PointInitialState {
  dataPoint: IPointHistoryParams | null;
}

const initialState: PointInitialState = { dataPoint: null };
export const pointSlice = createSlice({
  name: 'point',
  initialState: initialState,
  reducers: {
    setDataPoint(state, action) {
      state.dataPoint = action.payload;
    },
  },
});

export const { setDataPoint } = pointSlice.actions;

export default pointSlice.reducer;
