import { IHeaderParams } from 'ag-grid-community';
import React, { FC } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useAppDispatch, useAppSelector } from '@/hooks/hooks';
import { limitCheckbox } from '@/pages/limit/store/limitSlice';

const CheckboxRender: FC<IHeaderParams> = (params) => {
  const dispatch = useAppDispatch();
  const { isCheckbox } = useAppSelector((state) => state.limit);
  const clickESpan = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(limitCheckbox(e.target.checked));
    params.api.forEachNode((node) => node.setSelected(e.target.checked));
  };

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox size="small" onChange={clickESpan} checked={isCheckbox} />}
          label={<span style={{ fontWeight: '700', fontSize: '13px' }}>{params.displayName}</span>}
        />
      </FormGroup>
    </>
  );
};

export default CheckboxRender;
