import { IParamsFilterUserList, IParamsTransactionHistoryList } from '@/interfaces';

import { createSlice } from '@reduxjs/toolkit';

export interface UserState {
  paramsFilter: Partial<IParamsFilterUserList> | null;
  paramsFilerTransactionHistory: Partial<IParamsTransactionHistoryList> | null;
}

const initialState: UserState = { paramsFilter: null, paramsFilerTransactionHistory: null };
export const userSlice = createSlice({
  name: 'users',
  initialState: initialState,
  reducers: {
    setParamsFilter(state, action) {
      state.paramsFilter = action.payload;
    },
    setParamsFilterHistoryTransaction(state, action) {
      state.paramsFilerTransactionHistory = action.payload;
    },
  },
});

export const { setParamsFilter, setParamsFilterHistoryTransaction } = userSlice.actions;

const userReducer = userSlice.reducer;

export default userReducer;
