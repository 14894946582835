import { CashByDateTypes, CashByDateTypesMap, ICashByDateMap, IStatisticCashChildResponse, IStatisticCashResponse, TODO } from "@/interfaces";

export function formatNumberToString(resNumber: string | number | null | undefined): string {
  if (!resNumber) return '0';

  return resNumber.toLocaleString('fullwide', {useGrouping:false}).replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
}


export const addCashByDate = (summary: TODO, cashByDate: TODO) => {
  for (const cashType of CashByDateTypes) {
    const cash = (cashByDate[cashType] as number) ?? 0;
    summary[cashType] = summary[cashType] != undefined ? (summary[cashType] as number) + cash : cash;
  }

  summary.value += cashByDate.value;

  return summary;
}

export const buildCashByDateFormater = (tran_type: string) => {

  const currentCashType = CashByDateTypesMap[tran_type] ?? 'All';

  return (agentCashReport: IStatisticCashResponse | IStatisticCashChildResponse): TODO => {
    const { point_by_date, agent_id, ma_id, ma_name, agent_name } = agentCashReport;

    const dates: Array<string> = point_by_date ? Object.keys(point_by_date) : [];

    const cashByDateMap: Record<string, TODO> = {};
    for (const date of dates) {
      const cashByDate = point_by_date[date];

      if (cashByDate) {
        let value = 0;
        if (currentCashType == 'All') {
          value = CashByDateTypes.reduce((total, cashType) => total + (cashByDate[cashType] as number), 0);
        } else {
          value = cashByDate[currentCashType] as number;
        }
  
        cashByDateMap[date] = {
          ...cashByDate,
          value
        }
      } else {
        cashByDateMap[date] = {
          value: 0
        }
      }
    }

    const listCashByDate: TODO[] = Object.values(cashByDateMap);

    const total = listCashByDate.reduce<TODO>(addCashByDate, {
      value: 0,
    });

    const valueDate = dates.reduce((partialSum: ICashByDateMap, date) => {
      partialSum[date] = cashByDateMap[date];
      return partialSum;
    }, {});

    return {
      ...valueDate,
      ...agentCashReport,
      id: agent_id || ma_id,
      agentName: ma_name || agent_name,
      total,
      point_by_date: cashByDateMap
    };
  }
}