import {IParamsProviderSearch } from '@/interfaces/provider';

import { createSlice } from '@reduxjs/toolkit';

export interface ProviderState {
  paramSearch: Partial<IParamsProviderSearch> | null;
  pvrId : string | null;
}

const initialState: ProviderState = { paramSearch: null, pvrId : null };
export const providerSlice = createSlice({
  name: 'provider',
  initialState: initialState,
  reducers: {
    setParamSearch(state, action) {
      state.paramSearch = action.payload;
    },
    setPvrId(state, action) {
      state.pvrId = action.payload;
    }
  },
});

export const { setParamSearch, setPvrId } = providerSlice.actions;
export default providerSlice.reducer;
