import { deleteFCMToken } from '@/firebase';
import { Account, IResponseApi, LoginResponse, LogoutResponse, TODO } from '@/interfaces';
import * as CONSTANT from '@/interfaces/constants';
import { showError } from '@/utils/alert';
import { removeStorageItem, setStorageItem } from '@/utils/storage';
import { useMutation } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import requestApi from '../config';
import { useAppDispatch } from '@/hooks/hooks';
import { setLogout } from '@/pages/auth/store/AuthSlice';
import { useTranslation } from 'react-i18next';
const url = 'auth-service/authentication/login';
const url_logout = '/auth-service/authentication/logout';
const useLogin = () => {
  const navigation = useNavigate();
  const { t } = useTranslation();
  return useMutation<{ data: LoginResponse }, IResponseApi, Account, number>(
    async (value) => {
      const { isRemember, ...others } = value;
      const { data } = await requestApi.postUnintercepted<{ data: LoginResponse }>(url, others);

      if (isRemember) {
        setStorageItem(CONSTANT.ACCESS_TOKEN, data.data.jwtToken);
        setStorageItem(CONSTANT.REFRESH_TOKEN, data.data?.refreshToken);
      } else {
        Cookies.set(CONSTANT.ACCESS_TOKEN, data.data?.jwtToken);
        Cookies.set(CONSTANT.REFRESH_TOKEN, data.data?.refreshToken);
      }
      return data;
    },
    {
      onSuccess: () => {
        navigation('/setting');
      },
      onError: (error) => { 
        showError(t(error.data.message));
      }
    },
  );
};

const useLogout = () => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  return useMutation<TODO, IResponseApi, object, number>(
    async () => {
      const { data } = await requestApi.post<LogoutResponse>(url_logout);
      removeStorageItem(CONSTANT.ACCESS_TOKEN);
      removeStorageItem(CONSTANT.REFRESH_TOKEN);
      removeStorageItem(CONSTANT.FIREBASE_TOKEN);
      Cookies.remove(CONSTANT.ACCESS_TOKEN, { path: '' });
      Cookies.remove(CONSTANT.REFRESH_TOKEN, { path: '' });
      deleteFCMToken();
      return data;
    },
    {
      onSuccess: () => {
        dispatch(setLogout());
        navigation('/login');
      },
      onError: () => {
        removeStorageItem(CONSTANT.ACCESS_TOKEN);
        removeStorageItem(CONSTANT.REFRESH_TOKEN);
        Cookies.remove(CONSTANT.ACCESS_TOKEN, { path: '' });
        Cookies.remove(CONSTANT.REFRESH_TOKEN, { path: '' });
        navigation('/login');
      },
    },
  );
};

export { useLogin, useLogout };
