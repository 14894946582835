import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query/react';

import { TODO } from '@/interfaces';
import { RootAction } from '@/interfaces/enum';
import auth, { authSlice } from '@/pages/auth/store/AuthSlice';
import point, { pointSlice } from '@/pages/agents/store/pointSlice';
import common, { commonSlice } from './common/commonSlice';
import agent, { agentSlice } from '@/pages/agents/store/agentSlice';
import profileInfo, { infoSlice } from './common/profileInfoSlice';
import limit, { limitSlice } from '@/pages/limit/store/limitSlice';
import profileUser, { profileSlice } from '@/pages/setting/store';
import cash, { cashSlice } from '@/pages/agents/store/cashSlice';
import agentHolding, { agentHoldingSlice } from '@/pages/agentHolding/store/agentHoldingSlice';
import users, { userSlice } from '@/pages/users/store/userSlice';
import groups, { groupSlice } from '@/pages/notifications/store/groupSlice';
import cashStatistic, { cashStatisticSlice } from '@/pages/Statistics/store/cashStatisticSlice';
import provider, { providerSlice } from '@/pages/provider/store/providerSlice';

const rootReducer = combineReducers({
  common,
  auth,
  point,
  cash,
  agent,
  profileInfo,
  limit,
  profileUser,
  agentHolding,
  users,
  groups,
  cashStatistic,
  provider
});

const appReducers = (state: TODO, action: TODO) => {
  if (action.type === RootAction.LOGOUT) {
    return rootReducer(
      {
        auth: authSlice.getInitialState(),
        point: pointSlice.getInitialState(),
        cash: cashSlice.getInitialState(),
        profileUser: profileSlice.getInitialState(),
        agent: agentSlice.getInitialState(),
        limit: limitSlice.getInitialState(),
        profileInfo: infoSlice.getInitialState(),
        common: commonSlice.getInitialState(),
        agentHolding: agentHoldingSlice.getInitialState(),
        users: userSlice.getInitialState(),
        groups: groupSlice.getInitialState(),
        cashStatistic: cashStatisticSlice.getInitialState(),
        provider : providerSlice.getInitialState()
      },
      action,
    );
  }
  return rootReducer(state, action);
};

const middlewareHandler = (getDefaultMiddleware: TODO) => {
  return [...getDefaultMiddleware({immutableCheck: false})];
};
//API slice Will include automatically generated redux reducer And a custom middleware
const rootStore = configureStore({
  reducer: appReducers,
  middleware: (getDefaultMiddleware) => middlewareHandler(getDefaultMiddleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootStore.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof rootStore.dispatch;

setupListeners(rootStore.dispatch);
export default rootStore;
