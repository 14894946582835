import { TODO } from '@/interfaces';
import { APP_CONFIG } from '@/utils/env';
import { getApp, getApps, initializeApp } from 'firebase/app';
import { deleteToken, getMessaging, getToken } from 'firebase/messaging';
import { toast } from 'react-toastify';

const firebaseConfig = {
  apiKey: APP_CONFIG.apiKey,
  authDomain: APP_CONFIG.authDomain,
  projectId: APP_CONFIG.projectId,
  storageBucket: APP_CONFIG.storageBucket,
  messagingSenderId: APP_CONFIG.messagingSenderId,
  appId: APP_CONFIG.appId,
};

// Initialize Firebase
if (!getApps().length) {
  initializeApp(firebaseConfig);
} else {
  getApp();
}

export const requestForToken = async (t: TODO) => {
  const permission = await Notification.requestPermission();
  if (permission === 'denied') {
    toast.error('please allow notifications to use this app');
    return null;
  } else if (permission === 'granted') {
    const mess = getMessaging();
    return getToken(mess, {
      vapidKey: APP_CONFIG.vapidKey,
    })
      .then((currentToken: string) => {
        if (currentToken) {
          return currentToken;
        } else {
          toast.error('No registration token available. Request permission to generate one.');
          return null;
        }
      })
      .catch((err: Error) => {
        const { message } = err;
        if (message.includes("Registration failed")) {
          toast.error(t('login.error.firebase_register'));
        } else {
          toast.error('An error occurred while retrieving token');
        }

      });
  }
};

export const deleteFCMToken = async () => {
  return deleteToken(getMessaging());
};

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     messaging.app((payload) => {
//       resolve(payload);
//     });
//   });
// export { messaging };

// import { useEffect } from 'react';
// import firebase from 'firebase/app';
// import 'firebase/messaging';
// import { APP_CONFIG } from '@/utils/env';
// import { getMessaging } from 'firebase/messaging';
// const firebaseConfig = {
//   apiKey: APP_CONFIG.apiKey,
//   authDomain: APP_CONFIG.authDomain,
//   projectId: APP_CONFIG.projectId,
//   storageBucket: APP_CONFIG.storageBucket,
//   messagingSenderId: APP_CONFIG.messagingSenderId,
//   appId: APP_CONFIG.appId,
// };
// if (!firebase.apps.length) {
//   firebase.initializeApp(firebaseConfig);
// } else {
//   firebase.getApp(); // if already initialized, use that one
// }

// let messaging: any;

// if (typeof window !== 'undefined') {
//   if (getMessaging()) {
//     messaging = firebase.messaging();
//   }
// }

// export const getMessagingToken = async () => {
//   let currentToken = '';
//   if (!messaging) return;
//   try {
//     currentToken = await messaging.getToken({
//       vapidKey: process.env.REACT_APP_FIREBASE_FCM_VAPID_KEY,
//     });
//     console.log('FCM registration token', currentToken);
//   } catch (error) {
//     console.log('An error occurred while retrieving token. ', error);
//   }
//   return currentToken;
// };

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     messaging.onMessage((payload: unknown) => {
//       resolve(payload);
//     });
//   });
