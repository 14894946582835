import ModalComponents from '@/components/Modal';
import { ICellRendererParams } from 'ag-grid-community';
import { useState } from 'react';
import StatisticsChart from './StatisticsChart';

const PointByVendorRedes = (params: ICellRendererParams & { game_type: string[] }, title: string) => {
  const [modalShow, setModalShow] = useState(false);
  const onClickModal = () => {
    setModalShow(!modalShow);
  };
  return (
    <>
      <div
      // onClick={onClickModal}
      >
        {params.value}
      </div>

      {modalShow && (
        <ModalComponents show={modalShow} onHide={() => setModalShow(!modalShow)} title={title}>
          <StatisticsChart {...params} />
        </ModalComponents>
      )}
    </>
  );
};
export default PointByVendorRedes;
