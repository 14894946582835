import {
  FIRST_ELEMENT,
  IDataPermission,
  IDataPermissionsById,
  IPermissionType,
  IResponsePermission,
  IStatisticAgentPointListByVendorContentItem,
  ObjAny,
  TODO,
  EMPTY_DATA_VENDOR
} from '@/interfaces';
import SwitchPermission from '@/pages/agents/views/agentPermission/SwitchPermission';
import { ColDef } from 'ag-grid-community';
import { flatten, groupBy, map } from 'lodash';
import { useTranslation } from 'react-i18next';

export const usePermissionColumnDefs = (): ColDef[] => {
  const { t } = useTranslation();
  return [
    {
      field: 'roleName',
      headerName: t('permission_setting.agent_list'),
      editable: false,
      cellRenderer: null,
    },
    {
      field: 'dashboard',
      headerName: t('permission_setting.dashboard'),
    },
    {
      field: 'limit',
      headerName: t('permission_setting.maximum_bet_amount_limit'), //
    },
    {
      field: 'agentsTreeView',
      headerName: t('permission_setting.agent_management_agent_treeview'),
    },
    {
      field: 'agentsList',
      headerName: t('permission_setting.agent_management_agent_list'),
    },
    {
      field: 'agentsPointTransactions',
      headerName: t('permission_setting.agent_management_point_transaction_history'),
    },
    {
      field: 'agentsCashTransactions',
      headerName: t('permission_setting.agent_management_cash_transaction_history'),
    },

    {
      field: 'usersList',
      headerName: t('permission_setting.user_management_user_list'),
    },
    {
      field: 'transactions',
      headerName: t('permission_setting.user_management_transaction_history'), //
    },
    {
      field: 'agentsHoldingProcess',
      headerName: t('permission_setting.agent_holdings_management_application_processing_history'),
    },
    {
      field: 'gameHistory',
      headerName: t('permission_setting.gaming_history'),
    },
    {
      field: 'reportPointDaily',
      headerName: t('permission_setting.report'),
      hide: true,
    },
    {
      field: 'notifications',
      headerName: t('permission_setting.notifications'),
      hide: true,
    },
    {
      field: 'agentsPermissionSettings',
      headerName: t('permission_setting.permission_settings'),
      hide: true,
    },
    {
      field: 'agentsHoldingProcess',
      headerName: t('permission_setting.gaming_history'),
      hide: true,
    },
    {
      field: 'agentsHoldingDeposit',
      headerName: t('permission_setting.agent_point_statistics'),
      hide: true,
    },
    {
      field: 'agentsHoldingWithdraw',
      headerName: t('permission_setting.register_for_withdraw_cash'),
      hide: true,
    },
    {
      field: 'gameHistory',
      headerName: t('permission_setting.gaming_history'),
      hide: true,
    },
    {
      field: 'statisticAgentPoint',
      headerName: t('permission_setting.agent_point_statistics'),
      hide: true,
    },
    {
      field: 'statisticPointByVendor',
      headerName: t('permission_setting.point_statistics_by_each_vendor'),
      hide: true,
    },
    {
      field: 'statisticDaily',
      headerName: t('permission_setting.daily_statistics'),
      hide: true,
    },
    {
      field: 'statisticCash',
      headerName: t('permission_setting.cash_statistics'),
      hide: true,
    },
  ];
};

export const usePermissionDefaultColDefs = (): ColDef => {
  return {
    singleClickEdit: true,
    cellRenderer: SwitchPermission,
    wrapHeaderText: true,
    autoHeaderHeight: true,
  };
};

export const keyObJ = (permission_code: string): string => {
  switch (permission_code) {
    case 'setting':
      return 'setting';
    case 'agents.tree-view':
      return 'agentsTreeView';
    case 'limit':
      return 'limit';
    case 'dashboard':
      return 'dashboard';
    case 'agents.list':
      return 'agentsList';
    case 'agents.cash-transactions':
      return 'agentsCashTransactions';
    case 'agents.point-transactions':
      return 'agentsPointTransactions';
    case 'users.list':
      return 'usersList';
    case 'users.transactions':
      return 'usersTransactions';
    case 'transactions':
      return 'transactions';
    case 'report.point.daily':
      return 'reportPointDaily';
    case 'notifications':
      return 'notifications';
    case 'agents.permission-settings':
      return 'agentsPermissionSettings';
    case 'agents-holding.process':
      return 'agentsHoldingProcess';
    case 'agents-holding.deposit':
      return 'agentsHoldingDeposit';
    case 'agents-holding.withdraw':
      return 'agentsHoldingWithdraw';
    case 'game-history':
      return 'gameHistory';
    case 'statistic.agent-point':
      return 'statisticAgentPoint';
    case 'statistic.point-by-vendor':
      return 'statisticPointByVendor';
    case 'statistic.daily':
      return 'statisticDaily';
    case 'statistic.cash':
      return 'statisticCash';
    case 'login-history':
      return 'agentLoginHistory';
    case 'swagger':
      return 'swagger';
    default:
      return 'default';
  }
};

export const permissionMapData = async (data: Array<IResponsePermission>): Promise<Array<IPermissionType>> => {
  return await Promise.all(
    map(data, async (e) => {
      const obj: ObjAny = { agent_name: e.agent.agent_name };
      for (const el of e.agentPermissionList) {
        const key = keyObJ(el.permissionModel.permission_code);
        obj[key] = JSON.stringify(el);
      }
      return obj;
    }),
  );
};

export const permissionMapDataById = (data: Array<IDataPermission>): IDataPermissionsById => {
  let listPermissionById: IDataPermissionsById = {};
  data.forEach((item) => {
    const id = item.agentPermissionId.toString();
    listPermissionById[id] = item;
  });
  return listPermissionById;
};

export const permissionFindData = (accumulator: Array<IDataPermission>, currentValue: TODO) => {
  for (const key in currentValue) {
    if (key !== 'agent_name') {
      const permission = JSON.parse(currentValue[key]);
      accumulator.push({
        agentPermissionId: permission.agentPermissionId,
        state: permission.state,
      });
    }
  }
  return accumulator;
};

export const pointStatisticsByVendor = (
  data: IStatisticAgentPointListByVendorContentItem[],
): IStatisticAgentPointListByVendorContentItem[] => {
  const dataGroupByVendor = groupBy(data, 'vendorId');
  const dataConvertByVendor = map(dataGroupByVendor, (group) => {
    // only show data vendor in first row and hide in the next rows
    return map(group, (el, index) => (index === FIRST_ELEMENT ? el : { ...el, ...EMPTY_DATA_VENDOR }));
  });
  const flattenData = flatten(dataConvertByVendor);
  return flattenData;
};
