import { IAgentDetail } from '@/interfaces';
import { createSlice } from '@reduxjs/toolkit';
export interface AuthInitialState {
  user: IAgentDetail;
}

export const profileInfoState: AuthInitialState = {
  user: {
    agent_id: '',
    point_rate: 0,
    agent_name: '',
    alert_on_balance: '',
    cash_balance: 0,
    contact: '',
    oper_mode: '',
    permissions: [],
    point_balance: 0,
    role: { role_code: '', role_name: '' },
    status: '',
    whitelist_ip: [],
  },
};
export const infoSlice = createSlice({
  name: 'profileInfo',
  initialState: profileInfoState,
  reducers: {
    setProfileInfo(state, action) {
      state.user = action.payload;
    },
  },
});

export const { setProfileInfo } = infoSlice.actions;

export default infoSlice.reducer;
