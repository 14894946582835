import { notifyError } from './notification';
import { GameHistoryEnum, IResponseApi, LimitEnum, PermissionEnum, StatisticsEnum, TODO } from '@/interfaces';
import { QueryClient } from '@tanstack/react-query';
import { MUTATIONS_KEY, QUERY_KEYS } from '@/interfaces/enum';
import { t } from 'i18next';

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: (err) => {
        const error = err as IResponseApi;
        notifyError(t(error.message) ?? 'error occur !!');
      },
      retry: false,
    },
    queries: {
      onError: (err) => {
        const error = err as IResponseApi;
        notifyError(t(error.message) ?? 'error occur !!');
      },
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const queryKeyExact: Array<TODO> = [
  LimitEnum.LIST_DATA_TABLE,
  PermissionEnum.LIST_ROLE_PERMISSION,
  PermissionEnum.CHANGE_STATE_PERMISSION,
  QUERY_KEYS.GET_AGENT,
  MUTATIONS_KEY.AGENT_LIST,
  MUTATIONS_KEY.POINT_CASH_HISTORY,
  MUTATIONS_KEY.UPLOAD_FILE,
  MUTATIONS_KEY.APP_PROCESSING_HISTORY,
  MUTATIONS_KEY.USERS,
  MUTATIONS_KEY.TRANSACTION_HISTORY,
  MUTATIONS_KEY.GROUP_LIST,
  PermissionEnum.URL_LIST_PERMISSION,
  StatisticsEnum.STATISTIC_AGENT_POINT,
  StatisticsEnum.STATISTIC_CASH,
  StatisticsEnum.STATISTIC_DAILY,
  StatisticsEnum.STATISTIC_POINT_BY_VENDOR,
  StatisticsEnum.STATISTIC_POINT_BY_VENDOR_CHERT_COMPANY,
  StatisticsEnum.STATISTIC_POINT_BY_VENDOR_CHERT_AGENT,
  StatisticsEnum.STATISTIC_LIST_AGENT_POINT_CHILDREN,
  StatisticsEnum.STATISTIC_LIST_BY_PARENT,
  StatisticsEnum.STATISTIC_LIST_BY_PARENT_CHILDREN,
  GameHistoryEnum.GAME_HISTORY_LIST,
  GameHistoryEnum.GAME_HISTORY_DETAILS,
  MUTATIONS_KEY.GROUP_DETAIL,
];

export const queryFilters = {
  predicate: (query: TODO) => {
    const queryKey = query.queryKey || query.options.mutationKey;
    if (!queryKey) return true;
    const checkKey = queryKey.find((element: TODO) => queryKeyExact.includes(element));
    return !checkKey;
  },
};
