export const APP_CONFIG = {
  debug: true,
  apiUrl: process.env.REACT_APP_URL_UAT,
  imgUrl: process.env.IMAGE_URL,
  profileKey: process.env.USER_PROFILE_KEY,
  tokenKey: process.env.TOKEN_KEY,
  aggrid_license: process.env.REACT_APP_AG_GRID_LICENSE_KEY,
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  vapidKey: process.env.REACT_APP_VAPID_KEY,
};
