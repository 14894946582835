import { CASH_STATISTICS_TYPE_OPTION, IContentResponseApi, IResponseApi, ObjAny } from '@/interfaces';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { ColDef } from 'ag-grid-community';
import { AgGridReactProps } from 'ag-grid-react';
import * as Yup from 'yup';
export interface IStatisticsTablePops extends AgGridReactProps {
  param?: object;
  validator?: Yup.SchemaOf<object>;
  mutateAsync: UseMutateAsyncFunction<IContentResponseApi, IResponseApi, object>;
  columnDefs: Array<ColDef>;
}

export enum StatisticsEnum {
  STATISTIC_AGENT_POINT = 'StatisticAgentPoint',
  STATISTIC_CASH = 'StatisticCash',
  STATISTIC_DAILY = 'StatisticDaily',
  STATISTIC_POINT_BY_VENDOR = 'StatisticPointByVendor',
  STATISTIC_LIST_GAME_TYPE = 'StatisticListGameType',
  STATISTIC_POINT_BY_VENDOR_CHERT_COMPANY = 'StatisticPointByVendorChartCompany',
  STATISTIC_POINT_BY_VENDOR_CHERT_AGENT = 'StatisticPointByVendorChartAgent',
  STATISTIC_LIST_BY_PARENT = 'AuthServiceAgentsListByParent',
  STATISTIC_LIST_BY_PARENT_CHILDREN = 'useStatisticAgentPointByParenChildren',
  STATISTIC_LIST_AGENT_POINT_CHILDREN = 'useStatisticAgentPointChildren',
  URL_STATISTIC_DAILY_GAME_PROVIDER_LIST = 'adapterServiceGameProviderList',
  URL_STATISTIC_DAILY_AGENT_LIST = 'transServiceAgentList',
  URL_STATISTIC_LIST_VENDOR = 'adapter-service/game-provider/list',
  GET_MEMO = 'GetMemo',
  CREATE_MEMO = 'CreateMemo',
  UPDATE_MEMO = 'UpdateMemo',
  DELETE_MEMO = 'DeleteMemo',
}

export interface IStatisticAgentPointParam {
  page_no?: number;
  page_size?: number;
  parent_id?: string;
  agent_id?: string;
  from_date?: string;
  to_date?: string;
}

export interface IChartType {
  label: string;
  data: Array<number>;
  backgroundColor: string;
}
export interface IChartData {
  labels: Array<string>;
  datasets: Array<IChartType>;
}
export interface IStatisticAgentPointChildrenParam {
  parent_id: string;
}
export interface IStatisticDailyParam {
  startDate?: string;
  endDate?: string;
  agentId?: string;
  vendorId?: string;
  page_no?: number;
  page_size?: number;
  sort?: string;
  getChildren?: boolean;
}

export interface IStatisticCashParam {
  tran_type?: string;
  parent_id?: string;
  agent_id?: string;
  include_child?: boolean;
  from_date?: string;
  to_date?: string;
  filter?: string;
  include_non_active_agent?: boolean;
}
export interface IKeyType {
  [key: string]: number | string;
}
export interface IStatisticAgentPointParam {
  limit?: number;
  page?: number;
}

export const CashByDateTypesMap = {
  [CASH_STATISTICS_TYPE_OPTION.WITHDRAW]: 'withdraw_cash',
  [CASH_STATISTICS_TYPE_OPTION.BUY_POINT]: 'buy_point',
  [CASH_STATISTICS_TYPE_OPTION.RATE_ALLOCATION]: 'rate_allocation',
};
export const CashByDateTypes = Object.values(CashByDateTypesMap);

export type CashByDate = {
  [cashType: string]: number | undefined;
} & {
  memo_id?: string;
  value?: number;
};

export interface ICashByDateMap {
  [date: string]: CashByDate;
}

export interface IStatisticCashChildResponse {
  agent_id: string;
  agent_name: string;
  parent_id: string;
  ma_id: string;
  parent_name: string;
  ma_name: string;
  tran_type: string;
  point_by_date: ICashByDateMap;
}

export interface IStatisticCashResponse {
  ma_id: string;
  ma_name: string;
  agent_id: string;
  agent_name: string;
  point_by_date: ICashByDateMap;
  child: Array<IStatisticCashChildResponse>;
}

export enum StatisticsTypeCashEnum {
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
  CONVERT_POINT = 'TRANSFER POINT',
  RATE_ALLOCATION = 'COMMISSION',
}

export interface IStatisticsCashResponse extends ObjAny {
  id: string | number;
  agentName: string;
  total?: number;
  type?: StatisticsTypeCashEnum | string;
  masterAgent?: number;
  children?: Array<IStatisticsCashResponse>;
}

export interface IStatisticAgentPointListByParentResponse {
  agent_id: string;
  agent_name: string;
}

export interface IStatisticAgentPointListByVendorResponse {
  content: IStatisticAgentPointListByVendorContentItem[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  totalElements: number;
  totalPages: number;
}

export interface IStatisticAgentPointListByVendorContentItem {
  vendor: string|null;
  vendorId?: string;
  parentAgent?: string;
  agent?: string;
  agentId?: string;
  profit?: number;
  profitVendor: number|null;
  bettingAmount?: number;
  bettingAmountVendor: number|null;
  winAmount?: number;
  winAmountVendor: number|null;
  cancelAmount?: number;
  cancelAmountVendor: number|null;
}

export interface IStatisticAgentByVendorChartResponse {
  totalBetting?: number;
  totalCancel?: number;
  totalProfit?: number;
  totalWin?: number;
}

export interface IStatisticAgentByVendorChartDataResponse {
  Month: IStatisticAgentByVendorChartResponse;
  Last_month: IStatisticAgentByVendorChartResponse;
  Today: IStatisticAgentByVendorChartResponse;
  Last_Day: IStatisticAgentByVendorChartResponse;
  Last_Week: IStatisticAgentByVendorChartResponse;
  Week: IStatisticAgentByVendorChartResponse;
}

export interface IStatisticDailyGameProviderListResponse {
  id: string;
  providerName: string;
}

export interface IStatisticDailyAgentListListResponse {
  agentId: string;
  agentName: string;
}

export interface IStatisticAgentPointResponse extends IStatisticAgentPointListByParentResponse {
  revenue: number;
  bet: number;
  win: number;
  cancel: number;
  ma_id: string;
  parent_name: string;
  agent_point_balance: number;
  agent_point_rate: number;
  ma_point_balance: number;
  ma_point_rate: number;
  rate_diff: number;
}
export interface IStatisticDailyResponse {
  agentPoint: number;
  agentPointRate: number;
  betAmount: number;
  cancelAmount: number;
  chargeAmount: number;
  dateReport: string;
  holdingPoints: number;
  maPointRate: number;
  rateDiff: number;
  revenue: number;
  winAmount: number;
}

export type IStatisticVendorParamColumn = {
  vendorId?: string;
  gameType?: string[];
  agentId: string;
};
