import { useQuery } from '@tanstack/react-query';

import requestApi from '@/apis/config';
import { IResponseApi } from '@/interfaces';
import { IDashboardItem } from '@/interfaces/dashboard';
import { QUERY_KEYS } from '@/interfaces/enum';
const URL_DASHBOARD = '/auth-service/dashboard';
const useDashboardQuery = () => {
  return useQuery<IDashboardItem, IResponseApi>([QUERY_KEYS.DASHBOARD], async () => {
    const { data } = await requestApi.get<IDashboardItem>(URL_DASHBOARD);
    return data;
  });
};

export { useDashboardQuery };
