import { IStatisticsCashResponse, StatisticsTypeCashEnum } from '@/interfaces';

export const TreeData = {
  id: 'root',
  title: 'root',
  role: 'MA',
  children: [
    {
      id: '0.7374102759784757',
      title: '6yqyrjy1jk',
      role: 'A',
      children: [
        {
          id: '0.08980734447853322',
          role: 'B',
          title: 'uqvpcuzy7i',
          children: [
            {
              id: '0.6677802087124156',
              role: 'C',
              title: 'ui5iqexucq',
              children: [
                {
                  id: '0.14624969261112164',
                  role: 'D',
                  title: 'ulg1n4uin9',
                  children: [
                    {
                      id: '0.8260547144336596',
                      role: 'E',
                      title: 'n329bolzkll',
                      children: [
                        {
                          id: '0.726292587233268',
                          role: 'F',
                          title: 'syt52eorozk',
                          children: [
                            {
                              id: '0.216782005237949',
                              role: 'G',
                              title: 'dwf6edqumu',
                              children: [
                                {
                                  id: '0.9067966628458208',
                                  role: 'H',
                                  title: '224m23yehp',
                                  children: [
                                    {
                                      id: '0.12214028544932454',
                                      role: 'K',
                                      title: 'plfeep6fet',
                                      children: [
                                        {
                                          id: '0.6079588173403914',
                                          role: 'K',
                                          title: 'so1v5qqm4e',
                                          children: [
                                            {
                                              id: '0.24677567897579045',
                                              role: 'K',
                                              title: 'amwhg5j58r',
                                              children: [
                                                {
                                                  id: '0.5844273423192319',
                                                  role: 'K',
                                                  title: 'pdoynkduneg',
                                                  children: [
                                                    {
                                                      id: '0.5229864884257189',
                                                      role: 'K',
                                                      title: 'c30mk0a249',
                                                      children: [
                                                        {
                                                          id: '0.3958177218951062',
                                                          role: 'K',
                                                          title: 'fqrg4kursf',
                                                          children: [
                                                            {
                                                              id: '0.8713314336057834',
                                                              role: 'K',
                                                              title: 'kddhf3ojvz',
                                                              children: [
                                                                {
                                                                  id: '0.8823705530695312',
                                                                  role: 'K',
                                                                  title: 'do6h8urndr',
                                                                  children: [
                                                                    {
                                                                      id: '0.43028020066615236',
                                                                      role: 'K',
                                                                      title: 'skh9b6up1wk',
                                                                      children: [
                                                                        {
                                                                          id: '0.9549705169456235',
                                                                          role: 'K',
                                                                          title: 'egeik3d4xw',
                                                                          children: [
                                                                            {
                                                                              id: '0.046425056326444114',
                                                                              role: 'K',
                                                                              title: '4hmbxncaij',
                                                                              children: [
                                                                                {
                                                                                  id: '0.02759028371370631',
                                                                                  role: 'K',
                                                                                  title: '9spmfwgrz1',
                                                                                  children: [
                                                                                    {
                                                                                      id: '0.10739545234111869',
                                                                                      role: 'K',
                                                                                      title: 'p2kghsxeci',
                                                                                      children: [
                                                                                        {
                                                                                          id: '0.7461260660583515',
                                                                                          role: 'K',
                                                                                          title: '0.04159915816283499',
                                                                                          children: [
                                                                                            {
                                                                                              id: '0.03462847949608894',
                                                                                              role: 'K',
                                                                                              title:
                                                                                                '0.6035980698619701',
                                                                                              children: [
                                                                                                {
                                                                                                  id: '0.03651197021939678',
                                                                                                  role: 'K',
                                                                                                  title:
                                                                                                    '0.09920079015284222',
                                                                                                  children: [
                                                                                                    {
                                                                                                      id: '0.6403208616283773',
                                                                                                      role: 'K',
                                                                                                      title:
                                                                                                        '0.12856335676214425',
                                                                                                      children: [
                                                                                                        {
                                                                                                          id: '0.5701500265578117',
                                                                                                          role: 'K',
                                                                                                          title:
                                                                                                            'dksjfiidfinfdi',
                                                                                                        },
                                                                                                      ],
                                                                                                    },
                                                                                                  ],
                                                                                                },
                                                                                              ],
                                                                                            },
                                                                                          ],
                                                                                        },
                                                                                      ],
                                                                                    },
                                                                                  ],
                                                                                },
                                                                              ],
                                                                            },
                                                                          ],
                                                                        },
                                                                      ],
                                                                    },
                                                                  ],
                                                                },
                                                              ],
                                                            },
                                                          ],
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                            {
                              id: '0.7932363882560198',
                              role: 'G',
                              title: '8emvihssbi',
                              children: [
                                {
                                  id: '0.39773161198522256',
                                  role: 'H',
                                  title: 'kkp89gxs4s',
                                  children: [
                                    {
                                      id: '0.9830121792524686',
                                      role: 'K',
                                      title: 'zi6mxre0t1j',
                                      children: [
                                        {
                                          id: '0.4359266051421242',
                                          role: 'K',
                                          title: 'xcsxcikkzi',
                                          children: [
                                            {
                                              id: '0.23488745025527336',
                                              role: 'K',
                                              title: 'u0gjxx9cwoj',
                                              children: [
                                                {
                                                  id: '0.13874444867854008',
                                                  role: 'K',
                                                  title: '2mlu2feypl',
                                                  children: [
                                                    {
                                                      id: '0.36615293311642527',
                                                      role: 'K',
                                                      title: 'myoayizf3ph',
                                                      children: [
                                                        {
                                                          id: '0.7097931258657919',
                                                          role: 'K',
                                                          title: 'bb3gpw607b',
                                                          children: [
                                                            {
                                                              id: '0.7127578648073598',
                                                              role: 'K',
                                                              title: 'x9ra062q6xl',
                                                              children: [
                                                                {
                                                                  id: '0.3176477010095333',
                                                                  role: 'K',
                                                                  title: '619b3a1f29',
                                                                  children: [
                                                                    {
                                                                      id: '0.14890937397605564',
                                                                      role: 'K',
                                                                      title: '66myv7eg5b',
                                                                      children: [
                                                                        {
                                                                          id: '0.18720270787929594',
                                                                          role: 'K',
                                                                          title: 'we9c4q1r0y',
                                                                          children: [
                                                                            {
                                                                              id: '0.24382364370206155',
                                                                              role: 'K',
                                                                              title: '6qqdswtqv9k',
                                                                              children: [
                                                                                {
                                                                                  id: '0.9339029782223578',
                                                                                  role: 'K',
                                                                                  title: 'wnkklj2sk4',
                                                                                  children: [
                                                                                    {
                                                                                      id: '0.5357373422093239',
                                                                                      role: 'K',
                                                                                      title: 'pt2f7mcdn5',
                                                                                      children: [
                                                                                        {
                                                                                          id: '0.4886373596776201',
                                                                                          role: 'K',
                                                                                          title: '0.5037142384692495',
                                                                                          children: [
                                                                                            {
                                                                                              id: '0.828084609516911',
                                                                                              role: 'K',
                                                                                              title:
                                                                                                '0.06856289622976397',
                                                                                              children: [
                                                                                                {
                                                                                                  id: '0.2712464961223653',
                                                                                                  role: 'K',
                                                                                                  title:
                                                                                                    '0.15151088787478528',
                                                                                                  children: [
                                                                                                    {
                                                                                                      id: '0.8115985004657871',
                                                                                                      role: 'K',
                                                                                                      title:
                                                                                                        '0.8469245666019092',
                                                                                                      children: [
                                                                                                        {
                                                                                                          id: '0.45367317051386813',
                                                                                                          role: 'K',
                                                                                                          title:
                                                                                                            '0.708934915047315',
                                                                                                        },
                                                                                                      ],
                                                                                                    },
                                                                                                  ],
                                                                                                },
                                                                                              ],
                                                                                            },
                                                                                          ],
                                                                                        },
                                                                                      ],
                                                                                    },
                                                                                  ],
                                                                                },
                                                                              ],
                                                                            },
                                                                          ],
                                                                        },
                                                                      ],
                                                                    },
                                                                  ],
                                                                },
                                                              ],
                                                            },
                                                          ],
                                                        },
                                                      ],
                                                    },
                                                  ],
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    { id: '100', title: 'Child - 33', role: 'A', children: [{ id: '1001', role: 'B', title: 'Child - 1001' }] },
    { id: '1', role: 'A', title: 'Child - 1' },
    {
      id: '3',
      role: 'A',
      title: 'Child - 3',
      children: [
        {
          id: '634',
          role: 'B',
          title: 'Child - 634',
          children: [
            {
              id: '35',
              title: 'Child - 35',
              role: 'C',
              children: [
                { id: '36', role: 'D', title: 'Child - 36' },
                { id: '423', role: 'D', title: 'Child - 423' },
                { id: '38', role: 'D', title: 'Child - 38' },
                { id: '42', role: 'D', title: 'Child - 42' },
              ],
            },
          ],
        },
        {
          id: '427',
          role: 'B',
          title: 'Child - 427',
          children: [
            {
              id: '55',
              role: 'C',
              title: 'Child - 55',
              children: [
                { id: '95', role: 'D', title: 'Child - 95' },
                { id: '468', role: 'D', title: 'Child - 468' },
                { id: '48', role: 'D', title: 'Child - 48' },
                { id: '848', role: 'D', title: 'Child - 848' },
              ],
            },
            {
              id: '554',
              role: 'C',
              title: 'Child - 554',
              children: [
                { id: '935', role: 'D', title: 'Child - 935' },
                { id: '7468', role: 'D', title: 'Child - 7468' },
                { id: '548', role: 'D', title: 'Child - 548' },
                { id: '2848', role: 'D', title: 'Child - 2848' },
              ],
            },
            {
              id: '557',
              role: 'C',
              title: 'Child - 557',
              children: [
                { id: '915', role: 'D', title: 'Child - 915' },
                {
                  id: '9135',
                  role: 'D',
                  title: 'Child - 9135',
                  children: [{ id: '9156', role: 'E', title: 'Child - 9156' }],
                },
                { id: '4668', role: 'D', title: 'Child - 4668' },
                { id: '487', role: 'D', title: 'Child - 487' },
                { id: '1848', role: 'D', title: 'Child - 1848' },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const limitData = [
  {
    athlete: 'Michael Phelps',
    age: 23,
    country: 'United States',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 8,
    silver: 0,
    bronze: 0,
    total: 8,
  },
  {
    athlete: 'Michael Phelps',
    age: 19,
    country: 'United States',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 6,
    silver: 0,
    bronze: 2,
    total: 8,
  },
  {
    athlete: 'Michael Phelps',
    age: 27,
    country: 'United States',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 4,
    silver: 2,
    bronze: 0,
    total: 6,
  },
  {
    athlete: 'Natalie Coughlin',
    age: 25,
    country: 'United States',
    year: 2008,
    date: '24/08/2008',
    sport: 'Swimming',
    gold: 1,
    silver: 2,
    bronze: 3,
    total: 6,
  },
  {
    athlete: 'Aleksey Nemov',
    age: 24,
    country: 'Russia',
    year: 2000,
    date: '01/10/2000',
    sport: 'Gymnastics',
    gold: 2,
    silver: 1,
    bronze: 3,
    total: 6,
  },
  {
    athlete: 'Alicia Coutts',
    age: 24,
    country: 'Australia',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 1,
    silver: 3,
    bronze: 1,
    total: 5,
  },
  {
    athlete: 'Missy Franklin',
    age: 17,
    country: 'United States',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 4,
    silver: 0,
    bronze: 1,
    total: 5,
  },
  {
    athlete: 'Ryan Lochte',
    age: 27,
    country: 'United States',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 2,
    silver: 2,
    bronze: 1,
    total: 5,
  },
  {
    athlete: 'Allison Schmitt',
    age: 22,
    country: 'United States',
    year: 2012,
    date: '12/08/2012',
    sport: 'Swimming',
    gold: 3,
    silver: 1,
    bronze: 1,
    total: 5,
  },
  {
    athlete: 'Natalie Coughlin',
    age: 21,
    country: 'United States',
    year: 2004,
    date: '29/08/2004',
    sport: 'Swimming',
    gold: 2,
    silver: 2,
    bronze: 1,
    total: 5,
  },
  {
    athlete: 'Ian Thorpe',
    age: 17,
    country: 'Australia',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 3,
    silver: 2,
    bronze: 0,
    total: 5,
  },
  {
    athlete: 'Dara Torres',
    age: 33,
    country: 'United States',
    year: 2000,
    date: '01/10/2000',
    sport: 'Swimming',
    gold: 2,
    silver: 0,
    bronze: 3,
    total: 5,
  },
];

export const listVendorGame = [
  {
    label: '1',
    value: '1',
  },

  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '6',
    value: '6',
  },
];

export const dataChart = {
  Month: [1, 35, 3, 45],
  Last_month: [10, 2, 35, 4],
  Today: [6, 2, 3, 4],
  Last_Day: [9, 28, 3, 45],
  Last_Week: [9, 82, 3, 45],
  Week: [98, 2, 3, 4],
};

export const statisticDataTree: Array<IStatisticsCashResponse> = [
  {
    id: 4730,
    agentName: 'Emilie Alexander',
    employmentType: 'Contract',
    startDate: '24/12/2019',
    type: 'deposit',
    children: [
      {
        id: 4731,
        agentName: 'Bill Andrews',
        employmentType: 'Permanent',
        startDate: '15/10/2056',
        type: 'deposit',
      },
      {
        id: 4732,
        agentName: 'Shawn Jensen',
        employmentType: 'Permanent',
        startDate: '18/10/2072',
        type: 'withdraw',
      },
      {
        id: 4733,
        agentName: 'Amanda Richards',
        employmentType: 'Contract',
        startDate: '1/3/2080',
        type: 'convertPoint',
      },
      {
        id: 4734,
        agentName: 'Manuel Barnett',
        employmentType: 'Contract',
        startDate: '2/3/2088',
        type: 'convertPoint',
      },
      {
        id: 4735,
        agentName: 'Zachary Hawkins',
        employmentType: 'Contract',
        startDate: '29/9/2080',
        type: 'convertPoint',
      },
      {
        id: 4736,
        agentName: 'Floyd Ball',
        employmentType: 'Permanent',
        startDate: '17/4/2062',
        type: 'withdraw',
      },
      {
        id: 4737,
        agentName: 'Joseph Ingram',
        employmentType: 'Contract',
        startDate: '10/10/2096',
        type: 'rateAllocation',
      },
      {
        id: 4738,
        agentName: 'Nancy Miller',
        employmentType: 'Permanent',
        startDate: '30/9/2058',
        type: 'convertPoint',
      },
      {
        id: 4739,
        agentName: 'Mollie Schultz',
        employmentType: 'Contract',
        startDate: '29/7/2065',
        type: 'convertPoint',
      },
      {
        id: 4740,
        agentName: 'Gene Weaver',
        employmentType: 'Permanent',
        startDate: '18/8/2058',
        type: 'withdraw',
      },
    ],
  },
  {
    id: 4741,
    agentName: 'Charles Lindsey',
    employmentType: 'Contract',
    startDate: '7/1/2099',
    type: 'deposit',
    children: [
      {
        id: 4742,
        agentName: 'Linnie Roberson',
        employmentType: 'Contract',
        startDate: '25/10/2050',
      },
      {
        id: 4743,
        agentName: 'Lola Boone',
        employmentType: 'Permanent',
        startDate: '18/12/2104',
      },
      {
        id: 4744,
        agentName: 'James Ball',
        employmentType: 'Permanent',
        startDate: '14/7/2073',
      },
      {
        id: 4745,
        agentName: 'Louisa Nelson',
        employmentType: 'Contract',
        startDate: '17/1/2094',
      },
      {
        id: 4746,
        agentName: 'Frederick Burgess',
        employmentType: 'Contract',
        startDate: '31/7/2079',
      },
      {
        id: 4747,
        agentName: 'Bettie Miller',
        employmentType: 'Contract',
        startDate: '14/3/2098',
      },
      {
        id: 4748,
        agentName: 'Birdie Salazar',
        employmentType: 'Contract',
        startDate: '20/6/2084',
      },
      {
        id: 4749,
        agentName: 'Edith Bryant',
        employmentType: 'Permanent',
        startDate: '21/1/2089',
      },
      {
        id: 4750,
        agentName: 'Oscar Roberts',
        employmentType: 'Contract',
        startDate: '6/2/2089',
      },
      {
        id: 4751,
        agentName: 'Lida Schultz',
        employmentType: 'Permanent',
        startDate: '25/7/2049',
      },
    ],
  },
  {
    id: 4752,
    agentName: 'Jorge Parsons',
    employmentType: 'Contract',
    startDate: '7/3/2047',
    type: StatisticsTypeCashEnum.DEPOSIT,
    children: [
      {
        id: 4753,
        agentName: 'Mario Foster',
        employmentType: 'Permanent',
        startDate: '20/9/2075',
      },
      {
        id: 4754,
        agentName: 'Adrian Perkins',
        employmentType: 'Contract',
        startDate: '2/9/2099',
      },
      {
        id: 4755,
        agentName: 'Nelle Bennett',
        employmentType: 'Contract',
        startDate: '19/3/2019',
      },
      {
        id: 4756,
        agentName: 'Leo Briggs',
        employmentType: 'Permanent',
        startDate: '18/10/2031',
      },
      {
        id: 4757,
        agentName: 'Rebecca Marsh',
        employmentType: 'Contract',
        startDate: '6/6/2088',
      },
      {
        id: 4758,
        agentName: 'Nellie Park',
        employmentType: 'Permanent',
        startDate: '25/5/2101',
      },
      {
        id: 4759,
        agentName: 'Susie Norris',
        employmentType: 'Permanent',
        startDate: '23/6/2089',
      },
      {
        id: 4760,
        agentName: 'Maurice Myers',
        employmentType: 'Contract',
        startDate: '27/1/2023',
      },
      {
        id: 4761,
        agentName: 'Delia Powers',
        employmentType: 'Permanent',
        startDate: '28/7/2105',
      },
      {
        id: 4762,
        agentName: 'Larry Mann',
        employmentType: 'Permanent',
        startDate: '26/10/2063',
      },
    ],
  },
  {
    id: 179,
    agentName: 'Alberta Moore',
    employmentType: 'Contract',
    startDate: '12/12/2050',
    type: 'deposit',
    children: [
      {
        id: 180,
        agentName: 'Lelia Mills',
        employmentType: 'Permanent',
        startDate: '2/2/2040',
      },
      {
        id: 181,
        agentName: 'Gerald Newman',
        employmentType: 'Contract',
        startDate: '23/1/2086',
      },
      {
        id: 182,
        agentName: 'Jeff Huff',
        employmentType: 'Permanent',
        startDate: '10/5/2043',
      },
      {
        id: 183,
        agentName: 'Danny Miller',
        employmentType: 'Permanent',
        startDate: '15/10/2045',
      },
      {
        id: 184,
        agentName: 'Nicholas Bishop',
        employmentType: 'Contract',
        startDate: '10/9/2018',
      },
      {
        id: 185,
        agentName: 'Rena Strickland',
        employmentType: 'Contract',
        startDate: '2/9/2067',
      },
      {
        id: 186,
        agentName: 'Georgia Stevenson',
        employmentType: 'Contract',
        startDate: '29/9/2109',
      },
      {
        id: 187,
        agentName: 'Victor Munoz',
        employmentType: 'Permanent',
        startDate: '26/2/2059',
      },
      {
        id: 188,
        agentName: 'Violet Coleman',
        employmentType: 'Contract',
        startDate: '29/4/2069',
      },
      {
        id: 189,
        agentName: 'Leona Burton',
        employmentType: 'Permanent',
        startDate: '26/4/2022',
      },
    ],
  },
  {
    id: 100,
    agentName: 'Kathryn Powers',
    employmentType: 'Contract',
    startDate: '24/6/2026',
    type: 'deposit',
    children: [
      {
        id: 102,
        agentName: 'Troy Walsh',
        employmentType: 'Permanent',
        startDate: '28/6/2046',
      },
      {
        id: 103,
        agentName: 'Joshua Matthews',
        employmentType: 'Contract',
        startDate: '6/10/2078',
      },
    ],
  },
  {
    id: 101,
    agentName: 'Addie Meyer',
    employmentType: 'Permanent',
    startDate: '15/7/2104',
    type: 'deposit',
    children: [
      {
        id: 104,
        agentName: 'Mitchell Wong',
        employmentType: 'Contract',
        startDate: '22/7/2065',
      },
      {
        id: 105,
        agentName: 'Marc Hill',
        employmentType: 'Permanent',
        startDate: '15/12/2082',
      },
      {
        id: 106,
        agentName: 'Della Craig',
        employmentType: 'Permanent',
        startDate: '4/5/2091',
      },
      {
        id: 107,
        agentName: 'Hannah Richards',
        employmentType: 'Contract',
        startDate: '26/8/2028',
      },
      {
        id: 108,
        agentName: 'Isabelle Gonzalez',
        employmentType: 'Contract',
        startDate: '6/3/2036',
      },
      {
        id: 109,
        agentName: 'Joel Simon',
        employmentType: 'Permanent',
        startDate: '9/3/2104',
      },
      {
        id: 110,
        agentName: 'Joshua Gill',
        employmentType: 'Permanent',
        startDate: '12/2/2107',
      },
      {
        id: 111,
        agentName: 'Jonathan Hughes',
        employmentType: 'Contract',
        startDate: '29/9/2078',
      },
      {
        id: 112,
        agentName: 'Bernard Brown',
        employmentType: 'Contract',
        startDate: '15/3/2025',
      },

      {
        id: 124,
        agentName: 'Howard Zimmerman',
        employmentType: 'Contract',
        startDate: '8/7/2107',
      },
      {
        id: 125,
        agentName: 'Rena Rodgers',
        employmentType: 'Permanent',
        startDate: '9/4/2074',
      },
      {
        id: 126,
        agentName: 'Mable Ingram',
        employmentType: 'Permanent',
        startDate: '28/5/2056',
      },
      {
        id: 127,
        agentName: 'Myra Bowen',
        employmentType: 'Permanent',
        startDate: '17/4/2063',
      },
      {
        id: 128,
        agentName: 'Thomas Holloway',
        employmentType: 'Contract',
        startDate: '24/8/2069',
      },
      {
        id: 129,
        agentName: 'Ruth Guerrero',
        employmentType: 'Contract',
        startDate: '16/7/2115',
      },
      {
        id: 130,
        agentName: 'Tillie Turner',
        employmentType: 'Contract',
        startDate: '8/9/2108',
      },
      {
        id: 131,
        agentName: 'Margaret Hart',
        employmentType: 'Contract',
        startDate: '20/10/2083',
      },
      {
        id: 132,
        agentName: 'Harry Rose',
        employmentType: 'Permanent',
        startDate: '1/5/2078',
      },
      {
        id: 133,
        agentName: 'Annie Colon',
        employmentType: 'Permanent',
        startDate: '11/2/2059',
      },
      {
        id: 134,
        agentName: 'Patrick Jordan',
        employmentType: 'Contract',
        startDate: '9/11/2032',
      },
      {
        id: 113,
        agentName: 'Alberta Curtis',
        employmentType: 'Contract',
        startDate: '25/7/2036',
      },
      {
        id: 135,
        agentName: 'Ethel Johnston',
        employmentType: 'Contract',
        startDate: '27/12/2047',
      },
      {
        id: 146,
        agentName: 'Frances Moran',
        employmentType: 'Contract',
        startDate: '12/1/2093',
      },
      {
        id: 147,
        agentName: 'Susie Bryant',
        employmentType: 'Permanent',
        startDate: '7/5/2068',
      },
      {
        id: 148,
        agentName: 'Matthew Johnston',
        employmentType: 'Contract',
        startDate: '24/10/2048',
      },
      {
        id: 149,
        agentName: 'Isabel Ross',
        employmentType: 'Permanent',
        startDate: '30/11/2086',
      },
      {
        id: 150,
        agentName: 'Mitchell Martinez',
        employmentType: 'Contract',
        startDate: '14/1/2068',
      },
      {
        id: 151,
        agentName: 'Flora Ellis',
        employmentType: 'Permanent',
        startDate: '15/1/2105',
      },
      {
        id: 152,
        agentName: 'Lillian Santiago',
        employmentType: 'Contract',
        startDate: '18/9/2062',
      },
      {
        id: 153,
        agentName: 'Alejandro Anderson',
        employmentType: 'Contract',
        startDate: '16/2/2022',
      },
      {
        id: 154,
        agentName: 'Lilly Stephens',
        employmentType: 'Contract',
        startDate: '5/3/2024',
      },
      {
        id: 155,
        agentName: 'Tyler Schmidt',
        employmentType: 'Contract',
        startDate: '15/8/2037',
      },
      {
        id: 156,
        agentName: 'Jesse Buchanan',
        employmentType: 'Contract',
        startDate: '28/3/2059',
      },
      {
        id: 157,
        agentName: 'Marcus Sullivan',
        employmentType: 'Contract',
        startDate: '22/9/2051',
      },

      {
        id: 158,
        agentName: 'Lester Walters',
        employmentType: 'Contract',
        startDate: '15/2/2048',
      },
      {
        id: 159,
        agentName: 'Mable Rodriguez',
        employmentType: 'Contract',
        startDate: '10/10/2053',
      },
      {
        id: 160,
        agentName: 'Maurice Abbott',
        employmentType: 'Contract',
        startDate: '2/5/2065',
      },
      {
        id: 161,
        agentName: 'Patrick Bowers',
        employmentType: 'Contract',
        startDate: '7/7/2081',
      },
      {
        id: 162,
        agentName: 'Maggie Adams',
        employmentType: 'Permanent',
        startDate: '25/12/2065',
      },
      {
        id: 163,
        agentName: 'Leona Gomez',
        employmentType: 'Permanent',
        startDate: '7/5/2048',
      },
      {
        id: 164,
        agentName: 'Carrie Oliver',
        employmentType: 'Contract',
        startDate: '5/5/2070',
      },
      {
        id: 165,
        agentName: 'Marvin Sparks',
        employmentType: 'Contract',
        startDate: '3/9/2112',
      },
      {
        id: 166,
        agentName: 'Jose Walsh',
        employmentType: 'Permanent',
        startDate: '12/12/2051',
      },
      {
        id: 167,
        agentName: 'Mae Duncan',
        employmentType: 'Permanent',
        startDate: '25/1/2037',
      },
      {
        id: 168,
        agentName: 'Gregory White',
        employmentType: 'Contract',
        startDate: '25/1/2091',
      },

      {
        id: 169,
        agentName: 'Maud Ball',
        employmentType: 'Permanent',
        startDate: '26/5/2065',
      },
      {
        id: 170,
        agentName: 'Cody Cross',
        employmentType: 'Permanent',
        startDate: '26/6/2110',
      },
      {
        id: 171,
        agentName: 'Harry Huff',
        employmentType: 'Permanent',
        startDate: '26/4/2028',
      },
      {
        id: 172,
        agentName: 'Alfred Smith',
        employmentType: 'Permanent',
        startDate: '5/7/2029',
      },
      {
        id: 173,
        agentName: 'Chad Johnson',
        employmentType: 'Permanent',
        startDate: '1/5/2059',
      },
      {
        id: 174,
        agentName: 'Clarence Adams',
        employmentType: 'Contract',
        startDate: '18/7/2027',
      },
      {
        id: 175,
        agentName: 'Edward Bryan',
        employmentType: 'Permanent',
        startDate: '2/1/2104',
      },
      {
        id: 176,
        agentName: 'Alfred Cruz',
        employmentType: 'Permanent',
        startDate: '23/5/2074',
      },
      {
        id: 177,
        agentName: 'Jonathan Lambert',
        employmentType: 'Contract',
        startDate: '5/8/2051',
      },
      {
        id: 178,
        agentName: 'Lula Myers',
        employmentType: 'Permanent',
        startDate: '11/7/2101',
      },
      {
        id: 190,
        agentName: 'Juan Moss',
        employmentType: 'Permanent',
        startDate: '27/2/2050',
      },
      {
        id: 191,
        agentName: 'Dora Wolfe',
        employmentType: 'Permanent',
        startDate: '20/4/2019',
      },
      {
        id: 192,
        agentName: 'Mae Park',
        employmentType: 'Permanent',
        startDate: '26/5/2108',
      },
      {
        id: 193,
        agentName: 'Philip Thompson',
        employmentType: 'Contract',
        startDate: '9/11/2038',
      },
      {
        id: 194,
        agentName: 'Cecilia Oliver',
        employmentType: 'Contract',
        startDate: '14/9/2104',
      },
      {
        id: 195,
        agentName: 'Sue Hoffman',
        employmentType: 'Permanent',
        startDate: '10/10/2083',
      },
      {
        id: 196,
        agentName: 'Lee Riley',
        employmentType: 'Permanent',
        startDate: '8/8/2080',
      },
      {
        id: 197,
        agentName: 'Isabella Patton',
        employmentType: 'Contract',
        startDate: '30/5/2059',
      },
      {
        id: 198,
        agentName: 'Ina Stone',
        employmentType: 'Permanent',
        startDate: '21/9/2073',
      },
      {
        id: 199,
        agentName: 'Willie Flores',
        employmentType: 'Contract',
        startDate: '21/8/2043',
      },
      {
        id: 200,
        agentName: 'Mabel Gomez',
        employmentType: 'Permanent',
        startDate: '22/6/2047',
      },
      {
        id: 201,
        agentName: 'Devin Howard',
        employmentType: 'Permanent',
        startDate: '2/1/2036',
      },
      {
        id: 202,
        agentName: 'Dora Mendoza',
        employmentType: 'Permanent',
        startDate: '10/3/2076',
      },
      {
        id: 203,
        agentName: 'Zachary Ferguson',
        employmentType: 'Contract',
        startDate: '30/3/2060',
      },
      {
        id: 204,
        agentName: 'Lizzie Goodwin',
        employmentType: 'Contract',
        startDate: '24/12/2053',
      },
      {
        id: 205,
        agentName: 'Elsie Anderson',
        employmentType: 'Contract',
        startDate: '27/8/2079',
      },
      {
        id: 206,
        agentName: 'Gary Ortega',
        employmentType: 'Permanent',
        startDate: '3/3/2024',
      },
      {
        id: 207,
        agentName: 'Calvin Farmer',
        employmentType: 'Contract',
        startDate: '14/11/2020',
      },
      {
        id: 208,
        agentName: 'Harriet Johnson',
        employmentType: 'Contract',
        startDate: '13/6/2057',
      },
      {
        id: 209,
        agentName: 'Jeffrey Green',
        employmentType: 'Contract',
        startDate: '10/2/2091',
      },
      {
        id: 210,
        agentName: 'Edna Higgins',
        employmentType: 'Permanent',
        startDate: '21/2/2067',
      },
      {
        id: 211,
        agentName: 'Clarence Buchanan',
        employmentType: 'Contract',
        startDate: '3/6/2021',
      },
    ],
  },
  {
    id: 212,
    agentName: 'Mabel Ward',
    employmentType: 'Permanent',
    startDate: '7/8/2087',
    type: 'deposit',
    children: [
      {
        id: 213,
        agentName: 'Bryan Butler',
        employmentType: 'Permanent',
        startDate: '20/3/2018',
        type: 'withdraw',
      },
      {
        id: 214,
        agentName: 'Marguerite Stanley',
        employmentType: 'Contract',
        startDate: '25/1/2019',
        type: 'deposit',
      },
      {
        id: 215,
        agentName: 'Bill Stewart',
        employmentType: 'Permanent',
        startDate: '5/5/2026',
        type: 'withdraw',
      },
      {
        id: 216,
        agentName: 'Tillie Hanson',
        employmentType: 'Contract',
        startDate: '17/9/2071',
        type: 'withdraw',
      },
      {
        id: 217,
        agentName: 'Catherine Turner',
        employmentType: 'Contract',
        startDate: '30/5/2114',
        type: 'convertPoint',
      },
      {
        id: 218,
        agentName: 'Jacob Gray',
        employmentType: 'Permanent',
        startDate: '25/9/2086',
        type: 'deposit',
      },
      {
        id: 219,
        agentName: 'Olivia Schultz',
        employmentType: 'Contract',
        startDate: '8/11/2035',
        type: 'deposit',
      },
      {
        id: 220,
        agentName: 'Carrie Young',
        employmentType: 'Permanent',
        startDate: '7/5/2110',
        type: 'deposit',
      },
      {
        id: 221,
        agentName: 'Helen Barnes',
        employmentType: 'Permanent',
        startDate: '24/4/2067',
        type: 'convertPoint',
      },
      {
        id: 222,
        agentName: 'Harry Christensen',
        employmentType: 'Contract',
        startDate: '1/7/2095',
        type: 'convertPoint',
      },
      {
        id: 223,
        agentName: 'Leah Stone',
        employmentType: 'Permanent',
        startDate: '17/8/2097',
        type: 'convertPoint',
      },
      {
        id: 224,
        agentName: 'Pauline Nash',
        employmentType: 'Permanent',
        startDate: '21/5/2102',
        type: 'convertPoint',
      },

      {
        id: 225,
        agentName: 'Garrett Campbell',
        employmentType: 'Contract',
        startDate: '9/6/2110',
        type: 'deposit',
      },
      {
        id: 226,
        agentName: 'Alberta Hart',
        employmentType: 'Permanent',
        startDate: '20/9/2108',
        type: 'deposit',
      },
      {
        id: 227,
        agentName: 'Minerva Harper',
        employmentType: 'Contract',
        startDate: '16/3/2035',
        type: 'deposit',
      },
      {
        id: 228,
        agentName: 'Mike Frank',
        employmentType: 'Permanent',
        startDate: '21/7/2094',
        type: 'convertPoint',
      },
      {
        id: 229,
        agentName: 'Earl Stevenson',
        employmentType: 'Contract',
        startDate: '21/12/2036',
        type: 'rateAllocation',
      },
      {
        id: 230,
        agentName: 'Caleb Conner',
        employmentType: 'Permanent',
        startDate: '29/4/2037',
        type: 'withdraw',
      },
      {
        id: 231,
        agentName: 'George Potter',
        employmentType: 'Contract',
        startDate: '13/6/2081',
      },
      {
        id: 232,
        agentName: 'Micheal Ward',
        employmentType: 'Permanent',
        startDate: '25/11/2033',
      },
      {
        id: 233,
        agentName: 'Nina Fox',
        employmentType: 'Permanent',
        startDate: '5/7/2045',
      },
      {
        id: 234,
        agentName: 'Albert Alexander',
        employmentType: 'Contract',
        startDate: '3/4/2085',
      },

      {
        id: 235,
        agentName: 'Matilda Richards',
        employmentType: 'Contract',
        startDate: '8/6/2053',
      },
      {
        id: 236,
        agentName: 'Violet Cannon',
        employmentType: 'Contract',
        startDate: '6/11/2085',
      },
      {
        id: 237,
        agentName: 'Mark Pena',
        employmentType: 'Permanent',
        startDate: '14/6/2081',
      },
      {
        id: 238,
        agentName: 'Louis Gutierrez',
        employmentType: 'Contract',
        startDate: '25/5/2066',
      },
      {
        id: 239,
        agentName: 'Keith Bryant',
        employmentType: 'Permanent',
        startDate: '17/2/2084',
      },
      {
        id: 240,
        agentName: 'Gene Rodriquez',
        employmentType: 'Contract',
        startDate: '25/6/2027',
      },
      {
        id: 241,
        agentName: 'Chase Chapman',
        employmentType: 'Contract',
        startDate: '24/11/2092',
      },
      {
        id: 242,
        agentName: 'Birdie McDaniel',
        employmentType: 'Contract',
        startDate: '24/10/2087',
      },
      {
        id: 243,
        agentName: 'Louise Tyler',
        employmentType: 'Permanent',
        startDate: '6/3/2023',
      },
      {
        id: 244,
        agentName: 'Lucinda Pittman',
        employmentType: 'Contract',
        startDate: '18/4/2099',
      },
      {
        id: 245,
        agentName: 'Elizabeth Hoffman',
        employmentType: 'Contract',
        startDate: '26/9/2072',
      },

      {
        id: 246,
        agentName: 'Stephen Hubbard',
        employmentType: 'Contract',
        startDate: '26/9/2070',
      },
      {
        id: 247,
        agentName: 'Terry Perry',
        employmentType: 'Permanent',
        startDate: '16/10/2021',
      },
      {
        id: 248,
        agentName: 'Rosalie Becker',
        employmentType: 'Contract',
        startDate: '9/2/2083',
      },
      {
        id: 249,
        agentName: 'Louise Campbell',
        employmentType: 'Contract',
        startDate: '10/4/2097',
      },
      {
        id: 250,
        agentName: 'Lou Kelly',
        employmentType: 'Permanent',
        startDate: '29/6/2081',
      },
      {
        id: 251,
        agentName: 'Noah Zimmerman',
        employmentType: 'Contract',
        startDate: '1/4/2109',
      },
      {
        id: 252,
        agentName: 'Bess Nash',
        employmentType: 'Permanent',
        startDate: '26/8/2049',
      },
      {
        id: 253,
        agentName: 'Alfred Jones',
        employmentType: 'Permanent',
        startDate: '27/6/2033',
      },
      {
        id: 254,
        agentName: 'Effie Stokes',
        employmentType: 'Permanent',
        startDate: '31/3/2024',
      },
      {
        id: 255,
        agentName: 'Celia Carlson',
        employmentType: 'Contract',
        startDate: '2/3/2033',
      },
      {
        id: 256,
        agentName: 'Jeanette Cook',
        employmentType: 'Contract',
        startDate: '27/10/2109',
      },
      {
        id: 257,
        agentName: 'John Warner',
        employmentType: 'Permanent',
        startDate: '7/5/2064',
      },
      {
        id: 258,
        agentName: 'Harold Carter',
        employmentType: 'Contract',
        startDate: '19/1/2098',
      },
      {
        id: 259,
        agentName: 'Alfred Valdez',
        employmentType: 'Contract',
        startDate: '22/2/2064',
      },
      {
        id: 260,
        agentName: 'Victoria Lowe',
        employmentType: 'Contract',
        startDate: '24/5/2062',
      },
      {
        id: 261,
        agentName: 'Henry Adkins',
        employmentType: 'Permanent',
        startDate: '13/4/2024',
      },
      {
        id: 262,
        agentName: 'Roger Jennings',
        employmentType: 'Contract',
        startDate: '23/8/2038',
      },
      {
        id: 263,
        agentName: 'Lloyd Casey',
        employmentType: 'Permanent',
        startDate: '17/11/2057',
      },
      {
        id: 264,
        agentName: 'Jon Weaver',
        employmentType: 'Permanent',
        startDate: '19/10/2082',
      },
      {
        id: 265,
        agentName: 'Curtis Silva',
        employmentType: 'Permanent',
        startDate: '30/1/2043',
      },
      {
        id: 266,
        agentName: 'Rosalie Ryan',
        employmentType: 'Permanent',
        startDate: '21/8/2104',
      },
      {
        id: 267,
        agentName: 'Nannie McBride',
        employmentType: 'Contract',
        startDate: '18/7/2049',
      },
      {
        id: 268,
        agentName: 'Rena Gill',
        employmentType: 'Permanent',
        startDate: '7/1/2101',
      },
      {
        id: 269,
        agentName: 'Jorge Kim',
        employmentType: 'Permanent',
        startDate: '3/12/2026',
      },
      {
        id: 270,
        agentName: 'Jennie Pittman',
        employmentType: 'Contract',
        startDate: '19/6/2085',
      },
      {
        id: 271,
        agentName: 'Rosetta Mitchell',
        employmentType: 'Contract',
        startDate: '5/2/2085',
      },
      {
        id: 272,
        agentName: 'Glenn Little',
        employmentType: 'Permanent',
        startDate: '18/8/2059',
      },
      {
        id: 273,
        agentName: 'Julian Soto',
        employmentType: 'Permanent',
        startDate: '31/10/2053',
      },
      {
        id: 274,
        agentName: 'Susan Morgan',
        employmentType: 'Permanent',
        startDate: '19/6/2105',
      },
      {
        id: 275,
        agentName: 'Raymond Bowen',
        employmentType: 'Contract',
        startDate: '12/7/2086',
      },
      {
        id: 276,
        agentName: 'Ivan Moore',
        employmentType: 'Permanent',
        startDate: '28/7/2089',
      },
      {
        id: 277,
        agentName: 'Norman Cain',
        employmentType: 'Permanent',
        startDate: '19/12/2052',
      },
      {
        id: 278,
        agentName: 'Ola Vasquez',
        employmentType: 'Permanent',
        startDate: '5/2/2084',
      },
      {
        id: 279,
        agentName: 'Danny Lloyd',
        employmentType: 'Permanent',
        startDate: '22/11/2057',
      },
      {
        id: 280,
        agentName: 'Douglas Payne',
        employmentType: 'Contract',
        startDate: '7/2/2043',
      },
      {
        id: 281,
        agentName: 'Grace Todd',
        employmentType: 'Permanent',
        startDate: '12/2/2021',
      },
      {
        id: 282,
        agentName: 'Mattie Potter',
        employmentType: 'Contract',
        startDate: '24/9/2074',
      },
      {
        id: 283,
        agentName: 'Jason Fowler',
        employmentType: 'Permanent',
        startDate: '20/9/2073',
      },
      {
        id: 284,
        agentName: 'Lola Ryan',
        employmentType: 'Permanent',
        startDate: '13/4/2022',
      },
      {
        id: 285,
        agentName: 'Ricardo Chambers',
        employmentType: 'Contract',
        startDate: '21/9/2085',
      },
      {
        id: 286,
        agentName: 'Paul Luna',
        employmentType: 'Permanent',
        startDate: '11/9/2054',
      },
      {
        id: 287,
        agentName: 'Hallie Warren',
        employmentType: 'Contract',
        startDate: '13/1/2033',
      },
      {
        id: 288,
        agentName: 'Henry Moody',
        employmentType: 'Permanent',
        startDate: '22/9/2097',
      },
      {
        id: 289,
        agentName: 'Sophie Terry',
        employmentType: 'Permanent',
        startDate: '16/3/2048',
      },
      {
        id: 290,
        agentName: 'Hannah Ferguson',
        employmentType: 'Contract',
        startDate: '27/5/2116',
      },

      {
        id: 291,
        agentName: 'Isabella Harris',
        employmentType: 'Contract',
        startDate: '11/4/2051',
      },
      {
        id: 292,
        agentName: 'Matthew Carr',
        employmentType: 'Permanent',
        startDate: '30/7/2058',
      },
      {
        id: 293,
        agentName: 'Fannie Hernandez',
        employmentType: 'Contract',
        startDate: '27/3/2078',
      },
      {
        id: 294,
        agentName: 'Sam Sims',
        employmentType: 'Permanent',
        startDate: '27/11/2050',
      },
      {
        id: 295,
        agentName: 'Josie Hanson',
        employmentType: 'Permanent',
        startDate: '16/6/2115',
      },
      {
        id: 296,
        agentName: 'Barry Obrien',
        employmentType: 'Permanent',
        startDate: '29/5/2061',
      },
      {
        id: 297,
        agentName: 'John Carter',
        employmentType: 'Permanent',
        startDate: '27/9/2029',
      },
      {
        id: 298,
        agentName: 'Julia Clayton',
        employmentType: 'Contract',
        startDate: '17/6/2035',
      },
      {
        id: 299,
        agentName: 'Myrtle Logan',
        employmentType: 'Permanent',
        startDate: '28/12/2065',
      },
      {
        id: 300,
        agentName: 'Leila Rowe',
        employmentType: 'Permanent',
        startDate: '27/10/2047',
      },
    ],
  },
  {
    id: 301,
    agentName: 'Ernest Schwartz',
    employmentType: 'Contract',
    startDate: '6/8/2072',
    type: 'deposit',
    children: [
      {
        id: 302,
        agentName: 'Lewis Cobb',
        employmentType: 'Permanent',
        startDate: '21/6/2060',
        type: 'withdraw',
      },
      {
        id: 303,
        agentName: 'Francis Burke',
        employmentType: 'Contract',
        startDate: '16/6/2067',
        type: 'convertPoint',
      },
      {
        id: 304,
        agentName: 'Rhoda Ellis',
        employmentType: 'Permanent',
        startDate: '25/12/2069',
        type: 'convertPoint',
      },
      {
        id: 305,
        agentName: 'Gertrude Houston',
        employmentType: 'Contract',
        startDate: '22/7/2052',
        type: 'rateAllocation',
      },
      {
        id: 306,
        agentName: 'Alice Welch',
        employmentType: 'Permanent',
        startDate: '19/7/2023',
        type: 'rateAllocation',
      },
      {
        id: 307,
        agentName: 'Sylvia Brock',
        employmentType: 'Permanent',
        startDate: '9/8/2048',
        type: 'deposit',
      },
      {
        id: 308,
        agentName: 'Lelia Christensen',
        employmentType: 'Contract',
        startDate: '5/10/2101',
        type: 'withdraw',
      },
      {
        id: 309,
        agentName: 'Brett Poole',
        employmentType: 'Permanent',
        startDate: '4/4/2050',
        type: 'deposit',
      },
      {
        id: 310,
        agentName: 'Theresa Morton',
        employmentType: 'Contract',
        startDate: '4/7/2074',
        type: 'rateAllocation',
      },
      {
        id: 311,
        agentName: 'Jeremiah Webb',
        employmentType: 'Contract',
        startDate: '25/9/2093',
        type: 'deposit',
      },
    ],
  },
];
