import { createSlice } from '@reduxjs/toolkit';
export interface PointInitialState {
  profileUser?: Object;
}

const initialState: PointInitialState = {};
export const profileSlice = createSlice({
  name: 'treeview-profile',
  initialState: initialState,
  reducers: {
    setState(state, action) {
      state.profileUser = action.payload;
    },
  },
});

export const { setState } = profileSlice.actions;

export default profileSlice.reducer;
