export enum PermissionEnum {
  LIST_ROLE_PERMISSION = 'listRolePermission',
  CHANGE_STATE_PERMISSION = 'changeStatePermission',
  URL_LIST_PERMISSION = 'listPermission',
}

export interface IRolePermission {
  agent_name: string;
  role_code: string;
  role_level: number;
}

export interface IPermissionModel {
  permission_code: string;
  permission_name: string;
}
export interface IRolePermissionList {
  agentPermissionId: number;
  permissionModel: {
    permission_code: string;
    permission_name: string;
  };
  state: number;
}

export interface IResponsePermission {
  agent: IRolePermission;
  agentPermissionList: Array<IRolePermissionList>;
}

export interface IResPermission {
  content: Array<IResponsePermission>;
  pageable: object;
  totalPages: number;
  last: boolean;
  totalElements: number;
  size: number;
  number: number;
  sort: object;
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}

export interface IPermissionType {
  agentName?: string;
  setting?: string;
  agentsTreeView?: string;
  limit?: string;
  dashboard?: string;
  agentsList?: string;
  agentsCashTransactions?: string;
  agentsPointTransactions?: string;
  usersTransactions?: string;
  transactions?: string;
  reportPointDaily?: string;
  notifications?: string;
  agentsPermissionSettings?: string;
  agentsHoldingProcess?: string;
  agentsHoldingDeposit?: string;
  agentsHoldingWithdraw?: string;
  gameHistory?: string;
  statisticAgentPoint?: string;
  statisticPointByVendor?: string;
  statisticDaily?: string;
  statisticCash?: string;
}

export interface IParamsPermission {
  agentPermissionId: number;
  state: string;
}

export interface IParamsPermissionList {
  page_no: number;
  page_size: number;
  agent_name: string;
}

export interface IDataPermission {
  agentPermissionId: number;
  state: string;
}

export interface IDataPermissionsById {
  [id: string]: IDataPermission;
}
