import { useContext, useEffect } from 'react';

import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import {
  alpha,
  Box,
  IconButton,
  lighten,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  Tooltip,
  useTheme,
} from '@mui/material';
import { SidebarContext } from 'src/contexts/SidebarContext';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import { LANGUAGE } from '@/interfaces/enum';
import { useAppDispatch, useAppSelector } from '@/hooks/hooks';
import { changeLanguageInput } from '@/store/common/commonSlice';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { TODO } from '@/interfaces';
import { formatNumber } from '@/utils/helpers';
import useSessionstorage from '@rooks/use-sessionstorage';

const HeaderWrapper = styled(Box)(({ theme }) => ({
  '&': {
    height: theme.header.height,
    color: theme.header.textColor,
    padding: theme.spacing(0, 2),
    right: 0,
    zIndex: 6,
    backgroundColor: alpha(theme.header.background || '', 0.95),
    backdropFilter: 'blur(3px)',
    position: 'fixed',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      left: theme.sidebar.width,
      width: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      height: theme.header.height,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      height: theme.header.heightMd,
      paddingTop: 16,
    },
    [theme.breakpoints.down('sm')]: {
      height: theme.header.heightXs,
      paddingTop: 16,
    },

    boxShadow:
      theme.palette.mode === 'dark'
        ? `0 1px 0 ${alpha(
            lighten(theme.colors.primary.main, 0.7),
            0.15,
          )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
        : `0px 2px 8px -3px ${alpha(theme.colors.alpha.black[100], 0.2)}, 0px 5px 22px -4px ${alpha(
            theme.colors.alpha.black[100],
            0.1,
          )}`,
  },
}));

const ChangeLanguage = styled(Select)(({ theme }) => ({
  '&&&': {
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '.MuiSelect-select': {
      borderColor: 'transparent',
      '.MuiSelect-nativeInput': {
        border: 'none',
      },
    },
  },
}));

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const language = useAppSelector((state) => state.common.languageInput);
  const [value, set] = useSessionstorage('lang', 'kr');
  const { cash_balance, point_balance, commission_balance } = useAppSelector((state) => state.profileInfo.user);
  const onChangeInput = (e: SelectChangeEvent<unknown>) => {
    set(e.target.value);
    dispatch(changeLanguageInput(e.target.value));
  };
  useEffect(() => {
    dispatch(changeLanguageInput(value));
  }, [dispatch, value]);
  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Box
        alignItems="center"
        justifyContent="flex-end"
        sx={{
          display: { md: 'flex', xs: 'block' },
          marginLeft: { sm: 'auto', xs: 0 },
          width: { sm: 'unset', xs: '-webkit-fill-available' } 
        }}>
        <Box>
          <Box
            display="flex"
            justifyContent={'space-between'}
            sx={{
              flexDirection: { sm: 'row', xs: 'column' },
            }}>
            <Box sx={{ ml: { sm: '15px', xs: 0 } }}>
              <InputGroup className="mb-0">
                <InputGroup.Text>P</InputGroup.Text>
                <Form.Control aria-label="Amount (to the nearest dollar)" value={formatNumber(point_balance ?? 0)} />
                <InputGroup.Text>POINT</InputGroup.Text>
              </InputGroup>
            </Box>
            <Box sx={{ ml: { sm: '15px', xs: 0 }, mr: { md: '15px', sm: 0 }, mt: { sm: 0, xs: 1 } }}>
              <InputGroup className="mb-0">
                <InputGroup.Text>₩</InputGroup.Text>
                <Form.Control aria-label="Amount (to the nearest dollar)" value={formatNumber(cash_balance ?? 0)} />
                <InputGroup.Text>KRW</InputGroup.Text>
              </InputGroup>
            </Box>
          </Box>
        </Box>

        <Box
          className="d-flex center-item"
          sx={{
            justifyContent: {
              xs: 'flex-end',
            },
          }}>
          <HeaderButtons />
          <ChangeLanguage onChange={onChangeInput} value={language}>
            <MenuItem value={LANGUAGE.KR}>Korean</MenuItem>
            <MenuItem value={LANGUAGE.EN}>English</MenuItem>
          </ChangeLanguage>
          <HeaderUserbox />
          <Box
            component="span"
            sx={{
              ml: 2,
              display: { lg: 'none', xs: 'inline-block' },
            }}>
            <Tooltip arrow title="Toggle Menu">
              <IconButton color="primary" onClick={toggleSidebar}>
                {!sidebarToggle ? <MenuTwoToneIcon fontSize="small" /> : <CloseTwoToneIcon fontSize="small" />}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
