import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  *,
  *:before,
  *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
    margin: 0;

    &:not([class*='padding-']) {
      padding: 0;
    }
  }

  p {
    margin: 0;
    padding: 0;
  }

  .btn-form {
    background-color: #9c6aff;
    transition: 0.01ms ease transform;

    &:active {
      transform: scale(0.99);
    }
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 550px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .header-center {
    .ag-header-cell-label {
      justify-content: center !important;
    }
  }

  .w-30 {
    width: 30%;
  }

  .w-70 {
    width: 70%;
  }

  .w-45 {
    width: 45%;
  }

  .w-50 {
    width: 50%;
  }

  .text-title {
    font-size: 1rem;
    font-weight: 500;
  }

  .center-item {
    display: flex;
    align-items: center;
  }

  .top-items {
    display: flex;
    align-items: flex-start;
  }

  .line-bottom {
    border-bottom: 1px solid #dcdcdc;
    box-sizing: border-box;
    height: 100%;
  }

  .line-right {
    border-left: 1px solid #dcdcdc;
    box-sizing: border-box;
  }

  .border-form {
    border: 1px solid gray;
  }

  .flex-justify-between {
    display: flex;
  }

  .width-date-picker {
    width: 25em;
  }

  .center-button {
    display: flex;
    justify-content: stretch;
    align-items: center;
  }

  .w-full {
    width: 100%;
  }

  .tag-list {
    width: 100% !important;
  }

  .tag-item {
  }

  .dropdown-trigger {
    width: 100% !important;
    padding: 0 !important;
    border-radius: 10px;
  }

  .dropdown-trigger:after {
    margin-left: 15px !important;
    text-align: end;
  }

  .dropdown {
    width: 100% !important;
  }

  .search {
    width: 100% !important;
    border: none !important;
    padding: 13px 13px 13px 14px;
    background-color: transparent;
  }

  .dropdown-content {
    width: 100% !important;
    z-index: 9 !important;
    max-height: 600px;
    scroll-behavior: smooth;
  }

  .dropdown-content ul {
    width: 100% !important;
    z-index: 9 !important;
    max-height: 600px;
    scroll-behavior: smooth;
  }

  .dropdown-content ul div {
    width: 100% !important;
    z-index: 9 !important;
    max-height: 600px;
    scroll-behavior: smooth;
  }

  .tag {
    padding: 8px;
    border-radius: 8px;
    margin-left: 10px !important;
    background-color: #dcdcdc;
  }

  .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
    content: '\\25B2';
    position: absolute;
    right: 10px;
    top: 15px;
    color: #3c3c3c;
  }

  .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
    content: '\\25BC';
    position: absolute;
    right: 10px;
    top: 15px;
    color: #3c3c3c;
  }

  .toggle.collapsed:after {
    content: '+';
    color: #3c3c3c;
  }

  .toggle.expanded:after {
    content: '-';
    color: #3c3c3c;
  }

  .btn-search-form {
    transition: 0.01ms ease transform;

    &:active {
      transform: scale(0.99);
    }

    &:disabled {
      cursor: not-allowed;
      transform: unset;
    }
  }

  .header-center {
    .ag-header-cell-label {
      padding-left: 15px;
      justify-content: center !important;
    }
  }

  .parent-cell {
    width: 100%;
    text-align: center;
    line-height:1.7;

    &__1 {
      width: 100%;
    }

    &__2 {
      color: rgba(0, 0, 0, 0.61);
      width: 100%;
      font-size: 13px;
    }
  }

  .form-check {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &-input {
      width: 1rem;
      height: 1rem;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .search-selector {
    height: 56px;
    margin-bottom: 5px;
  }

  .search-selector .MuiInputBase-root {
    height: 100%;
  }

@media only screen and (max-width: 576px) {
  div.ag-theme-alpine {
    --ag-font-size: 10px;
  }

  .text-title {
    font-size: 0.875rem;
  }
}
`;

export * from './color';
export * from './general';
