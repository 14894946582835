import { IResponseApi, IResponseUploadFile, TODO } from '@/interfaces';
import * as CONSTANT from '@/interfaces/constants';
import { APP_CONFIG } from '@/utils/env';
import { getStorageItem, removeStorageItem, setStorageItem } from '@/utils/storage';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';
class RequestApi {
  private static instance: RequestApi;
  private static uninterceptedAxiosInstance = axios.create();
  private static isRefreshing = false;
  private static refreshSubscribers: ((token: string) => void)[] = [];

  static getInstance(url?: string) {
    if (RequestApi.instance) {
      return RequestApi.instance;
    }
    axios.defaults.baseURL = url;
    axios.defaults.headers.common = {
      'Content-Type': 'application/json',
    };
    RequestApi.uninterceptedAxiosInstance.defaults.baseURL = url;
    RequestApi.uninterceptedAxiosInstance.defaults.headers.common = {
      'Content-Type': 'application/json',
    };
    axios.interceptors.request.use(
      async (config) => {
        config.headers = config.headers ?? {};
        const token = getStorageItem(CONSTANT.ACCESS_TOKEN) || Cookies.get(CONSTANT.ACCESS_TOKEN);
        if (token && token.length > 40) {
          config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
      },
      function (error) {
        return Promise.reject(error);
      },
    );
    axios.interceptors.response.use(
      // Do something with response data
      (response: AxiosResponse) => {
        return response.data;
      },
      // Do something with response error
      (error) => {
        const subscribeTokenRefresh = (cb: (token: string) => void) => {
          this.refreshSubscribers.push(cb);
        };

        const onRefreshed = (token: string) => {
          this.refreshSubscribers.map((cb) => cb(token));
        };
        const { response, config } = error;
        if (!config) {
          return Promise.reject(error.response);
        }
        const originalRequest = config;
        if (axios.isCancel(error)) {
          console.debug('Request canceled', error.message);
        }

        if (response?.status === 401) {
          if (isEmpty(response.data) || !response.data.message.includes('JWT expired at')) {
            removeStorageItem(CONSTANT.ACCESS_TOKEN);
            removeStorageItem(CONSTANT.REFRESH_TOKEN);
            Cookies.remove(CONSTANT.ACCESS_TOKEN, { path: '' });
            Cookies.remove(CONSTANT.REFRESH_TOKEN, { path: '' });
            window.location.href = '/login';
            return Promise.reject(error.response);
          }
          const refreshToken = getStorageItem(CONSTANT.REFRESH_TOKEN) || Cookies.get(CONSTANT.REFRESH_TOKEN);
          const path = `${process.env.REACT_APP_URL_UAT}/auth-service/authentication/refresh-token`;
          const data = {
            refreshToken: refreshToken,
          };
          const retryOrigReq = new Promise((resolve, reject) => {
            subscribeTokenRefresh((token) => {
              resolve(
                axios({
                  ...originalRequest,
                  //important because header doesn't understand string
                  headers: originalRequest.headers.toJSON(),
                }),
              );
              reject(() => {
                removeStorageItem(CONSTANT.ACCESS_TOKEN);
                removeStorageItem(CONSTANT.REFRESH_TOKEN);
                Cookies.remove(CONSTANT.ACCESS_TOKEN, { path: '' });
                Cookies.remove(CONSTANT.REFRESH_TOKEN, { path: '' });
                window.location.href = '/login';
              });
            });
          });
          if (!this.isRefreshing) {
            this.isRefreshing = true;
            RequestApi.uninterceptedAxiosInstance
              .post(path, data)
              .then((newToken) => {
                if (Boolean(getStorageItem(CONSTANT.ACCESS_TOKEN))) {
                  setStorageItem(CONSTANT.ACCESS_TOKEN, newToken.data.token);
                } else {
                  Cookies.set(CONSTANT.ACCESS_TOKEN, newToken.data.token);
                }
                this.isRefreshing = false;
                onRefreshed(newToken.data.token);
              })
              .catch(() => {
                removeStorageItem(CONSTANT.ACCESS_TOKEN);
                removeStorageItem(CONSTANT.REFRESH_TOKEN);
                Cookies.remove(CONSTANT.ACCESS_TOKEN, { path: '' });
                Cookies.remove(CONSTANT.REFRESH_TOKEN, { path: '' });
                window.location.href = '/login';
              });
          }

          return retryOrigReq;
          // try {
          //   const res: TODO = RequestApi.uninterceptedAxiosInstance.post(path, data);
          // if (Boolean(getStorageItem(CONSTANT.ACCESS_TOKEN))) {
          //   setStorageItem(CONSTANT.ACCESS_TOKEN, res.token);
          // } else {
          //   Cookies.set(CONSTANT.ACCESS_TOKEN, res.token);
          // }
          //   axios.defaults.headers.common.Authorization = `Bearer ${res.data.data.token}`;
          //   // call lai api ban dau
          // } catch (_error) {
          //   console.error('error', _error);
          //   console.log('VLLLLL');

          // removeStorageItem(CONSTANT.ACCESS_TOKEN);
          // removeStorageItem(CONSTANT.REFRESH_TOKEN);
          // Cookies.remove(CONSTANT.ACCESS_TOKEN, { path: '' });
          // Cookies.remove(CONSTANT.REFRESH_TOKEN, { path: '' });
          // window.location.href = '/login';
          // }
        } else if (response?.status === 400 && response.data.message.includes('Refresh token')) {
          removeStorageItem(CONSTANT.ACCESS_TOKEN);
          removeStorageItem(CONSTANT.REFRESH_TOKEN);
          Cookies.remove(CONSTANT.ACCESS_TOKEN, { path: '' });
          Cookies.remove(CONSTANT.REFRESH_TOKEN, { path: '' });
          window.location.href = '/login';
        } else {
          return Promise.reject(error.response);
        }
      },
    );
    return new RequestApi();
  }

  get = async <T = unknown>(url: string, params?: object): Promise<IResponseApi<T>> => {
    try {
      return await axios.get(url, { params });
    } catch (err) {
      const error = err as AxiosResponse;
      throw error.data;
    }
  };

  downloadFile = async (url: string, params?: object): Promise<string> => {
    try {
      return await axios.get(url, { params, responseType: 'arraybuffer' });
    } catch (err) {
      const error = err as AxiosResponse;
      throw error.data;
    }
  };
  post = async <T>(url: string, body?: object): Promise<IResponseApi<T>> => {
    try {
      return await axios.post(url, body);
    } catch (err) {
      const error = err as AxiosResponse;
      throw error.data;
    }
  };
  uploadFile = async (
    url: string,
    file: FormData,
    config?: AxiosRequestConfig<FormData> | undefined,
  ): Promise<IResponseUploadFile> => {
    try {
      return await axios.post(url, file, config);
    } catch (err) {
      const error = err as AxiosResponse;
      throw error.data;
    }
  };
  postUnintercepted = async <T = TODO>(url: string, body?: object): Promise<IResponseApi<T>> => {
    try {
      return await RequestApi.uninterceptedAxiosInstance.post(url, body);
    } catch (err) {
      const axiosError = err as AxiosError;
      const error = {
        code: axiosError.code,
        success: false,
        message: axiosError.message,
        data: axiosError.response?.data,
      };
      throw error;
    }
  };

  put = async <T = TODO>(url: string, body?: object): Promise<IResponseApi<T>> => {
    try {
      return await axios.put(url, body);
    } catch (err) {
      const error = err as AxiosResponse;
      throw error.data;
    }
  };

  patch = async <T = TODO>(url: string, body?: object): Promise<IResponseApi<T>> => {
    try {
      return await axios.patch(url, body);
    } catch (err) {
      const error = err as AxiosResponse;
      throw error.data;
    }
  };

  delete = async <T = TODO>(url: string, body?: object): Promise<IResponseApi<T>> => {
    try {
      return await axios.delete(url, {
        data: body,
      });
    } catch (err) {
      const error = err as AxiosResponse;
      throw error.data;
    }
  };
}

export default RequestApi.getInstance(APP_CONFIG.apiUrl);
