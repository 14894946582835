import requestApi from '../config';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  APITokenRenewResponse,
  IAccountInfoParams,
  IAccountInfoResponse,
  IAlertOnBalanceParams,
  IAlertOnBalanceResponse,
  IChangePasswordParams,
  IResponseApi,
  ISettingInfoResponse,
  SettingEnum,
  TokenRenewResponse,
} from '@/interfaces';
const url_change_token = '/auth-service/agent/token/renew';
const url_change_action = '/auth-service/agent';
const url_change_passwrd = '/password-change';
const url_change_callback = '/auth-service/agent/callback-token/renew';
const url_setting_infor = '/setting-info';

export const useChangeToken = () => {
  const url = url_change_token;
  return useMutation<IAlertOnBalanceResponse, IResponseApi, IAlertOnBalanceParams, number>(async (value) => {
    const { data } = await requestApi.post<APITokenRenewResponse>(url, value);
    return data;
  });
};

export const useUpdateAccountInfo = () => {
  const url = url_change_action;
  return useMutation<object | string, IResponseApi, IAccountInfoParams, number>(async (value) => {
    const data = await requestApi.patch(url, value);
    return data.code;
  });
};

export const useChangePassword = () => {
  const url = url_change_passwrd;
  return useMutation<IAccountInfoResponse, IResponseApi, IChangePasswordParams, number>(async (value) => {
    const { data } = await requestApi.put(url, value);
    return data;
  });
};

export const useChangeCallbackToken = () => {
  const url = url_change_callback;
  return useMutation<IAlertOnBalanceResponse, IResponseApi, IAlertOnBalanceParams, number>(async (value) => {
    const { data } = await requestApi.post<TokenRenewResponse>(url, value);
    return data;
  });
};

export const useSettingInfo = () => {
  const url = url_setting_infor;
  return useQuery<ISettingInfoResponse, IResponseApi>({
    queryKey: [SettingEnum.SETTING_INFO],
    queryFn: async () => {
      const { data } = await requestApi.get(url);
      return data;
    },
  });
};
