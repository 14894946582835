import { createSlice } from '@reduxjs/toolkit';
import { IParamsFilter } from '@/interfaces';

export interface IAgentInitialState {
  paramsFilter: IParamsFilter;
}

export const initialState: IAgentInitialState = {
  paramsFilter: {},
};
export const agentHoldingSlice = createSlice({
  name: 'agent-holding',
  initialState: initialState,
  reducers: {
    setParamsFilter(state, action) {
      state.paramsFilter = action.payload;
    },
  },
});

export const { setParamsFilter } = agentHoldingSlice.actions;

export default agentHoldingSlice.reducer;
