import { ComponentType, lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import BaseLayout from '@/layouts/BaseLayout';
import SidebarLayout from '@/layouts/SidebarLayout';

import SuspenseLoader from '@/components/SuspenseLoader';

const Loader =
  <T extends {}>(Component: ComponentType<T>) =>
  (props: T) =>
    (
      <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
      </Suspense>
    );

const Status404 = Loader(lazy(() => import('@/pages/Status/Status404')));
const Login = Loader(lazy(() => import('@/pages/auth/views/Login')));

const Dashboard = Loader(lazy(() => import('@/pages/dashboard/views/Dashboard')));
const AgentsTreeView = Loader(lazy(() => import('@/pages/agents/views/agentTreeView/AgentsTreeView')));
const Setting = Loader(lazy(() => import('@/pages/setting/views')));
const LimitPage = Loader(lazy(() => import('@/pages/limit/views')));

const AgentManagementPage = Loader(lazy(() => import('@/pages/agents/views/agentList/Agent-List')));
const AgentPointTransaction = Loader(lazy(() => import('@/pages/agents/views/agentPoint/AgentPointTransaction')));
const AgentCash = Loader(lazy(() => import('@/pages/agents/views/agentCash/AgentCash')));
const AgentPermission = Loader(lazy(() => import('@/pages/agents/views/agentPermission/AgentPermission')));
const AgentLoginHistory = Loader(lazy(() => import('@/pages/agents/views/agentLoginHistory/AgentLoginHistory')));
const StatisticPointByVendor = Loader(lazy(() => import('@/pages/Statistics/views/StatisticPointByVendor')));
const StatisticDaily = Loader(lazy(() => import('@/pages/Statistics/views/StatisticDaily')));
const StatisticCash = Loader(lazy(() => import('@/pages/Statistics/views/StatisticCash')));
const StatisticAgentPoint = Loader(lazy(() => import('@/pages/Statistics/views/StatisticAgentPoint')));

// agent holding
const AppProcessingHistory = Loader(lazy(() => import('@/pages/agentHolding/views/AppProcessingHistory')));
const PointDeposit = Loader(lazy(() => import('@/pages/agentHolding/views/PointDeposit')));
const CashWithdraw = Loader(lazy(() => import('@/pages/agentHolding/views/CashWithdraw')));

// user management
const UserList = Loader(lazy(() => import('@/pages/users/views/Users')));
const TransactionHistory = Loader(lazy(() => import('@/pages/users/views/TransactionHistory')));

// notifications
const Notifications = Loader(lazy(() => import('@/pages/notifications/views/Notifications')));
const CreateSendNotifications = Loader(lazy(() => import('@/pages/notifications/views/CreateSendNotifications')));
const GameHistory = Loader(lazy(() => import('@/pages/gameHistory/view')));

const Swagger = Loader(lazy(() => import('@/pages/swagger/view')));

const GameList = Loader(lazy(() => import('@/pages/gameList/views/GameList')));
const GameListByArgr = Loader(lazy(() => import('@/pages/gameList/views/GamesByAgrg')));
const Provider = Loader(lazy(() => import('@/pages/provider/views/provider-list')));
const ProviderDetail = Loader(lazy(() => import('@/pages/provider/views/provider-detail')));

const routes = (isLoggedIn: boolean) => [
  // auth stack
  {
    path: '/',
    element: !isLoggedIn ? <BaseLayout /> : <Navigate to="/setting" />,
    children: [
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
  // main stack
  {
    path: '/',
    element: isLoggedIn ? <SidebarLayout /> : <Navigate to="/login" />,
    children: [
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'agents',
        children: [
          {
            path: 'list',
            element: <AgentManagementPage />,
          },
          {
            path: 'tree-view',
            element: <AgentsTreeView />,
          },
          {
            path: 'point-transactions',
            element: <AgentPointTransaction />,
          },
          {
            path: 'cash-transactions',
            element: <AgentCash />,
          },
          {
            path: 'permission-settings',
            element: <AgentPermission />,
          },
          {
            path: 'login-history',
            element: <AgentLoginHistory />,
          },
        ],
      },
      {
        path: 'users',
        children: [
          {
            path: 'list',
            element: <UserList />,
          },
          {
            path: 'transactions',
            element: <TransactionHistory />,
          },
        ],
      },
      {
        path: 'limit',
        children: [
          {
            path: '',
            element: <LimitPage />,
          },
        ],
      },
      {
        path: 'game-history',
        children: [
          {
            path: '',
            element: <GameHistory />,
          },
        ],
      },
      {
        path: 'setting',
        children: [
          {
            path: '',
            element: <Setting />,
          },
        ],
      },
      {
        path: 'agents-holding',
        children: [
          {
            path: 'process',
            element: <AppProcessingHistory />,
          },
          {
            path: 'deposit',
            element: <PointDeposit />,
          },
          {
            path: 'withdraw',
            element: <CashWithdraw />,
          },
        ],
      },
      {
        path: 'statistic',
        children: [
          {
            path: 'agent-point',
            element: <StatisticAgentPoint />,
          },
          {
            path: 'point-by-vendor',
            element: <StatisticPointByVendor />,
          },
          {
            path: 'daily',
            element: <StatisticDaily />,
          },
          {
            path: 'cash',
            element: <StatisticCash />,
          },
        ],
      },
      {
        path: 'notifications',

        children: [
          {
            path: '',
            element: <Notifications />,
          },
          {
            path: 'send-notifications',
            element: <CreateSendNotifications />,
          },
        ],
      },
      {
        path: 'swagger',

        children: [
          {
            path: '',
            element: <Swagger />,
          },
        ],
      },
      {
        path: 'game-list',

        children: [
          {
            path: '',
            element: <GameList />,
          },
          {
            path: 'detail/:pvrId/:agrgId',
            element: <GameListByArgr />,
          }
        ],
      },
      {
        path: 'provider',

        children: [
          {
            path: '',
            element: <Provider />,
          },
          {
            path: 'detail/:providerId',
            element: <ProviderDetail />,
          },
        ],
      },
    ],
  },
];

export default routes;
