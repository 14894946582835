import { PaginationParams, TODO } from '@/interfaces';
import { Dispatch, SetStateAction } from 'react';

export interface ISettingGameResponse {
  success: string;
  code: string;
  message: string;
  data: null;
}
export interface ISettingVendorResponse {
  success: string;
  code: string;
  message: string;
  data: null;
}
export interface ISettingForAllResponse {
  success: string;
  code: string;
  message: string;
  data: null;
}

export type IValueFormGame = {
  game: { label: string; value: string }; // game id
} & IValueFormVendor;

export type IValueFormVendor = {
  vendor: { label: string; value: string }; // vendor id
} & IValueFormGlobal;

export type IValueFormGlobal = {
  stake: string; // limit betting
};
export enum LimitEnum {
  LIST_DATA_TABLE = 'ListDataTable',
  LIST_DATA_VENDOR_FORM = 'ListDataVendorForm',
}
export interface IOlympicData {
  athlete: string;
  age: number;
  country: string;
  year: number;
  date: string;
  sport: string;
  gold: number;
  silver: number;
  bronze: number;
  total: number;
  id?: number | string;
}

export interface ITableLimitPops<T> {
  data: T;
}

export interface IInputSearchLimitPops<T> {
  setData: Dispatch<SetStateAction<T>>;
  maxLength?: number;
}
export type IListGame = { gameKey: string; gameName: string; vendorKey: string };
export interface IListVendorGame {
  name: string;
  vendorKey: string;
  gameListVendorModels?: IListGame[];
}

export interface IFormProps {
  data?: string;
  reloadTable: () => void;
}

export interface IListDataTableResponse<T = TODO> {
  content?: T;
  totalElements?: number;
  totalPages?: number;
  size?: number;
  number?: number;
  first?: boolean;
  empty?: boolean;
}
export type IParamFilterLimitBet = {
  sort: string;
  agent_id?: string;
  search_field: string;
} & PaginationParams;
export type HistoryBettingContent = {
  id: string;
  vendorKey: string;
  gameName: string;
  gameKey: string;
  typeLimit: string;
  amountLimit: string;
  createDate: string;
  updateDate: string;
};
export interface DataHistoryBetting {
  content: HistoryBettingContent[];
  pageable: {
    sort: {
      sorted: boolean;
      unsorted: boolean;
      empty: boolean;
    };
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
  };
  totalElements: number;
  totalPages: number;
  last: boolean;
  size: number;
  number: number;
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}
export interface IListDataTableParam {
  page_size: number;
  page_number: number;
  agent_name: string;
  sort: string;
}
