import { ListTreeResponse, AgentType, TODO } from '@/interfaces';
import { ROLE_CODE } from '@/interfaces/enum';
import { colors } from '@mui/material';
import { isEmpty } from 'lodash';
export const filterTree = (payload: ListTreeResponse, node: string) => {
  const data: ListTreeResponse = JSON.parse(JSON.stringify(payload));
  if (!node) {
    return data;
  }
  if (data.children) {
    const filtered = findNode(data.children, node.toLowerCase());
    if (data.agentName.trim().toLowerCase().includes(node.toLowerCase()) 
        ||data.id.includes(node)
        || filtered.length) {
      return Object.assign(data, { children: filtered });
    }
    return {};
  }
};

export const findNode = (data: ListTreeResponse[], node: string) => {
  return data.reduce((accumulator: ListTreeResponse[], currentValue: ListTreeResponse) => {
    if ((currentValue.agentName.trim().toLowerCase().includes(node.toLowerCase())
      || currentValue.id.includes(node)) 
      && !currentValue.children) {
      accumulator.push(currentValue);
      return accumulator;
    }
    if (currentValue.children) {
      const filtered = findNode(currentValue.children, node.toLowerCase());
      if (currentValue.agentName.trim().toLowerCase().includes(node.toLowerCase()) 
        || currentValue.id.includes(node) 
        || filtered.length) {
        accumulator.push({ ...currentValue, children: filtered });
      }
    }
    return accumulator;
  }, []);
};

export const roleBgColor = (role: AgentType) => {
  switch (role) {
    case ROLE_CODE.SUPER_ADMIN:
      return colors.blue[500];
    case ROLE_CODE.MASTER_AGENT:
      return colors.yellow[500];
    case ROLE_CODE.AGENT_A:
      return colors.green[500];
    case ROLE_CODE.AGENT_B:
      return colors.red[500];
    case ROLE_CODE.AGENT_C:
      return colors.grey[500];
    case ROLE_CODE.AGENT_D:
      return colors.lime[500];
    case ROLE_CODE.AGENT_E:
      return colors.blueGrey[500];
    case ROLE_CODE.AGENT_F:
      return colors.cyan[500];
    case ROLE_CODE.AGENT_G:
      return colors.orange[50];
    case ROLE_CODE.AGENT_H:
      return colors.orange[100];
    case ROLE_CODE.AGENT_I:
      return colors.orange[200];
    case ROLE_CODE.AGENT_J:
      return colors.orange[300];
    case ROLE_CODE.AGENT_K:
      return colors.orange[400];
    case ROLE_CODE.AGENT_L:
      return colors.orange[500];
    case ROLE_CODE.AGENT_M:
      return colors.orange[600];
    case ROLE_CODE.AGENT_N:
      return colors.orange[700];
    case ROLE_CODE.AGENT_O:
      return colors.orange[800];
    case ROLE_CODE.AGENT_P:
      return colors.orange[900];
    case ROLE_CODE.AGENT_Q:
      return colors.indigo[50];
    case ROLE_CODE.AGENT_R:
      return colors.indigo[100];
    case ROLE_CODE.AGENT_S:
      return colors.indigo[200];
    case ROLE_CODE.AGENT_T:
      return colors.indigo[300];
    case ROLE_CODE.AGENT_U:
      return colors.indigo[400];
    case ROLE_CODE.AGENT_V:
      return colors.indigo[500];
    case ROLE_CODE.AGENT_W:
      return colors.indigo[600];
    case ROLE_CODE.AGENT_X:
      return colors.indigo[700];
    case ROLE_CODE.AGENT_Y:
      return colors.indigo[800];
    case ROLE_CODE.AGENT_Z:
      return colors.indigo[900];
    case ROLE_CODE.Normal:
      return colors.teal[400];
    default:
      return colors.teal[500];
  }
};

export const roleSymbol = (role: AgentType) => {
  switch (role) {
    case ROLE_CODE.SUPER_ADMIN:
      return 'SA';
    case ROLE_CODE.MASTER_AGENT:
      return 'MA';
    case ROLE_CODE.AGENT_A:
      return 'A';
    case ROLE_CODE.AGENT_B:
      return 'B';
    case ROLE_CODE.AGENT_C:
      return 'C';
    case ROLE_CODE.AGENT_D:
      return 'D';
    case ROLE_CODE.AGENT_E:
      return 'E';
    case ROLE_CODE.AGENT_F:
      return 'F';
    case ROLE_CODE.AGENT_G:
      return 'G';
    case ROLE_CODE.AGENT_H:
      return 'H';
    case ROLE_CODE.AGENT_I:
      return 'I';
    case ROLE_CODE.AGENT_J:
      return 'J';
    case ROLE_CODE.AGENT_K:
      return 'K';
    case ROLE_CODE.AGENT_L:
      return 'L';
    case ROLE_CODE.AGENT_M:
      return 'M';
    case ROLE_CODE.AGENT_N:
      return 'N';
    case ROLE_CODE.AGENT_O:
      return 'O';
    case ROLE_CODE.AGENT_P:
      return 'P';
    case ROLE_CODE.AGENT_Q:
      return 'Q';
    case ROLE_CODE.AGENT_R:
      return 'R';
    case ROLE_CODE.AGENT_S:
      return 'S';
    case ROLE_CODE.AGENT_T:
      return 'T';
    case ROLE_CODE.AGENT_U:
      return 'U';
    case ROLE_CODE.AGENT_V:
      return 'V';
    case ROLE_CODE.AGENT_W:
      return 'W';
    case ROLE_CODE.AGENT_X:
      return 'X';
    case ROLE_CODE.AGENT_Y:
      return 'Y';
    case ROLE_CODE.AGENT_Z:
      return 'Z';
    case ROLE_CODE.Normal:
      return 'NO';
    default:
      return 'No';
  }
};

export const findIdTree = (data: ListTreeResponse[]) => {
  return data.reduce((accumulator: string[], currentValue) => {
    accumulator.push(currentValue.id);
    if (currentValue.children) {
      accumulator.push(...findIdTree(currentValue.children));
    }
    return accumulator;
  }, []);
};

export const filterTreeData = (data: ListTreeResponse, node: string) => {
  const filterData = filterTree(data, node) as TODO;
  const ids: string[] = [];
  if (!isEmpty(filterData)) {
    ids.push(filterData.id, ...findIdTree(filterData.children || []));
  }
  return { filterData, ids };
};
