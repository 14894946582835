export enum LANGUAGE {
  KR = 'kr',
  EN = 'en',
}
export enum RootAction {
  LOGOUT = 'auth/setLogout',
}

export enum QUERY_KEYS {
  DASHBOARD = 'dashboard',
  POINT_TRANSACTIONS = 'point_transactions',
  SIDEBAR = 'SIDEBAR',
  GET_AGENT = 'get_agent',
  CHECK_FIREBASE = 'check_firebase',
  PROVIDER_DETAIL = 'provider_detail'
}

export enum MUTATIONS_KEY {
  AGENT_LIST = 'AGENT_LIST',
  POINT_CASH_HISTORY = 'POINT_CASH_HISTORY',
  APP_PROCESSING_HISTORY = 'APP_PROCESSING_HISTORY',
  POINT_DEPOSIT = 'POINT_DEPOSIT',
  USERS = 'USERS',
  TRANSACTION_HISTORY = 'TRANSACTION_HISTORY',
  CASH_WITHDRAWAL = 'POINT_DEPOSIT',
  GROUP_LIST = 'GROUP_LIST',
  UPLOAD_FILE = 'UPLOAD_FILE',
  GROUP_DETAIL = 'GROUP_DETAIL',
  GAME_LISTS = 'GAME_LISTS',
}

export enum STATUS_CODE {
  SUCCESS = 'SUCCESS',
}

export enum ROLE_CODE {
  SUPER_ADMIN = 'SUPER_ADMIN',
  MASTER_AGENT = 'MASTER_AGENT',
  AGENT_A = 'AGENT_A',
  AGENT_B = 'AGENT_B',
  AGENT_C = 'AGENT_C',
  AGENT_D = 'AGENT_D',
  AGENT_E = 'AGENT_E',
  AGENT_F = 'AGENT_F',
  AGENT_G = 'AGENT_G',
  AGENT_H = 'AGENT_H',
  AGENT_I = 'AGENT_I',
  AGENT_J = 'AGENT_J',
  AGENT_K = 'AGENT_K',
  AGENT_L = 'AGENT_L',
  AGENT_M = 'AGENT_M',
  AGENT_N = 'AGENT_N',
  AGENT_O = 'AGENT_O',
  AGENT_P = 'AGENT_P',
  AGENT_Q = 'AGENT_Q',
  AGENT_R = 'AGENT_R',
  AGENT_S = 'AGENT_S',
  AGENT_T = 'AGENT_T',
  AGENT_U = 'AGENT_U',
  AGENT_V = 'AGENT_V',
  AGENT_W = 'AGENT_W',
  AGENT_X = 'AGENT_X',
  AGENT_Y = 'AGENT_Y',
  AGENT_Z = 'AGENT_Z',
  Normal = 'Normal',
}
export const REGEX_PASSWORD = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,15}$/;

export const REGEX_PHONE_NUMBER = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4,})$/;

// export const REGEX_IP = /^[\d\s,.]*$/;
export const REGEX_IP =
  /((25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)(,\n|,?$))/;
export const REGEX_SINGLE_IP =
  /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4})|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])| ([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.){3}((25[0-5]|(2[0-4]|1\d|[1-9]|)\d))$/g;
export enum SUB_TYPE {
  COMMISSION = 'COMMISSION',
  MANUAL_PAYMENT = 'MANUAL_PAYMENT',
  MANUAL_DEDUCTION = 'MANUAL_DEDUCTION',
  APPLY_DEBIT = 'APPLY_DEBIT',
  DEBIT_RETURN = 'DEBIT_RETURN',
}

export enum SUB_TYPE_POINT {
  BET = 'BET',
  WIN = 'WIN',
  WIN_CANCEL = 'WIN_CANCEL',
  BET_CANCEL_RETURN = 'BET_CANCEL_RETURN',
  MANUAL_PAYMENT = 'MANUAL_PAYMENT',
  MANUAL_DEDUCTION = 'MANUAL_DEDUCTION',
  REQUEST_FOR_CREDIT = 'REQUEST_FOR_CREDIT',
  CANCEL_CANCEL = 'CANCEL_CANCEL',
  ADD = 'ADD',
  SUB = 'SUB',
  SUBALL = 'SUBALL'
}

export const URL_APP_PROCESSING = {
  URL_APPROVE: '/trans-service/transactions/processing/approval',
  URL_REFUND: '/trans-service/transactions/processing/refund',
};

export enum SENDER_NOTI {
  SYSTEM = 'System',
  SUPER_ADMIN = 'Super Admin',
}
