import { CASH_STATISTICS_TYPE_OPTION } from '@/interfaces';
import { createSlice } from '@reduxjs/toolkit';

export interface ICashStatisticInitalState {
  tranType: string;
}

export const cashStatisticInitialState: ICashStatisticInitalState = {
  tranType: CASH_STATISTICS_TYPE_OPTION.ALL,
};

export const cashStatisticSlice = createSlice({
  name: 'cashStatistic',
  initialState: cashStatisticInitialState,
  reducers: {
    setTranType(state, action) {
      state.tranType = action.payload;
    },
  },
});

export const { setTranType } = cashStatisticSlice.actions;

export default cashStatisticSlice.reducer;
