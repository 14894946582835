import requestApi from '../../config';
import { useMutation, useQuery } from '@tanstack/react-query';
import { IResponseApi, ListTreeResponse, TreeViewEnum, UserInfoResponse } from '@/interfaces';
import { agentDetail } from '@/pages/agents/store/agentSlice';
import { useAppDispatch } from '@/hooks/hooks';

const URL_LIST_TREE = '/auth-service/agents/tree';
const URL_DETAIL_AGENT = `/auth-service/v2/agent/details`;

export const useListTree = () => {
  return useQuery<ListTreeResponse, IResponseApi>({
    queryKey: [TreeViewEnum.LIST_TREE_DATA],
    queryFn: async () => {
      const { data } = await requestApi.get<ListTreeResponse>(URL_LIST_TREE);
      return data;
    },
  });
};
export const useDetailAgent = () => {
  const dispatch = useAppDispatch();
  return useMutation<UserInfoResponse, IResponseApi, string | number, number>(
    async (id) => {
      const { data } = await requestApi.get<UserInfoResponse>(URL_DETAIL_AGENT, { id });
      return data;
    },
    {
      onSuccess(data) {
        if (data) dispatch(agentDetail(data));
      },
    },
  );
};

export const useUserInfo = () => {
  return useMutation<UserInfoResponse, IResponseApi, string | number, number>(async (id) => {
    const url = `/user-info/${id}`;
    const { data } = await requestApi.get<UserInfoResponse>(url);
    return data;
  });
};
