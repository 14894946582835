import requestApi from '../../config';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  AgentTablePaginationParams,
  IAgentDetail,
  IContentDataTable,
  IResponseApi,
  IResponseUserList,
  IUseCreateAgentMutation,
  IUseUpdateAgentMutation,
  QUERY_KEYS,
  QUERY_KEYS_AGENT,
  TODO,
} from '@/interfaces';
import { CashPaymentDeductionData, PointPaymentDeductionData, RoleListType } from '@/pages/agents/model';
import { showError, showSuccess } from '@/utils/alert';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/hooks/hooks';
import { agentDetail } from '@/pages/agents/store/agentSlice';
import { setProfileInfo } from '@/store/common/profileInfoSlice';
import { useDetailAgent, useListTree } from '@/apis/service';
import { MUTATIONS_KEY } from '@/interfaces/enum';
import { useRegisterDevice as registerDevice } from '../notifications';

export type AgentRegisterDataType = {
  agent_id: string;
  agent_name: string;
  parent_id: string;
  password: string;
  point_rate: number;
  role_code: string;
  oper_mode?: string;
  whitelist_ips: string[];
  [s: string]: TODO;
};

export type AgentUpdateDataType = Partial<Omit<AgentRegisterDataType, 'whitelist_ip'>>;

export type AgentResType = { success: boolean; code: string; message: string };

const URL_AGENT_TABLE = '/auth-service/agents/list';
export const useGetAgentManagement = () => {
  return useMutation(
    async (params: AgentTablePaginationParams) => {
      const response = await requestApi.post<IResponseUserList<IContentDataTable>>(URL_AGENT_TABLE, params);
      return response;
    },
    { mutationKey: [MUTATIONS_KEY.AGENT_LIST] },
  );
};

const URL_DETAIL_CURRENT = '/auth-service/v2/agent/details';

export const useDetailsCurrent = () => {
  const dispatch = useAppDispatch();
  const agentDetailInfo = useAppSelector((state) => state.agent.agentDetail);
  const { mutateAsync } = registerDevice();

  return useQuery(
    [QUERY_KEYS_AGENT.QUERY_KEY_AGENT_PROFILE_INFO],
    async () => {
      const { data } = await requestApi.get<IAgentDetail>(URL_DETAIL_CURRENT);
      return data;
    },
    {
      onSuccess: (data) => {
        if (!Object.keys(agentDetailInfo).length) {
          dispatch(agentDetail(data));
        }
        dispatch(setProfileInfo(data));
        mutateAsync();
      },
    },
  );
};

const URL_GET_ROLE = '/auth-service/roles';
export const useGetRoleListQuery = (parent_id: string | undefined) => {
  return useQuery<RoleListType[], IResponseApi<RoleListType>>(
    [QUERY_KEYS.ROLE_LIST, parent_id],
    async () => {
      const response = await requestApi.get<RoleListType[]>(URL_GET_ROLE, { parent_id });
      return response.data;
    },
    {
      staleTime: 60 * 1000,
    },
  );
};

const URL_CREATE_AGENT = '/auth-service/v2/agent';
export const useCreateAgentMutation = ({ callback }: IUseCreateAgentMutation) => {
  const { t } = useTranslation();
  const { refetch } = useListTree();
  return useMutation<IResponseApi<null>, AgentResType, AgentRegisterDataType, number>(
    async (registerData) => {
      const res = await requestApi.post<never>(URL_CREATE_AGENT, registerData);
      return res;
    },
    {
      onSuccess: (res) => {
        if (res.success) {
          showSuccess(t(`${res.message}`));
          refetch();
          if (callback) callback();
        } else {
          showError(t(`${res.message}`));
        }
      },
      onError: (res) => {
        if (!res.success) {
          showError(t(`${res.message}`));
        }
      },
    },
  );
};

const URL_UPDATE_AGENT = '/auth-service/v2/agent';
export const useUpdateAgentMutation = ({ callback }: IUseUpdateAgentMutation) => {
  const { t } = useTranslation();
  const { mutate } = useDetailAgent();
  const { agent_id } = useAppSelector((state) => state.agent.agentDetail);
  const { refetch: refetchTreeView } = useListTree();

  return useMutation<IResponseApi<null>, AgentResType, AgentUpdateDataType, number>(
    async (updateData) => {
      const res = await requestApi.patch(URL_UPDATE_AGENT, updateData);
      return res;
    },
    {
      onSuccess: (res, updateData) => {
        if (res.success) {
          showSuccess(t(`${res.message}`));
          if (!agent_id) return;
          if (!('password' in updateData)) {
            mutate(agent_id);
            refetchTreeView();
          }
          if (callback) callback();
        }
      },
    },
  );
};

const URL_PAYMENT_DEDUCTION_POINT = '/auth-service/agentwallets/payment-deduction';
export const usePaymentDeductionPointMutation = (callback: VoidFunction) => {
  const { t } = useTranslation();
  const { agent_id } = useAppSelector((state) => state.agent.agentDetail);
  const { mutate } = useDetailAgent();
  const { refetch } = useDetailsCurrent();
  return useMutation(
    async (data: Partial<PointPaymentDeductionData>) => {
      return await requestApi.post(URL_PAYMENT_DEDUCTION_POINT, data);
    },
    {
      onSuccess: (res) => {
        if (res.success) {
          callback();
          if (!agent_id) return;
          mutate(agent_id);
          refetch();
          showSuccess(t(`${res.message}`));
        } else {
          showError(t(`${res.message}`));
        }
      },
    },
  );
};

const URL_PAYMENT_DEDUCTION_CASH = '/auth-service/agentwallets/cash-payment-deduction';
export const usePaymentDeductionCashMutation = (callback: VoidFunction) => {
  const { t } = useTranslation();
  const { agent_id } = useAppSelector((state) => state.agent.agentDetail);
  const { mutate } = useDetailAgent();
  const { refetch } = useDetailsCurrent();

  return useMutation(
    async (data: Partial<CashPaymentDeductionData>) => {
      return await requestApi.post(URL_PAYMENT_DEDUCTION_CASH, data);
    },
    {
      onSuccess: (res) => {
        if (res.success) {
          if (!agent_id) return;
          mutate(agent_id);
          refetch();
          callback();
          showSuccess(t(`${res.message}`));
        } else {
          showError(t(`${res.message}`));
        }
      },
    },
  );
};

const URL_REFRESH_AGENT = '/auth-service/agents/refresh-agent';
export const useRefreshAgent = () => {
  return useMutation(async () => {
    const res = await requestApi.get(URL_REFRESH_AGENT);
    return res;
  });
};