import styled from 'styled-components';
import { styled as styleMUI, Typography } from '@mui/material';
import { COLORS } from './color';

export const Wrapper = styled.div`
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

export const Title = styleMUI(Typography)(
  ({ theme }) => `
     background-color: ${COLORS.violet_subTitle};
     color: white;
     padding: ${theme.spacing(1, 1)};
     border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: ${theme.typography.pxToRem(16)}
`,
);

export const ButtonStyled = styled.button<{ color?: string }>`
  padding: 6px 12px;
  border-radius: 4px;
  background-color: ${(props) => props.color ?? COLORS.violet};
  color: white;
  border: none;
  transition: 0.01ms tranform ease;
  &:disabled {
    opacity: 0.5;
  }
  &:active {
    transform: scale(0.99);
  }
  a {
    color: white;
    text-decoration: none;
  }
`;

export const BoxBorder = {
  width: '1px',
  color: 'gray',
  style: 'solid',
  radius: '10px'
};