export enum GameHistoryEnum {
  GAME_HISTORY_LIST = 'GameHistoryList',
  GAME_HISTORY_DETAILS = 'GameHistoryDetails',
  GAME_BY_VENDOR = 'GameByVendor',
  SEARCH_REPORT = 'Search_Report'
}

export interface IGameHistory {
  bettingAmount?: number;
  date?: string;
  endTime?: string;
  gameName?: string;
  gameType?: string;
  playSessionId?: string;
  processingAmount?: number;
  startTime?: string;
  transSubType?: string;
  url?: string;
  hasUrl?: boolean;
  esb_result_cd?: string;
  dataType?: string
  jsonData?: any
  totalBetAmount?: any
  totalPaymentAmount?: any
  vendor?: string
  dealer?: string
  status?: string
  gameResults?: any
  messageError?: string
  billNo?: string
}

export interface IGameHistoryTable {
  agentId?: string;
  gameId?: string;
  startDate?: string;
  endDate?: string;
  transCode?: string;
  ogtxId?: string;
  type?: string;
  gameType?: string;
  getChildren?: boolean;
  page_size?: number;
  page_no?: number;
  sort?: string;
  gameRoundId?: string;
  vendorKeys?: Array<string>;
  userId?: string;
  filter?: string;
}

export interface IGameByVendor {
  gameKey?: string;
  gameName?: string;
}

export interface IGameHistoryLists {
  transId: string;
  transCode: string;
  gameRoundId: string;
  agentName: string;
  username: string;
  type: string;
  gameType?: string;
  status: string;
  game: string;
  vendorKey: string;
  processingAmount: number;
  previousAmount: number;
  processedDate: string;
  userId: string;
}

export interface ISearchReportRes {  
  bettingAmount: number | null;
  winAmount: number | null;
  bettingCase: number | null;
  winCase: number | null;
  totalCase: number;
  cancelAmount : number | null;
  cancelCase: number | null;
}

export interface ISearchReportState {  
  bettingAmount: string;
  winAmount: string;
  bettingCase: string;
  winCase: string;
  totalCase: string;
  profitLossAmount: string;
  cancelAmount : string;
  cancelCase: string;
}
