import { createSlice } from '@reduxjs/toolkit';

export interface IAgentInitialState {
  isCheckbox: boolean;
}

export const limitState: IAgentInitialState = { isCheckbox: false };
export const limitSlice = createSlice({
  name: 'limit',
  initialState: limitState,
  reducers: {
    limitCheckbox(state, action) {
      state.isCheckbox = action.payload;
    },
  },
});

export const { limitCheckbox } = limitSlice.actions;

export default limitSlice.reducer;
