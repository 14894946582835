import { useMutation, useQuery } from '@tanstack/react-query';

import Request from '@/apis/config';
import { IParamsFilter, IResponseApi, IResponseUserList, ListTreeResponse, TreeViewEnum } from '@/interfaces';
import { MUTATIONS_KEY } from '@/interfaces/enum';

type ITransPointContent = {
  agentId: string;
  agentName: string;
  targetAgent: string;
  targetAgentName: string;
  transId: string;
  type: string;
  processingAmount: string;
  previousAmount: string;
  currentAmount: string;
  note: string;
  processDate: string;
  gameTransId: string;
  subType: string;
};
type ITransCashContent = {
  agentId: string;
  targetAgent: string;
  transId: string;
  type: string;
  processingAmount: string;
  previousAmount: string;
  currentAmount: string;
  note: string;
  processDate: string;
  subType: string;
};

const URL_TRANS_CASH = '/trans-service/transactions/cash/list';
export const useTransactionCashMutation = () => {
  return useMutation({
    mutationFn: async (params: IParamsFilter) => {
      const res = await Request.post<IResponseUserList<ITransCashContent>>(URL_TRANS_CASH, params);
      return res;
    },
    mutationKey: [MUTATIONS_KEY.POINT_CASH_HISTORY],
    cacheTime: 0,
  });
};
const URL_TRANS_POINT = '/trans-service/transactions/point/list';
export const useTransactionPointMutation = () => {
  return useMutation({
    mutationFn: async (params: IParamsFilter) => {
      const res = await Request.post<IResponseUserList<ITransPointContent>>(URL_TRANS_POINT, params);
      return res;
    },
    mutationKey: [MUTATIONS_KEY.POINT_CASH_HISTORY],
    cacheTime: 0,
  });
};

const URL_LIST_TREE_AGENTS = '/auth-service/agents/tree/by-role';
export const useListTreeAgents = () => {
  return useQuery<ListTreeResponse, IResponseApi>({
    queryKey: [TreeViewEnum.LIST_TREE_DATA],
    queryFn: async () => {
      const { data } = await Request.get<ListTreeResponse>(URL_LIST_TREE_AGENTS);
      return data;
    },
  });
};
