import { useAppSelector } from '@/hooks/hooks';
import Status403 from '@/pages/Status/Status403';
import { Outlet, useLocation } from 'react-router';

export const RouteGuard = () => {
  const { pathname } = useLocation();

  const allowedPath = useAppSelector((state) => state.auth.sidebar);

  if (!allowedPath.length) return <Outlet />;

  // return <>{allowedPath.includes(pathname) ? <Outlet /> : <Status403 />}</>;
  return <>{<Outlet />}</>;
};
