import React, { useReducer } from 'react';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import HistoryIcon from '@mui/icons-material/History';
import InfoIcon from '@mui/icons-material/Info';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ApiIcon from '@mui/icons-material/Api';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PieChartIcon from '@mui/icons-material/PieChart';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import WindPowerIcon from '@mui/icons-material/WindPower';
import GamesIcon from '@mui/icons-material/Games';
import CategoryIcon from '@mui/icons-material/Category';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Button, List, ListItem, ListSubheader } from '@mui/material';
import { useContext } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { useSidebarQuery } from '@/apis/service/common';
import { Handyman } from '@mui/icons-material';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { useTranslation } from 'react-i18next';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { MenuTile, MenuWrapper, SubMenuWrapper, TextTile } from './style';
// necessary hard code
interface DropDownState {
  name: string | number;
  value: boolean;
}

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  const { t } = useTranslation();
  const { data } = useSidebarQuery();
  const [isSelected] = React.useState<Array<DropDownState>>([]);
  const [open, setOpen] = React.useState(true);

  const navs = [
    {
      key: 3,
      label: `${t('sidebar.agent.title')}`,
      icon: <AccountTreeIcon />,
      children: [
        {
          key: 34,
          label: `${t('sidebar.agent.tree')}`,
          path: '/agents/tree-view',
        },
        {
          key: 35,
          label: `${t('sidebar.agent.list')}`,
          path: '/agents/list',
        },
        {
          key: 36,
          label: `${t('sidebar.agent.point')}`,
          path: '/agents/point-transactions',
        },
        {
          key: 37,
          label: `${t('sidebar.agent.cash')}`,
          path: '/agents/cash-transactions',
        },
        {
          key: 38,
          label: `${t('sidebar.agent.permission')}`,
          path: '/agents/permission-settings',
        },
        {
          key: 39,
          label: `${t('sidebar.agent.agentLoginHistory')}`,
          path: '/agents/login-history',
        },
      ],
    },
    {
      key: 8,
      label: `${t('sidebar.transactions')}`,
      icon: <HistoryIcon />,
      path: '/game-history',
    },
    {
      key: 2,
      label: `${t('sidebar.limit')}`,
      icon: <DoNotDisturbIcon />,
      path: '/limit',
    },
    {
      key: 104,
      label: `${t('sidebar.agent-reserve.title')}`,
      icon: <WindPowerIcon />,
      children: [
        {
          key: 105,
          label: `${t('sidebar.agent-reserve.processing')}`,
          path: '/agents-holding/process',
        },
        {
          key: 106,
          label: `${t('sidebar.agent-reserve.deposit')}`,
          path: '/agents-holding/deposit',
        },
        {
          key: 107,
          label: `${t('sidebar.agent-reserve.withdraw')}`,
          path: '/agents-holding/withdraw',
        },
      ],
    },
    {
      key: 108,
      label: `${t('sidebar.statistic.title')}`,
      icon: <PieChartIcon />,
      children: [
        {
          key: 110,
          label: `${t('sidebar.statistic.agent-point')}`,
          path: '/statistic/agent-point',
        },
        {
          key: 1101,
          label: `${t('sidebar.statistic.point-vendor')}`,
          path: '/statistic/point-by-vendor',
        },
        {
          key: 1102,
          label: `${t('sidebar.statistic.daily')}`,
          path: '/statistic/daily',
        },
        {
          key: 1103,
          label: `${t('sidebar.statistic.cash')}`,
          path: '/statistic/cash',
        },
      ],
    },
    {
      key: 101,
      label: `${t('sidebar.user.title')}`,
      icon: <PeopleAltIcon />,
      children: [
        {
          key: 102,
          label: `${t('sidebar.user.list')}`,
          path: '/users/list',
        },
        {
          key: 103,
          label: `${t('sidebar.user.transactions')}`,
          path: '/users/transactions',
        },
      ],
    },
    {
      key: 114,
      label: `${t('sidebar.provider')}`,
      icon: <CategoryIcon />,
      path: '/provider',
    },
    {
      key: 115,
      label: `${t('sidebar.game_list')}`,
      icon: <GamesIcon />,
      path: '/game-list',
    },
    {
      key: 113,
      label: `${t('sidebar.api_document')}`,
      icon: <ApiIcon />,
      path: '/swagger',
    },
    {
      key: 9,
      label: `${t('sidebar.setting')}`,
      icon: <Handyman />,
      path: '/setting',
    },
    {
      key: 1,
      label: `${t('sidebar.dashboard')}`,
      icon: <StackedBarChartIcon />,
      path: '/dashboard',
    },
    {
      key: 111,
      label: `${t('sidebar.vendor')}`,
      icon: <InfoIcon />,
      path: '/vendors',
    },
    {
      key: 112,
      label: `${t('sidebar.notice')}`,
      icon: <NotificationsIcon />,
      path: '/notifications',
    },
  ];
  return (
    <MenuWrapper>
      {navs
        .filter((x) => x.children || data?.includes(x.path))
        .map((item) => {
          if (item.children) {
            const child = [...item.children].filter((x) => data?.includes(x.path));
            if (child.length <= 0) return null;

            if (isSelected.filter((e) => e.name === item.key).length === 0) {
              isSelected.push({ name: item.key, value: false });
            }
            return (
              <List
                key={item.key}
                component="div"
                subheader={
                  <ListSubheader component="div" disableSticky>
                    <MenuTile
                      onClick={() => {
                        isSelected.filter((e) => {
                          if (e.name === item.key) {
                            if (e.value) {
                              e.value = false;
                            } else {
                              e.value = true;
                            }
                          }
                          return true;
                        });
                        setOpen(!open);
                      }}>
                      {item.icon}
                      <TextTile>{item.label}</TextTile>
                      {
                        isSelected.some((e) => {
                          if (e.name === item.key && e.value === true) {
                            return true;
                          } else {
                            return false;
                          }
                        }) ? <ExpandLess 
                          sx={{
                            position:'absolute',
                            right: '5px'
                          }}
                        /> 
                        : 
                        <ExpandMore 
                          sx={{
                            position:'absolute',
                            right: '5px'
                          }}
                        />
                      }
                    </MenuTile>
                  </ListSubheader>
                }>
                {isSelected.some((e) => {
                  if (e.name === item.key && e.value === true) {
                    return true;
                  } else {
                    return false;
                  }
                }) && (
                  <SubMenuWrapper
                    sx={{
                      paddingTop: '10px',
                      paddingLeft: '25px'
                    }}
                  >
                    <List component="div">
                      {child.map((x) => {
                        return (
                          <ListItem key={`child-${x.key?.toString()}`} component="div">
                            <Button disableRipple component={RouterLink} onClick={closeSidebar} to={x.path}>
                              {x.label}
                            </Button>
                          </ListItem>
                        );
                      })}
                    </List>
                  </SubMenuWrapper>
                )}
              </List>
            );
          }
          return (
            <SubMenuWrapper key={item.key}>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    component={RouterLink}
                    onClick={closeSidebar}
                    to={item.path}
                    startIcon={item.icon}>
                    {item.label}
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          );
        })}
    </MenuWrapper>
  );
}

export default React.memo(SidebarMenu);
