import {
  alpha,
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useMemo, useRef, useState } from 'react';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import { styled } from '@mui/material/styles';

import { formatDistance } from 'date-fns';
import { enUS, ko } from 'date-fns/locale';
import {
  useCheckAllNotificationMutation,
  useListNotification,
  useNotificationUncheck,
} from '@/apis/service/notifications';
import { INotificationItem, NotificationList } from '@/interfaces';
import i18n from '@/i18n';
import { useModalContext } from '@/components/ModalProvider';
import { COLORS } from '@/global-styles';

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`,
);
const NotiMessageItem: FC<INotificationItem & { onClosePopover: () => void }> = ({
  id,
  title,
  content,
  time_send,
  onClosePopover,
  sender,
  status,
}) => {
  const modalContext = useModalContext();
  return (
    <ListItem
      onClick={() => {
        onClosePopover();
        modalContext.onShow({
          content,
          hasFooter: true,
          receive_date: time_send,
          sender,
          title,
          id,
        });
      }}
      key={id}
      sx={{
        p: 2,
        minWidth: 350,
        display: { xs: 'block', sm: 'flex', backgroundColor: status !== 'SEEN' ? COLORS.light_grey : '' },
      }}>
      <Box flex="1">
        <Box display="flex" justifyContent="space-between">
          <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
          <Typography variant="caption" sx={{ textTransform: 'none' }}>
            {formatDistance(new Date(time_send), new Date(), {
              addSuffix: true,
              locale: i18n.language === 'kr' ? ko : enUS,
            })}
          </Typography>
        </Box>
        <Typography
          // clamp 3 lines
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '3',
            WebkitBoxOrient: 'vertical',
          }}
          component="p"
          color="text.secondary">
          {content}
        </Typography>
      </Box>
    </ListItem>
  );
};
const DEFAULT_PAGE_NO = 0;
const DEFAULT_PAGE_SIZE = 10;
function HeaderNotifications() {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [notiList, setNotiList] = useState<NotificationList>();
  const { data, refetch } = useNotificationUncheck();

  const { mutateAsync } = useCheckAllNotificationMutation();
  const { mutateAsync: mutateGetList } = useListNotification();
  const handleOpen = (): void => {
    setOpen(true);
    mutateAsync().then(async () => {
      const dataList = await mutateGetList({ page_no: DEFAULT_PAGE_NO, page_size: DEFAULT_PAGE_SIZE });
      setNotiList(dataList.data);
      refetch({ stale: true });
    });
    //   if (data) {
    // }
  };

  const handleClose = (): void => {
    setOpen(false);
  };
  const isLastPage = useMemo(() => {
    if (!notiList) return true;
    return notiList.totalElements <= notiList?.numberOfElements;
  }, [notiList]);

  return (
    <>
      <Tooltip arrow title="Notifications">
        <IconButton color="primary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            badgeContent={(data?.data && data?.data.count) || 0}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}>
            <NotificationsActiveTwoToneIcon />
          </NotificationsBadge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <Box sx={{ p: 2 }} display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">Notifications</Typography>
        </Box>
        <Divider />
        <List sx={{ p: 0, width: 350 }}>
          {notiList?.content.map((item, index) => (
            <div style={{ cursor: 'pointer' }} key={index + 'itemNoti'}>
              <NotiMessageItem {...item} onClosePopover={handleClose} />
            </div>
          ))}
          {!isLastPage && (
            <ListItem sx={{ p: 2, minWidth: 350, display: { xs: 'block', sm: 'flex' } }}>
              <Button
                onClick={() => {
                  if (!notiList) return;
                  mutateGetList({
                    page_no: DEFAULT_PAGE_NO,
                    page_size: DEFAULT_PAGE_SIZE + notiList.numberOfElements,
                  }).then(async (dataListNoti) => {
                    setNotiList(dataListNoti.data);
                  });
                }}
                sx={{ fontWeight: 'bold', flex: 1 }}>
                Load more
              </Button>
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  );
}

export default HeaderNotifications;
