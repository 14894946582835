import { createSlice } from '@reduxjs/toolkit';

export interface AuthInitialState {
  access_token?: string;
  remember?: boolean;
  sidebar: string[];
  firebaseToken: string;
  isLogin: boolean;
  isRegisterDevice: boolean;
}

const initialState: AuthInitialState = { remember: false, sidebar: [], firebaseToken: '', isLogin: false, isRegisterDevice: false };
export const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    login(state, action) {
      state.access_token = action.payload;
    },
    setSidebar(state, action) {
      state.sidebar = action.payload;
    },
    setFirebaseToken(state, action) {
      state.firebaseToken = action.payload;
    },
    setRegisterDevice(state, action) {
      state.isRegisterDevice = action.payload;
    },
    setLogout() {},
  },
});

export const { login, setSidebar, setFirebaseToken, setLogout, setRegisterDevice } = authSlice.actions;

export default authSlice.reducer;
