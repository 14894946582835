import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { COLORS } from '@/global-styles';
import i18n from '@/i18n';

const MySwal = withReactContent(Swal);

export const showConfirm = (text = i18n.t('notify.confirm')) => {
  return MySwal.fire({
    title: 'Warning',
    text: text,
    icon: 'warning',
    confirmButtonText: 'Ok',
    confirmButtonColor: COLORS.violet_2,
    showCancelButton: true,
    cancelButtonText: 'Cancel',
    reverseButtons: true,
    focusConfirm: false,
    focusCancel: false,
    scrollbarPadding: false,
  }).then((result) => {
    return result.isConfirmed;
  });
};

export const showError = (errorMessage = i18n.t('common.modal.error')) => {
  return MySwal.fire({
    title: 'Error',
    text: errorMessage,
    icon: 'error',
    confirmButtonText: 'Ok',
    confirmButtonColor: COLORS.violet_2,
    reverseButtons: true,
    focusConfirm: false,
    focusCancel: false,
    scrollbarPadding: false,
  });
};

export const showSuccess = (message = i18n.t('common.modal.success')) => {
  return MySwal.fire({
    title: 'Success',
    text: message,
    icon: 'success',
    confirmButtonText: 'Ok',
    confirmButtonColor: COLORS.violet_2,
    reverseButtons: true,
    focusConfirm: false,
    focusCancel: false,
    scrollbarPadding: false,
  });
};

export const showModal = (text = i18n.t('notify.confirm')) => {
  return MySwal.fire({
    title: 'Warning',
    html: text,
    icon: 'warning',
    confirmButtonText: 'Ok',
    confirmButtonColor: COLORS.violet_2,
    showCancelButton: true,
    cancelButtonText: 'Cancel',
    reverseButtons: true,
    focusConfirm: false,
    focusCancel: false,
    scrollbarPadding: false,
  }).then((result) => {
    return result.isConfirmed;
  });
};

export const showDetail = (text = i18n.t('notify.confirm')) => {
  return MySwal.fire({
    title: 'Warning',
    text: text,
    icon: 'warning',
    confirmButtonText: i18n.t('limit.view_detail') as string,
    confirmButtonColor: COLORS.violet_2,
    showCancelButton: true,
    cancelButtonText: 'Cancel',
    reverseButtons: true,
    focusConfirm: false,
    focusCancel: false,
    scrollbarPadding: false,
  }).then((result) => {
    return result.isConfirmed;
  });
};

export const showDetailSuccess = (text = i18n.t('common.modal.success')) => {
  return MySwal.fire({
    title: 'Success',
    text: text,
    icon: 'success',
    confirmButtonText: i18n.t('limit.view_detail') as string,
    confirmButtonColor: COLORS.violet_2,
    showCancelButton: true,
    cancelButtonText: 'Cancel',
    reverseButtons: true,
    focusConfirm: false,
    focusCancel: false,
    scrollbarPadding: false,
  }).then((result) => {
    return result.isConfirmed;
  });
};

export const showDetailUrl = (text = i18n.t('common.modal.success')) => {
  return MySwal.fire({
    text: text,
    icon: 'warning',
    confirmButtonColor: COLORS.violet_2,
    reverseButtons: true,
    focusConfirm: false,
    focusCancel: false,
    scrollbarPadding: false,
  }).then((result) => {
    return result.isConfirmed;
  });
};
