import { useQuery, useQueryClient } from '@tanstack/react-query';
/* eslint-disable react-hooks/rules-of-hooks */
import requestApi from '../config';
import { useMutation } from '@tanstack/react-query';
import {
  GameHistoryEnum,
  IContentResponseApi,
  IGameByVendor,
  IGameHistory,
  IGameHistoryLists,
  ISearchReportRes,
  ISearchReportState,
} from '@/interfaces';
import { formatNumberToString } from '@/utils/number';
const url_game_list = '/trans-service/transactions/point/game-history';
const url_game_history_detail = 'trans-service/transactions/point/detail?trans_id=';
const URL_GAME_BY_VENDOR = '/adapter-service/game-provider/game-list?vendorKeys=';

const URL_SEARCH_REPORT = '/trans-service/transactions/point/game-history-statistics';

export const useGameLists = () => {
  const queryClient = useQueryClient();
  return useMutation([GameHistoryEnum.GAME_HISTORY_LIST], async (value: object) => {
    const { data } = await requestApi.post<IContentResponseApi<IGameHistoryLists>>(url_game_list, value);
    return data;
  });
};

export const useSearchReport = () => {
  return useMutation([GameHistoryEnum.SEARCH_REPORT], async (searchValue: object) => {
    const { data } = await requestApi.post<ISearchReportRes>(URL_SEARCH_REPORT, searchValue);
    const { bettingAmount, winAmount, bettingCase, winCase, totalCase, cancelAmount, cancelCase } = data;

    const roundBettingAmount = bettingAmount ? Math.round(bettingAmount) : 0;
    const roundWinAmount = winAmount ? Math.round(winAmount) : 0;
    const roundCancelAmount = cancelAmount ? Math.round(cancelAmount) : 0;

    const profitLossAmount = formatNumberToString(roundWinAmount + roundCancelAmount - roundBettingAmount);

    const reportState: ISearchReportState = {
      bettingAmount: formatNumberToString(roundBettingAmount),
      winAmount: formatNumberToString(roundWinAmount),
      bettingCase: formatNumberToString(bettingCase),
      winCase: formatNumberToString(winCase),
      totalCase: formatNumberToString(totalCase),
      profitLossAmount,
      cancelAmount: formatNumberToString(roundCancelAmount),
      cancelCase: formatNumberToString(cancelCase),
    };
    return reportState;
  });
};

export const useGameHistory = (id: string | number) => {
  return useQuery([GameHistoryEnum.GAME_HISTORY_DETAILS], async () => {
    const { data } = await requestApi.post<IGameHistory>(url_game_history_detail + id);
    return data;
  });
};

export const useGameByVendor = () => {
  return useMutation([GameHistoryEnum.GAME_BY_VENDOR], async (param: string) => {
    const data = await requestApi.get<Array<IGameByVendor>>(URL_GAME_BY_VENDOR + param);
    return data;
  });
};
