import { showError } from './../../../utils/alert';
import { useMutation } from '@tanstack/react-query';
import Request from '@/apis/config';
import {
  IParamsPermission,
  IParamsPermissionList,
  IPermissionModel,
  IResPermission,
  IResponseApi,
  PermissionEnum,
} from '@/interfaces';
import { permissionFindData, permissionMapData, permissionMapDataById } from '@/helpers';
import { useAppDispatch } from '@/hooks/hooks';
import { setAgentPermission, setPermissionsById } from '@/pages/agents/store/agentSlice';
import { useTranslation } from 'react-i18next';

const URL_LIST_ROLE_PERMISSION = '/auth-service/permission/list-role-permission';
const URL_CHANGE_STATE_PERMISSION = '/auth-service/permission/change-state';
const URL_LIST_PERMISSION = '/auth-service/permission/list-permission';

export const useAgentPermissionList = () => {
  const dispatch = useAppDispatch();
  return useMutation(
    async (params: IParamsPermissionList) => {
      const res = await Request.get<IResPermission>(URL_LIST_ROLE_PERMISSION, params);
      if (!res) return { totalElements: 0, data: [] };
      const data = await permissionMapData(res.data.content);
      return { totalElements: res.data.totalElements, data };
    },
    {
      mutationKey: [PermissionEnum.LIST_ROLE_PERMISSION],
      onSuccess: (data) => {
        const sumWithInitial = data.data.reduce(permissionFindData, []);
        const permissionsById = permissionMapDataById(sumWithInitial);
        dispatch(setAgentPermission(sumWithInitial));
        dispatch(setPermissionsById(permissionsById));
      },
    },
  );
};

export const useUpdateAgentPermission = () => {
  const { t } = useTranslation();
  return useMutation({
    mutationKey: [PermissionEnum.CHANGE_STATE_PERMISSION],
    mutationFn: async (params: IParamsPermission) => {
      return await Request.patch(URL_CHANGE_STATE_PERMISSION, params);
    },
    onError: async (error: IResponseApi) => {
      await showError(t(error?.message));
    },
  });
};

export const useListPermission = () => {
  return useMutation({
    mutationKey: [PermissionEnum.URL_LIST_PERMISSION],
    mutationFn: async () => {
      const { data } = await Request.get<Array<IPermissionModel>>(URL_LIST_PERMISSION);
      return data;
    },
  });
};
