import { ColDef, ValueSetterParams } from 'ag-grid-community';
import { isEqual, isNumber } from 'lodash';
import { useTranslation } from 'react-i18next';
import CheckboxRender from '@/components/FormField/checkboxField';
import { HistoryBettingContent } from '@/interfaces';
import { toast } from 'react-toastify';
import { formatDate } from '@/utils/helpers';
export const useColumnDefs = (): ColDef[] => {
  const { t } = useTranslation();

  return [
    {
      sortable: true,
      field: 'typeLimit',
      headerName: `${t('limit.type')}`,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      headerComponentFramework: CheckboxRender,
      filter: 'agTextColumnFilter',
    },
    {
      sortable: true,
      unSortIcon: true,
      field: 'vendorKey',
      filter: 'agTextColumnFilter',
      headerName: `${t('limit.vendor_KEY')}`,
    },
    {
      sortable: true,
      unSortIcon: true,
      field: 'gameKey',
      headerName: `${t('limit.game_KEY')}`,
    },
    {
      sortable: true,
      unSortIcon: true,
      field: 'gameName',
      headerName: `${t('limit.game_name')}`,
    },
    {
      sortable: true,
      unSortIcon: true,
      field: 'amountLimit',
      headerName: `${t('limit.maximum_bet_amount')}`,
      editable: (params) => {
        const listSelected = params.api.getSelectedRows();
        const isNodeSelected = listSelected.find((x) => x.id === params.data.id);
        if (isNodeSelected) return true;
        return false;
      },
      // cellRenderer: TextTableRender,
      valueFormatter: (params) => {
        const returnNumber = `${params.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return returnNumber || '0';
      },
      valueSetter: (params: ValueSetterParams) => {
        const newValue = params.newValue.trim();
        let valueChanged = params.data.amountLimit !== newValue;
        const numberParsed = parseInt(newValue);

        if (numberParsed > 0 && isNumber(numberParsed) && valueChanged) {
          params.data.amountLimit = BigInt(newValue).toString();
          valueChanged = true;
        } else {
          toast('Input allow positive number only!', {
            position: 'top-right',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            type: 'error',
          });
          params.data.amountLimit = params.oldValue;
          valueChanged = false;
        }

        return valueChanged;
      },
    },
    {
      sortable: false,
      field: 'createDate',
      headerName: `${t('limit.date_of_registration')}`,
      valueFormatter: (p) => {
        return p.value ? formatDate(p.value) : '';
      },
    },
    {
      sortable: false,
      field: 'updateDate',
      headerName: `${t('limit.modification_date')}`,
      valueFormatter: (p) => {
        return p.value ? formatDate(p.value) : '';
      },
    },
  ];
};

export const checkDataLimit = (
  data: HistoryBettingContent[],
  param?: HistoryBettingContent,
): HistoryBettingContent[] => {
  const newData = data.filter((e) => !isEqual(e, param));
  if (data.length === newData.length && param) newData.push(param);
  return newData;
};

export const limitFileSize = 10485760;
