import {
  IContentResponseApi,
  IResponseApi,
  IStatisticAgentByVendorChartDataResponse,
  IStatisticAgentPointChildrenParam,
  IStatisticAgentPointListByParentResponse,
  IStatisticAgentPointListByVendorResponse,
  IStatisticAgentPointParam,
  IStatisticAgentPointResponse,
  IStatisticCashResponse,
  IStatisticDailyAgentListListResponse,
  IStatisticDailyGameProviderListResponse,
  IStatisticDailyParam,
  IStatisticDailyResponse,
  IStatisticVendorParamColumn,
  StatisticsEnum,
  TODO,
} from '@/interfaces';
import { buildCashByDateFormater } from '@/utils/number';
import { useMutation, useQuery } from '@tanstack/react-query';
import requestApi from '../config';
import { pointStatisticsByVendor } from '@/helpers';

const URL_STATISTIC_AGENT_POINT = 'trans-service/reports/agent-point';
const URL_STATISTIC_LIST_BY_PARENT = 'auth-service/agents/list/by-parent';
const URL_STATISTIC_DAILY = 'trans-service/reports/daily-point';
const URL_STATISTIC_DAILY_GAME_PROVIDER_LIST = 'adapter-service/game-provider/list';
const URL_STATISTIC_DAILY_AGENT_LIST = 'trans-service/agents/agent-list';

const URL_STATISTIC_CASH = 'trans-service/v2/reports/cash';
const URL_LIST_GAME_TYPE = '/trans-service/reports/list-game-type';
const URL_POINT_STATISTIC_BY_VENDOR = '/trans-service/reports/vendor-report';
const URL_POINT_STATISTIC_BY_VENDOR_CHART = '/trans-service/reports/vendor-statistic';
const URL_STATISTIC_LIST_VENDOR = 'adapter-service/game-provider/list';
const URL_MEMO = {
  GET: (memo_id: string) => `trans-service/memo/${memo_id}`,
  CREATE: '/trans-service/memo',
  UPDATE: '/trans-service/memo',
  DELETE: (memo_id: string) => `/trans-service/memo/${memo_id}`,
}

export const useStatisticAgentPoint = () => {
  return useMutation(async (param: IStatisticAgentPointParam) => {
    const data = await requestApi.post<IContentResponseApi<IStatisticAgentPointResponse>>(
      URL_STATISTIC_AGENT_POINT,
      param,
    );
    return data;
  });
};

export const usePointStatisticByVendorMutation = () => {
  return useMutation<IContentResponseApi, IResponseApi, object>(
    [StatisticsEnum.STATISTIC_POINT_BY_VENDOR],
    async (param) => {
      const { data } = await requestApi.post<IStatisticAgentPointListByVendorResponse>(
        URL_POINT_STATISTIC_BY_VENDOR,
        { ...param },
      );
      const dataConverted = pointStatisticsByVendor(data.content)
      return { content: dataConverted, totalElements: data.totalElements };
    },
  );
};

export const useStatisticAgentPointListByParent = () => {
  return useQuery([StatisticsEnum.STATISTIC_LIST_BY_PARENT], async () => {
    const { data } = await requestApi.get<Array<IStatisticAgentPointListByParentResponse>>(
      URL_STATISTIC_LIST_BY_PARENT,
    );
    return data;
  });
};

export const useStatisticAgentPointListVendor = () => {
  return useQuery([StatisticsEnum.URL_STATISTIC_LIST_VENDOR], async () => {
    const { data } = await requestApi.get<Array<{ id: string; providerName: string }>>(URL_STATISTIC_LIST_VENDOR);
    return data;
  });
};

export const useStatisticAgentPointChildren = () => {
  return useMutation<Array<IStatisticAgentPointListByParentResponse>, IResponseApi, IStatisticAgentPointChildrenParam>(
    [StatisticsEnum.STATISTIC_LIST_AGENT_POINT_CHILDREN],
    async (param) => {
      const { data } = await requestApi.get<Array<IStatisticAgentPointListByParentResponse>>(
        URL_STATISTIC_LIST_BY_PARENT,
        param,
      );
      return data;
    },
  );
};

export const useStatisticPointByVendorChart = (param?: IStatisticVendorParamColumn) => {
  return useQuery([StatisticsEnum.STATISTIC_POINT_BY_VENDOR_CHERT_COMPANY, param], async () => {
    const { data } = await requestApi.post<{ statistic: IStatisticAgentByVendorChartDataResponse }>(
      URL_POINT_STATISTIC_BY_VENDOR_CHART,
      param,
    );
    return data.statistic;
  });
};

export const useStatisticDaily = () => {
  return useMutation(async (param: IStatisticDailyParam) => {
    const data = await requestApi.post<IContentResponseApi<IStatisticDailyResponse>>(URL_STATISTIC_DAILY, param);
    return data;
  });
};

export const useStatisticDailyGameProviderList = () => {
  return useQuery([StatisticsEnum.URL_STATISTIC_DAILY_GAME_PROVIDER_LIST], async () => {
    const { data } = await requestApi.get<Array<IStatisticDailyGameProviderListResponse>>(
      URL_STATISTIC_DAILY_GAME_PROVIDER_LIST,
    );
    return data;
  });
};

export const useStatisticDailyAgentListList = () => {
  return useQuery([StatisticsEnum.URL_STATISTIC_DAILY_AGENT_LIST], async () => {
    const { data } = await requestApi.get<Array<IStatisticDailyAgentListListResponse>>(URL_STATISTIC_DAILY_AGENT_LIST);
    return data;
  });
};

export const useStatisticCash = () => {
  return useMutation<IContentResponseApi, IResponseApi, object>(
    [StatisticsEnum.STATISTIC_AGENT_POINT],
    async (param: TODO) => {
      const { data } = await requestApi.post<IStatisticCashResponse[]>(URL_STATISTIC_CASH, param);
      if (!data) return { content: [], totalElements: 0 };
      const { tran_type } = param;

      const cashFormater = buildCashByDateFormater(tran_type);

      const dataCash = data.map((e: IStatisticCashResponse) => {
        const formatedCashByDate = cashFormater(e);

        const childrenMap: Record<string, TODO[]> = {};
        let rootChild: TODO = null;

        if (e.child) {
          const children = e.child.map((rawChild) => {
            const child = cashFormater(rawChild);
            const { parent_id } = child;

            if (child.agent_id == e.ma_id) {
              rootChild = child;
            }

            if (childrenMap[parent_id]) {
              childrenMap[parent_id].push(child);
            } else {
              childrenMap[parent_id] = [child];
            }

            return child;
          });

          for (const child of children) {
            const { agent_id } = child;

            if (childrenMap[agent_id]?.length) {
              child.children = [...childrenMap[agent_id]];
            }
          }
        }
        
        return {
          ...formatedCashByDate,
          children: rootChild ? [rootChild] : undefined,
          masterAgent: 1,
        };
      });

      return { content: dataCash, totalElements: dataCash.length };
    },
  );
};

export const useListGameTypeQuery = () => {
  return useQuery([StatisticsEnum.STATISTIC_LIST_GAME_TYPE], async () => {
    const res = await requestApi.get<string[]>(URL_LIST_GAME_TYPE);
    return res.data;
  });
};

export const useGetMemo = () => {
  return useMutation([StatisticsEnum.GET_MEMO], async (memo_id: string) => {
    const res = await requestApi.get<TODO>(URL_MEMO.GET(memo_id));
    return res.data;
  });
};

export const useModifyMemo = () => {
  return useMutation([StatisticsEnum.GET_MEMO], async (param: TODO) => {
    const res = await requestApi.patch<TODO>(URL_MEMO.UPDATE, param);
    return res;
  });
};

export const useCreateMemo = () => {
  return useMutation([StatisticsEnum.GET_MEMO], async (param: TODO) => {
    const res = await requestApi.post<TODO>(URL_MEMO.CREATE, param);
    return res;
  });
};

export const useDeleteMemo = () => {
  return useMutation([StatisticsEnum.GET_MEMO], async (memo_id: string) => {
    const res = await requestApi.delete<TODO>(URL_MEMO.DELETE(memo_id));
    return res;
  });
};

