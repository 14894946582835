import { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

interface ModalComponentsProps {
  show: boolean;
  onHide: () => void;
  onClickOk?: () => void;
  title?: string;
  haveFooterButton?: boolean;
  callback?: VoidFunction;
  formId?: string;
}

const ModalComponents: FC<ModalComponentsProps> = (props) => {
  return (
    <Modal {...props} size="lg" centered scrollable>
      {props.title && <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{props.title}</Modal.Title>
      </Modal.Header>}
      <Modal.Body>{props.children}</Modal.Body>
      {props.haveFooterButton ? (
        <Modal.Footer>
          <Button size="sm" variant="secondary" onClick={props.onHide}>
            Close
          </Button>
          <Button
            form={props.formId ?? 'hook-form'}
            size="sm"
            variant="primary"
            type="submit"
            onClick={props.onClickOk}>
            Ok
          </Button>
        </Modal.Footer>
      ) : (
        ''
      )}
    </Modal>
  );
};

export default ModalComponents;
