import { useAgentPermissionList, useUpdateAgentPermission } from '@/apis/service/agent/agent-permission';
import { useAppSelector } from '@/hooks/hooks';
import { IDataPermission, IParamsPermission } from '@/interfaces';
import { showConfirm } from '@/utils/alert';
import { Switch } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const SwitchPermission = (params: ICellRendererParams) => {
  const { t } = useTranslation();
  const searchPermission = useAppSelector((state) => state.agent.searchPermission);
  const { mutateAsync } = useUpdateAgentPermission();
  const { mutate: mutateAgentPermissionList } = useAgentPermissionList();
  const [key] = useState(params.column?.getColId() || '');
  const { permissionsById } = useAppSelector((state) => state.agent);

  const data: IDataPermission = useMemo(() => {
    let value = {};
    try {
      return JSON.parse(params.value);
    } catch (error) {}
    return value;
  }, [params.value]);

  const state = permissionsById[data?.agentPermissionId]?.state;
  const checked = state !== undefined ? !!Number(state) : !!Number(data.state);

  const onChangeSwitch = async (value: boolean) => {
    const massage = value ? t('permission_setting.title_confirm_on') : t('permission_setting.title_confirm_off');
    const isConfirm = await showConfirm(massage);
    if (isConfirm) {
      const param: IParamsPermission = {
        agentPermissionId: data.agentPermissionId,
        state: value ? '1' : '0',
      };

      const res = await mutateAsync(param);
      if (res) {
        const page = params.api.paginationGetCurrentPage();
        const size = params.api.paginationGetPageSize();
        mutateAgentPermissionList({
          page_no: page,
          page_size: size,
          agent_name: searchPermission,
        });
      }
    }
  };

  return (
    <>
      {params.data[key] && data && (
        <Switch
          key={data.agentPermissionId}
          defaultChecked={checked}
          checked={checked}
          onChange={(event) => onChangeSwitch(event.target.checked)}
        />
      )}
    </>
  );
};

export default SwitchPermission;
