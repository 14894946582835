import { colorsText } from '@/helpers';
import { formatNumber } from '@/utils/helpers';
import { ICellRendererParams } from 'ag-grid-community';

const StatisticDailyEdit = (params: ICellRendererParams) => {
  return (
    <>
      <p style={{ color: colorsText(params.colDef?.colId) }} className="statisticVendorEdit-center">
        {formatNumber(Number(params.value) || 0)}{' '}
        {params.colDef?.field && ['agentPointRate', 'maPointRate', 'rateDiff'].includes(params.colDef.field)
          ? '%'
          : null}
      </p>
    </>
  );
};
export default StatisticDailyEdit;
