import { IPageable } from '@/interfaces/index';

export interface IUserListRow {
  id: number;
  agent_id: string;
  user_id: string;
  user_name: string;
  point_balance: number;
  created_date: string;
  last_access: null | string;
}

export interface ITransactionHistoryRow {
  agentId: string;
  userId: string;
  userName: string;
  type: string;
  processAmount: number;
  currentAmount: number;
  previousAmount: number;
  processDate: string;
}

export interface IResponseUserList<T> {
  content: T[];
  pageable: IPageable;
  totalPages: number;
  totalElements: number;
  numberOfElements: number;
  last: boolean;
  size: number;
  number: number;
}

export interface IParamsFilterUserList {
  page_no: number;
  page_size: number;
  user_id: string;
  user_name: string;
  agent_id: string;
  included_child: boolean;
  sort: string;
  from_date: string;
  to_date: string;
}

export interface IParamsTransactionHistoryList {
  agentId: string;
  isIncludeSubAgent: string;
  startDate: string;
  endDate: string;
  userId: string;
  nickName: string;
  type: string;
  pageSize: number;
  page_no: number;
  sort: string;
}

export enum TYPE_TRANSACTION_HISTORY {
  ALL = 'ALL',
  PAYMENT = 'DEBIT',
  DEDUCTION = 'CREDIT',
  TOPUP = 'ADD',
  REFUND = 'SUB',
}
