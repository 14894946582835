import { Option } from 'react-bootstrap-typeahead/types/types';
export enum NotificationEnum {
  NOTI_UNCHECK = 'NOTI_UNCHECK',
}
export interface IDataCreateGroup {
  group_name: string;
  usernames: string[];
}

export interface IDataCreateNotifications {
  title: string;
  content: string;
  attachment_url: string;
  type: string;
  time_send: string;
  usernames: string[];
  groups: (number | undefined | string)[];
  sentNow: boolean;
}

export type IDataReadNotifications = {
  list_notification_id: string[];
};

export type INotificationUncheck = {
  count: number;
};

export interface NotificationList {
  content: INotificationItem[];
  pageable: {
    sort: {
      sorted: boolean;
      unsorted: boolean;
      empty: boolean;
    };
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
  };
  totalElements: number;
  totalPages: number;
  last: boolean;
  size: number;
  number: number;
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}
export interface INotificationItem {
  id: string;
  title: string;
  content: string;
  attachment_url: string;
  type: string;
  time_send: string;
  created_date: string;
  read_date: string;
  sender: string;
  status: 'RECEIVED' | 'CHECKED' | 'SEEN';
}

export interface IParamsFilterGroupList {
  from_date: string;
  to_date: string;
  page_no: number;
  page_size: number;
  sort: string;
}

export interface IGroup {
  agent_count: number;
  group_name: string;
  created_date: string;
  group_id?: string;
  agent_id: string;
  agent_name: string;
  id: string;
}

export interface IGroupListResponse {
  agent_count: number;
  group_name: string;
  created_date: string;
}

export interface IParamsFilterGetAgent {
  keyword: string;
}

export interface IUserSelectOption {
  agent_id: string;
  agent_name: string;
  group_name: string;
  group_id: string;
}

export interface ICreateNotificationsData {
  title: string;
  content: string;
  attachment_url: string;
  type: string;
  time_send: string;
  usernames: string[];
  groups: number[];
  file_name: string;
}

export type IDataCreateNotificationsForm = Omit<ICreateNotificationsData, 'groups' | 'usernames' | 'type'> & {
  type: Option[] | string;
};

export interface OptionType {
  label: string;
  value: string;
}
