import requestApi from '@/apis/config';
import {
  FIREBASE_TOKEN,
  IDataCreateNotifications,
  IDataReadNotifications,
  INotificationUncheck,
  IParamsFilterGetAgent,
  IResponseApi,
  IUserSelectOption,
  NotificationEnum,
  NotificationList,
  PaginationParams,
  TODO,
} from '@/interfaces';
import { QUERY_KEYS } from '@/interfaces/enum';
import { useMutation, useQuery } from '@tanstack/react-query';
import store from '@/store';
import { getStorageItem } from '@/utils/storage';
import { setFirebaseToken, setRegisterDevice } from '@/pages/auth/store/AuthSlice';

const URL_CREATE = '/noti-service/notification/create';
const URL_SEEN = '/noti-service/notification/seen';
const URL_SELECT_USER = '/auth-service/agents/list';
const URL_NOTIFICATION_PAGING = '/noti-service/notification/list';
const URL_NOTIFICATION_UNCHECK = '/noti-service/notification/count-non-check';
const URL_CHECK_ALL = '/noti-service/notification/check-all';
const URL_REGISTER_DEVICE = '/noti-service/device/save';

export const useNotificationUncheck = () => {
  return useQuery([NotificationEnum.NOTI_UNCHECK], async () => {
    return await new Promise<IResponseApi<INotificationUncheck>>((resolve) => {
      setTimeout(async () => {
        const data = await requestApi.get<INotificationUncheck>(URL_NOTIFICATION_UNCHECK);
        return resolve(data);
      }, 2000);
    });
  });
};
export const useListNotification = () => {
  return useMutation(async (param: PaginationParams) => {
    const data = await requestApi.get<NotificationList>(URL_NOTIFICATION_PAGING, param);
    return data;
  });
};

export const useCheckAllNotificationMutation = () => {
  return useMutation(async () => {
    const res = await requestApi.get(URL_CHECK_ALL);
    return res;
  });
};

export const useCreateNotificationsMutation = () => {
  return useMutation(async (data: IDataCreateNotifications) => {
    const res = await requestApi.post(URL_CREATE, data);
    return res;
  });
};

export const useReadNotificationsMutation = () => {
  return useMutation(
    async (data: IDataReadNotifications) => {
      return await requestApi.put<never>(URL_SEEN, data);
    },
    {
      // onSuccess: (res) => {
      //   toast.success(t(res.message));
      // },
    },
  );
};

// export const useSeenNotificationsMutation = () => {
//   return useMutation(async (data: IDataReadNotifications) => {
//     return await requestApi.post<INotificationItem[]>(URL_SEEN, data);
//   });
// };

export const useGetAgentQuery = (paramsFilter: IParamsFilterGetAgent) => {
  return useQuery([QUERY_KEYS.GET_AGENT, [paramsFilter]], async () => {
    const res = await requestApi.get<IUserSelectOption[]>(URL_SELECT_USER, paramsFilter);
    return res.data;
  });
};

export const useRegisterDevice = () => {
  return useMutation(
    async () => {
      const globalState = store.getState();
      const isRegisterDevice = globalState.auth.isRegisterDevice;

      if (!isRegisterDevice) {
        const agentId = globalState.profileInfo.user?.agent_id;
        const firebaseToken = globalState.auth.firebaseToken;
      
        if (agentId && firebaseToken) {
          store.dispatch(setRegisterDevice(true));

          const payload = {
            username: agentId,
            fcm_token: firebaseToken,
          }
        
          return await requestApi.post<TODO>(URL_REGISTER_DEVICE, payload);
        }
      }

    },
  );
};

export const useCheckFirebaseToken = async () => {
  const { mutateAsync } = useRegisterDevice();

  return useQuery([QUERY_KEYS.CHECK_FIREBASE], async () => {
    const globalState = store.getState(); 
    const firebaseToken = globalState.auth.firebaseToken;
  
    /**
     * When user access page again without login
     * Register device follow token from storage
     */
    if (!firebaseToken) {
      const firebaseTokenFromStorage = getStorageItem(FIREBASE_TOKEN);
  
      if (firebaseTokenFromStorage) {
        store.dispatch(setFirebaseToken(firebaseTokenFromStorage));
        return await mutateAsync();
      }
    }

    return false;
  });

}