import { useRef, useState } from 'react';

import { NavLink } from 'react-router-dom';

import { Avatar, Box, Button, Divider, Hidden, List, ListItem, ListItemText, Popover } from '@mui/material';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import { useTranslation } from 'react-i18next';
import { useLogout } from '@/apis/service';
import { MenuUserBox, UserBoxButton, UserBoxDescription, UserBoxLabel, UserBoxText } from './style';
import { TODO } from '@/interfaces';
import { useAppSelector } from '@/hooks/hooks';

function HeaderUserbox() {
  const { t } = useTranslation();
  const profile = useAppSelector((state) => state.profileInfo);
  const { mutate } = useLogout();
  const user = {
    name: profile.user?.agent_name,
    jobtitle: profile.user?.agent_id,
  };

  const ref = useRef<TODO>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={user.name} />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">{user.jobtitle}</UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={user.name} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">{user.jobtitle}</UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem button to="/setting" component={NavLink}>
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary={t('header.account_setting')} />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={() => mutate({})}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            {t('header.logout')}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
