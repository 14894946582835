import requestApi from '../../config';
import { useMutation } from '@tanstack/react-query';
import {
  LimitEnum,
  ISettingForAll,
  ISettingGame,
  ISettingVendor,
  IResponseApi,
  IListVendorGame,
  IParamFilterLimitBet,
  DataHistoryBetting,
  HistoryBettingContent,
} from '@/interfaces';
import { useQuery } from '@tanstack/react-query';
import { showError, showSuccess } from '@/utils/alert';
import { useTranslation } from 'react-i18next';
import { MUTATIONS_KEY } from '@/interfaces/enum';
const URL_LIST_VENDOR = '/adapter-service/limitation/vendor-game';
const URL_LIST_VENDOR_SPECIFIC = '/adapter-service/limitation/vendor-game-agent';
const URL_SET_LIMIT = '/adapter-service/limitation/game-setting-bet';
const URL_DELETE_LIMIT = '/adapter-service/limitation/delete-game-bet';
const URL_EDIT_LIMIT = '/adapter-service/limitation/update-game-bet';
const URL_SEARCH_BET = '/adapter-service/limitation/history-setting-bet';
const URL_DOWLOAD_TEAMPLATE = "/adapter-service/limitation/game-list/download/template.xlsx"

export const useSettingByGame = () => {
  const { t } = useTranslation();
  return useMutation<IResponseApi<never>, IResponseApi<never>, ISettingGame, number | string>(
    async (value) => {
      const data = await requestApi.post<never>(URL_SET_LIMIT, value);
      return data;
    },
    {
      onSuccess: async (value) => {
        await showSuccess(t(value.message));
      },
      onError: (error: { message: string }) => {
        showError(t(error.message));
      },
    },
  );
};

export const useSettingByVendor = () => {
  const { t } = useTranslation();
  return useMutation<IResponseApi<never>, IResponseApi<never>, ISettingVendor, number | string>(
    async (value) => {
      const data = await requestApi.post<never>(URL_SET_LIMIT, value);
      return data;
    },
    {
      onSuccess: async (value) => {
        await showSuccess(t(value.message));
      },
      onError: (error: { message: string }) => {
        showError(t(error.message));
      },
    },
  );
};

export const useSettingForAll = () => {
  const { t } = useTranslation();
  return useMutation<IResponseApi<never>, IResponseApi<never>, ISettingForAll, number | string>(
    async (value) => {
      const data = await requestApi.post<never>(URL_SET_LIMIT, value);
      return data;
    },
    {
      onSuccess: async (value) => {
        await showSuccess(t(value.message));
      },
      onError: (error: { message: string }) => {
        showError(t(error.message));
      },
    },
  );
};

export const useListDataTable = () => {
  return useMutation(async (param: IParamFilterLimitBet) => {
    // const response = await fetch('https://www.ag-grid.com/example-assets/olympic-winners.json');
    // const movies = await response.json();
    // const totalElements = movies.length;
    // const content = chunk(movies, param.page_size)[param.page_number] || [];
    // const url = '/auth-service/agents/list';
    const data = await requestApi.post<DataHistoryBetting>(URL_SEARCH_BET, param);

    return data;
  });
};
export const useLimitConfirmDelete = () => {
  const { t } = useTranslation();
  return useMutation(
    async ({ list, agent_id }: { list: HistoryBettingContent[]; agent_id: string }) => {
      const listDTO = list.map((x) => x.id);
      const param = {
        list: listDTO,
        agent_id,
      };
      const data = await requestApi.delete<null>(URL_DELETE_LIMIT, param);
      return data;
    },
    {
      onSuccess: async (val) => {
        await showSuccess(t(val.message)); // lam tiep cai nay
      },
      onError: async (error: { message: string }) => {
        await showError(t(error.message));
      },
    },
  );
};

export const useLimitConfirmEdit = () => {
  const { t } = useTranslation();
  return useMutation(
    async ({ list, agent_id }: { list: HistoryBettingContent[]; agent_id: string }) => {
      const listDTO = list.map((x) => ({
        id: x.id,
        game_max_bet: x.amountLimit,
      }));
      // const listDTO = list.map((x) => x.id);
      const param = {
        list: listDTO,
        agent_id,
      };
      const data = await requestApi.put<null>(URL_EDIT_LIMIT, param);
      return data;
    },
    {
      onSuccess: async (val) => {
        await showSuccess(t(val.message));
      },
      onError: async (error: { message: string }) => {
        await showError(t(error.message));
      },
    },
  );
};

export const useListVendorGame = (agentId?: string) => {
  return useQuery<IListVendorGame[]>({
    queryKey: [LimitEnum.LIST_DATA_VENDOR_FORM, agentId],
    queryFn: async () => {
      if (!agentId) {
        const { data } = await requestApi.get<IListVendorGame[]>(URL_LIST_VENDOR);
        return data;
      } else {
        const { data } = await requestApi.get<IListVendorGame[]>(URL_LIST_VENDOR_SPECIFIC, { agentId });
        return data;
      }
    },
  });
};
// export const useListGame = () => {
//   return useMutation<IListGame[], unknown, IListGame[] | undefined>((value: any) => {
//     // const url = 'list--game';
//     // const { data } = await requestApi.get<Array<IListVendorGame>>(url, { value });
//     return value;
//   });
// };

const URL_UPLOAD_FILE = '/adapter-service/limitation/game-list/upload';
export const useUploadFile = () => {
  return useMutation([MUTATIONS_KEY.UPLOAD_FILE], async ({ file }: { file: FormData }) => {
    return await requestApi.uploadFile(URL_UPLOAD_FILE, file);
  });
};

const URL_DOWNLOAD_FILE = '/adapter-service/limitation/game-list/download/';
export const useDowloadFile = () => {
  return useMutation(async (param: string) => {
    const data = await requestApi.downloadFile(URL_DOWNLOAD_FILE + param);
    return data;
  });
};

export const useDowloadTeamplate = () => {
  return useMutation(async () => {
    const data = await requestApi.downloadFile(URL_DOWLOAD_TEAMPLATE);
    return data;
  });
};