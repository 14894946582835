import { FC, ReactNode } from 'react';
import { alpha, Box, lighten, styled, useTheme } from '@mui/material';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';

import Sidebar from './Sidebar';
import Header from './Header';
import SuspenseLoader from '@/components/SuspenseLoader';
import { useDetailsCurrent } from '@/apis/service';
import { queryFilters } from '@/utils/queryClient';
import { RouteGuard } from '@/router/RouteGuard';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const LayoutWrapper = styled(Box)(({ theme }) => ({
  '&': {
    flex: 1,
    height: '100%',
    minWidth:350,
    overflow:"scroll",
    '.MuiPageTitle-wrapper': {
      background: theme.palette.mode === 'dark' ? theme.colors.alpha.trueWhite[5] : theme.colors.alpha.white[50],
      marginBottom: `${theme.spacing(4)}`,
      boxShadow:
        theme.palette.mode === 'dark'
          ? `0 1px 0 ${alpha(
              lighten(theme.colors.primary.main, 0.7),
              0.15,
            )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
          : `0px 2px 4px -3px ${alpha(theme.colors.alpha.black[100], 0.1)}, 0px 5px 12px -4px ${alpha(
              theme.colors.alpha.black[100],
              0.05,
            )}`,
    },
  },
}));
const ContentWrapper = styled(Box)(({ theme }) => ({
  '&': {
    position: 'relative',
    zIndex: 5,
    display: 'block',
    flex: 1,
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.header.height,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.header.heightMd,
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: `${theme.sidebar.width}`,
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.header.heightXs,
    },
  },
}));

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  useDetailsCurrent();
  const theme = useTheme();
  const isFetching = useIsFetching(queryFilters);
  const isMutating = useIsMutating(queryFilters);
  return (
    <>
      <LayoutWrapper id="back-to-top-anchor">
        <Header />
        <Sidebar />
        <ContentWrapper>
          <Box display="block" sx={{ padding: '20px' }}>
            <RouteGuard />
          </Box>
        </ContentWrapper>
      </LayoutWrapper>
      {isFetching || isMutating ? <SuspenseLoader /> : null}
    </>
  );
};

export default SidebarLayout;
