import { TODO } from '@/interfaces';
import sessionstorage from '@rooks/use-sessionstorage';

export function useSessionstorage(key: string, defaultValue?: TODO) {
  const [value, setValue, removeValue] = sessionstorage(key, defaultValue);
  return { value, setValue, removeValue };
}

export function setStorageItem(key: string, value: string) {
  window.localStorage.setItem(key, value);
}

export function getStorageItem(key: string) {
  return window.localStorage.getItem(key);
}

export function removeStorageItem(key: string) {
  // Removes the key/value pair with the given key
  return window.localStorage.removeItem(key);
}

export function clearStorageItems() {
  // Remove all local storage items
  return window.localStorage.clear();
}

export function setStorageItemBySession(key: string, value: string) {
  window.sessionStorage.setItem(key, value);
}

export function getStorageItemBySession(key: string) {
  return window.sessionStorage.getItem(key);
}

export function clearStorageItemsBySession() {
  // Remove all local storage items
  return window.sessionStorage.clear();
}
