import encode from '@/utils/encode';
import { LANGUAGE } from '@/interfaces/enum';
import { IStatisticAgentPointListByVendorContentItem } from './statistics';
export const REFRESH_TOKEN = encode('refreshToken', 4);
export const ACCESS_TOKEN = encode('accessToken', 4);
export const FIREBASE_TOKEN = encode('firebaseToken', 4);
export const EXPIRED_AT = 'expired_at';

export enum QUERY_KEYS {
  DASHBOARD = 'dashboard',
  ROLE_LIST = 'role_list',
  GROUP_LIST = 'group_list',
}

export enum FORMAT_MODELS {
  DATE = 'YYYY-MM-DD HH:mm:ss',
  FULL_DATE = 'YYYY-MM-DD',
}

export const DEFAULT_PAGINATION = {
  page_no: 1,
  page_size: 10,
  agent_name: '',
  sort: 'createdDate.asc',
};

export const backgroundImg = [
  'linear-gradient(180deg, #ff9634, #e76f00)',
  'linear-gradient(180deg,#ffe133,#e6c300)',
  'linear-gradient(180deg,#05f353,#03a839)',
  'linear-gradient(180deg,#01fdfd,#01b1b1)',
  'linear-gradient(180deg,#d497fe,#b54cfd)',
  'linear-gradient(180deg,#9c9c9c,#757575)',
  ' linear-gradient(180deg,#4b92f2,#1168e0)',
  'linear-gradient(180deg,#ff339a,#e60074)',
];

export const validateMessage = {
  [LANGUAGE.KR]: {
    required: '아직 입력되지 않음',
  },
  [LANGUAGE.EN]: {
    required: 'is required',
  },
};

export const FORMAT_DATE = 'YYYY-MM-DD HH:mm:ss';

export const DEBOUNCE_TIME = 400;

export const PAGE_NUMBER = 10;

export const MAX_LENGTH_INPUT = 50;

export const OPTIONS_NOTIFICATIONS = [
  { label: 'notifications.announcements', value: 'NOTICE' },
  { label: 'notifications.check', value: 'CHECK' },
  { label: 'notifications.point', value: 'POINT' },
  { label: 'notifications.system_guiding', value: 'GUIDE' },
];

export const IpFilterType = {
  OPEN: 'OPEN',
  WHITELIST: 'WHITELIST',
};

export const SUPER_ADMIN = {
  agent_id: 'admin',
  agent_name: 'super_admin',
};

export const PAYMENT_DEDUCTION_OPTION = {
  PROCESS_AS_BUY_POINT: 'PROCESS_AS_BUY_POINT',
  RETURN_PAYMENT: 'RETURN_PAYMENT',
  NO: 'NO',
};

export const CASH_STATISTICS_TYPE_OPTION = {
  WITHDRAW: '2',
  BUY_POINT: '3',
  RATE_ALLOCATION: '4',
  ALL: '5',
};

export const OPERATION_MODE = {
  SEAMLESS: '1',
  TRANSFER: '0',
};

export const FIRST_ELEMENT = 0;

export const RANGE_DAY_LIMIT = 90;

export const EMPTY_DATA_VENDOR: IStatisticAgentPointListByVendorContentItem = {
  bettingAmountVendor: null,
  cancelAmountVendor: null,
  profitVendor: null,
  vendor: null,
  winAmountVendor: null,
};

export const optionAll = {
  label : "ALL",
  value : "ALL"
}