import { useMutation, useQuery } from '@tanstack/react-query';

import requestApi from '@/apis/config';
import { IResponseApi } from '@/interfaces';
import { MUTATIONS_KEY, QUERY_KEYS } from '@/interfaces/enum';
import { useAppDispatch } from '@/hooks/hooks';
import { setSidebar } from '@/pages/auth/store/AuthSlice';

const URL_MENU = '/auth-service/menu';
const useSidebarQuery = () => {
  const dispatch = useAppDispatch();
  return useQuery<string[], IResponseApi>(
    [QUERY_KEYS.SIDEBAR],
    async () => {
      const { data } = await requestApi.get<string[]>(URL_MENU);
      return data;
    },
    {
      onSuccess: (data) => {
        if (data) {
          dispatch(setSidebar(data));
        }
      },
    },
  );
};

const URL_UPLOAD_FILE = '/media-service/api/v1/1/files';
const useUploadFile = () => {
  return useMutation([MUTATIONS_KEY.UPLOAD_FILE], async ({ file }: { file: FormData }) => {
    return await requestApi.uploadFile(URL_UPLOAD_FILE, file);
  });
};

export { useSidebarQuery, useUploadFile };
