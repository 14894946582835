import { LogoSigns, LogoWrapper, TooltipWrapper } from './style';

function Logo() {
  return (
    <TooltipWrapper title="APIM" arrow>
      <LogoWrapper to="/">
        <LogoSigns>
          <img width='100%' height='100%' src='/static/images/mix-logo.svg' alt='mix-logo' />
        </LogoSigns>
      </LogoWrapper>
    </TooltipWrapper>
  );
}

export default Logo;
