import { FORMAT_MODELS, IContentResponseApi, IResponseApi, IStatisticsCashResponse, TODO } from '@/interfaces';
import { COLORS_STATISTIC } from '@/global-styles';
import PointByVendorEdit from '@/pages/Statistics/components/StatisticPointByVendor/PointByVendorEdit';
import PointByVendorRedes from '@/pages/Statistics/components/StatisticPointByVendor/PointByVendorRedes';
import {
  ColDef,
  ColGroupDef,
  ICellRendererParams,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
} from 'ag-grid-community';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import * as Yup from 'yup';
import StatisticDailyEdit from '@/pages/Statistics/components/StatisticDaily/StatisticDailyEdit';
import StatisticAgentPointEdit from '@/pages/Statistics/components/StatisticAgentPoint/StatisticAgentPointEdit';
export const getServerSideDatasource = (
  mutateAsync: UseMutateAsyncFunction<IContentResponseApi, IResponseApi, object>,
  validator?: Yup.SchemaOf<object>,
  param?: object,
) => {
  return {
    getRows: async (paramRows: IServerSideGetRowsParams) => {
      const page = paramRows.api.paginationGetCurrentPage();
      const size = paramRows.api.paginationGetPageSize();
      try {
        const {request}  = paramRows
        let sort= ''
        const sortModel = request.sortModel;
        if (sortModel.length > 0) {
          const [sortItem] = sortModel;
          sort = `${sortItem.colId}.${sortItem.sort}`;
        }
        const params = Object.assign(
          {
            page_size: size,
            page_no: page,
            sort
          },
          param || {},
        );
        if (!validator) throw new Error();
        const schema = await validator.isValid(params);
        if (!schema) throw new Error();
        const response = await mutateAsync(params);
        if (response.totalElements === 0) paramRows.api.showNoRowsOverlay();
        else paramRows.api.hideOverlay();
        if (response) {
          paramRows.success({
            rowData: response.content,
            rowCount: response.totalElements,
          });
        } else {
          paramRows.fail();
        }
      } catch (error) {
        paramRows.api.showNoRowsOverlay();
        paramRows.success({
          rowData: [],
          rowCount: 0,
        });
      }
    },
  };
};

export const useStatisticAgentPointColumnDefsText = (): (ColDef | ColGroupDef)[] => {
  return [
    {
      headerName: 'Athlete Details',
      children: [
        {
          headerName: 'Athlete',
          field: 'athlete',
        },
        {
          headerName: 'Age',
          field: 'age',
        },
        {
          headerName: 'Country',
          field: 'country',
        },
      ],
    },
    {
      headerName: 'Sports Results',
      children: [
        {
          field: 'sport',
          headerName: 'Sport',
        },
        {
          field: 'total',
          headerName: 'Total',
        },
        {
          field: 'gold',
          headerName: 'Gold',
        },
        {
          field: 'silver',
          headerName: 'Silver',
        },
        {
          field: 'bronze',
          headerName: 'Bronze',
        },
      ],
    },
    {
      headerName: 'Date',
      children: [
        {
          field: 'date',
          headerName: 'Date',
        },
        {
          field: 'year',
          headerName: 'Year',
        },
      ],
    },
  ];
};

export const useStatisticPointByVendorColumnDefs = (param: string[]) => {
  const { t } = useTranslation(); //t('statistics.point_by_vendor.popup_vendor')
  return [
    {
      width: 200,
      field: 'vendor',
      headerName: t('statistics.point_by_vendor.vendor'),
      cellRenderer: (p: ICellRendererParams) =>
        PointByVendorRedes({ ...p, game_type: param }, t('statistics.point_by_vendor.popup_vendor')),
      colId: 'company',
      headerClass: 'statistics-column',
      sortable : false
    },

    {
      field: 'profitVendor',
      headerName: t('statistics.agent_point.profit'),
      colId: 'orange',
      headerClass: 'statistics-column',
      sortable : false
    },
    {
      headerName: t('statistics.agent_point.betting'),
      headerClass: 'statistics-text',
      children: [
        {
          field: 'bettingAmountVendor',
          headerName: t('statistics.agent_point.bet_money'),
          colId: 'yellow',
          headerClass: 'statistics-column',
          sortable : false
        },

        {
          field: 'winAmountVendor',
          headerName: t('statistics.agent_point.prize_money'),
          colId: 'red',
          headerClass: 'statistics-column',
          sortable : false
        },
        {
          field: 'cancelAmountVendor',
          headerName: t('statistics.agent_point.cancellation_fee'),
          colId: 'blue',
          headerClass: 'statistics-column',
          sortable : false
        },
      ],
    },

    {
      width: 200,
      field: 'parentAgent',
      headerName: t('statistics.point_by_vendor.parent_agent'),
      cellRenderer: null,
      headerClass: 'statistics-column',
      sortable : false
    },
    {
      width: 200,
      field: 'agent',
      headerName: t('statistics.point_by_vendor.agent'),
      cellRenderer: (p: ICellRendererParams) =>
        PointByVendorRedes({ ...p, game_type: param }, t('statistics.point_by_vendor.popup_agent')),
      colId: 'agent',
      headerClass: 'statistics-column',
      sortable : false
    },
    {
      field: 'profit',
      headerName: t('statistics.agent_point.profit'),
      colId: 'orange',
      headerClass: 'statistics-column',
      sortable : false
    },

    {
      headerName: t('statistics.agent_point.betting'),
      headerClass: 'statistics-text',
      children: [
        {
          field: 'bettingAmount',
          headerName: t('statistics.agent_point.bet_money'),
          colId: 'yellow',
          headerClass: 'statistics-column',
          sortable : false
        },
        {
          field: 'winAmount',
          headerName: t('statistics.agent_point.prize_money'),
          colId: 'red',
          headerClass: 'statistics-column',
          sortable : false
        },
        {
          field: 'cancelAmount',
          headerName: t('statistics.agent_point.cancellation_fee'),
          colId: 'blue',
          headerClass: 'statistics-column',
          sortable : false
        },
      ],
    },
  ];
};

export const useStatisticPointByVendorDefaultColDef = (): ColDef => {
  return {
    width: 120,
    cellRenderer: PointByVendorEdit,
    sortable: true,
    unSortIcon: true,
    cellClass: 'text-center',
    headerClass: () => 'header-center',
    flex: 1,
  };
};

export const colorsText = (field?: string): string => {
  switch (true) {
    case field?.includes('orange'):
      return COLORS_STATISTIC.orange;
    case field?.includes('yellow'):
      return COLORS_STATISTIC.yellow;
    case field?.includes('red'):
      return COLORS_STATISTIC.red;
    case field?.includes('blue'):
      return COLORS_STATISTIC.blue;
    default:
      return COLORS_STATISTIC.default;
  }
};

export const useStatisticAgentPointColumnDefs = (): (ColDef | ColGroupDef)[] => {
  const { t } = useTranslation();
  return [
    {
      width: 200,
      field: 'parent_name',
      headerName: t('statistics.agent_point.parent_agent'),
      cellRenderer: null,
      headerClass: 'statistics-column',
      // hard code for filter
      filter: 'agTextColumnFilter',
    },
    {
      width: 200,
      field: 'agent_name',
      headerName: t('statistics.agent_point.agent'),
      cellRenderer: null,
      colId: 'agent',
      headerClass: 'statistics-column',
    },
    {
      field: 'revenue',
      headerName: t('statistics.agent_point.profit'),
      colId: 'orange',
      headerClass: 'statistics-column',
    },

    {
      headerName: t('statistics.agent_point.betting'),
      headerClass: 'statistics-text',
      children: [
        {
          field: 'bet',
          headerName: t('statistics.agent_point.bet_money'),
          colId: 'yellow',
          headerClass: 'statistics-column',
        },

        {
          field: 'win',
          headerName: t('statistics.agent_point.prize_money'),
          colId: 'red',
          headerClass: 'statistics-column',
        },

        {
          field: 'cancel',
          headerName: t('statistics.agent_point.cancellation_fee'),
          colId: 'blue',
          headerClass: 'statistics-column',
        },
      ],
    },
    {
      field: 'agent_point_balance',
      headerName: t('statistics.agent_point.holding_points'),
      headerClass: 'statistics-column',
    },
    {
      field: 'agent_point_rate',
      headerName: t('statistics.agent_point.rate'),
      colId: 'orange',
      headerClass: 'statistics-column',
    },
    {
      field: 'ma_point_rate',
      headerName: t('statistics.agent_point.ma_rate'),
      colId: 'yellow',
      headerClass: 'statistics-column',
    },
    {
      field: 'rate_diff',
      headerName: t('statistics.agent_point.rate_deference'),
      headerClass: 'statistics-column',
    },
  ];
};

export const useStatisticAgentPointDefaultColDef = (): ColDef => {
  return {
    // width: 140,
    cellRenderer: StatisticAgentPointEdit,
    sortable: true,
    unSortIcon: true,
  };
};

export const useStatisticDailyColumnDefs = (): (ColDef | ColGroupDef)[] => {
  const { t } = useTranslation();
  return [
    {
      // width: 200,
      field: 'dateReport',
      headerName: t('statistics.daily.date'),
      cellRenderer: null,
      headerClass: 'statistics-column',
      cellClass: 'statisticVendorEdit-center',
      // hard code for filter
      filter: 'agTextColumnFilter',
      cellStyle: { textAlign: 'center' },
      valueFormatter: (p) => {
        return p.value ? moment(p.value).format(FORMAT_MODELS.FULL_DATE) : '';
      },
    },
    {
      field: 'revenue',
      headerName: t('statistics.daily.revenue'),
      type: 'rightAligned',
      colId: 'orange',
      headerClass: 'statistics-column',
    },
    {
      headerName: t('statistics.daily.betting'),
      headerClass: 'statistics-text',
      children: [
        {
          field: 'betAmount',
          headerName: t('statistics.daily.bet_amount'),
          type: 'rightAligned',
          cellStyle: {color: 'yellow'},
          headerClass: 'statistics-column',
        },

        {
          field: 'winAmount',
          headerName: t('statistics.daily.amount_of_hits'),
          type: 'rightAligned',
          cellStyle: {color: 'red'},
          headerClass: 'statistics-column',
        },

        {
          field: 'cancelAmount',
          headerName: t('statistics.daily.cancellation_amount'),
          type: 'rightAligned',
          colId: 'blue',
          headerClass: 'statistics-column',
        },
      ],
    },
    {
      field: 'agentPoint',
      headerName: t('statistics.daily.Holding_points'),
      type: 'rightAligned',
      colId: 'yellow',
      headerClass: 'statistics-column',
    },
    {
      field: 'agentPointRate',
      headerName: t('statistics.daily.rating'),
      colId: 'yellow',
      type: 'rightAligned',
      headerClass: 'statistics-column',
    },
    {
      field: 'maPointRate',
      headerName: t('statistics.daily.ma_rating'),
      colId: 'yellow',
      type: 'rightAligned',
      headerClass: 'statistics-column',
    },
    {
      field: 'rateDiff',
      headerName: t('statistics.daily.rate_difference'),
      colId: 'yellow',
      type: 'rightAligned',
      headerClass: 'statistics-column',
    },
    {
      field: 'holdingPoints',
      headerName: t('statistics.daily.final_holding_points_of_day'),
      cellStyle: { textAlign: 'center' },
      colId: 'yellow',
      headerClass: 'statistics-column',
    },
  ];
};

export const useStatisticDailyDefaultColDef = (): ColDef => {
  return {
    // width: 150,
    cellRenderer: StatisticDailyEdit,
    sortable: true,
    unSortIcon: true,
  };
};

export const dateRange = (start: string, end: string, steps = 1) => {
  const startDate = new Date(start).toISOString();
  const endDate = new Date(end).toISOString();

  const dateArray = [];
  let currentDate = new Date(startDate);

  while (currentDate <= new Date(endDate)) {
    dateArray.push(moment(new Date(currentDate).toISOString()).format('yyyy-MM-DD'));
    currentDate.setUTCDate(currentDate.getUTCDate() + steps);
  }

  return dateArray;
};

export const createServer = (serverData: Array<IStatisticsCashResponse>) => {
  const server = {
    data: serverData,
    getData: function (request: IServerSideGetRowsRequest) {
      const extractRowsFromData: (groupKeys: string[], data: Array<IStatisticsCashResponse>) => TODO = (
        groupKeys: string[],
        data: Array<IStatisticsCashResponse>,
      ) => {
        if (groupKeys.length === 0) {
          return data.map((e) => {
            return { ...e, group: !!e.children && !!e.children.length };
          });
        }
        const key = groupKeys[0];
        for (let i = 0; i < data.length; i++) {
          const children = data[i].children || [];
          if (data[i].id === key) {
            return extractRowsFromData(groupKeys.slice(1), children.slice());
          }
        }
      };
      return extractRowsFromData(request.groupKeys, this.data);
    },
  };
  return server;
};
