import { createSlice } from '@reduxjs/toolkit';

export interface ICashHistoryParams {
  agentId: string;
  isIncludeSubAgent: boolean;
  startDate: string;
  endDate: string;
  transId: string;
  type: string;
  subType: string[];
}

export interface PointInitialState {
  dataCash: ICashHistoryParams | null;
}

const initialState: PointInitialState = { dataCash: null };
export const cashSlice = createSlice({
  name: 'cash',
  initialState: initialState,
  reducers: {
    setDataCash(state, action) {
      state.dataCash = action.payload;
    },
  },
});

export const { setDataCash } = cashSlice.actions;

export default cashSlice.reducer;
