import { createContext, FC, useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import { FORMAT_MODELS } from '@/interfaces';
import { useReadNotificationsMutation } from '@/apis/service/notifications';

interface ModalContextType {
  onShow: (bodyContent?: ModalBody) => void;
  onHide: () => void;
  onSubmit: () => void;
}
type ModalBody = {
  sender: string;
  title: string;
  content: string;
  receive_date: string;
  hasFooter: boolean;
  id: string;
};
const ModalContext = createContext<ModalContextType>({
  onHide: () => {
    // console.log('hide modal');
  },
  onShow: () => {
    // console.log('show modal');
  },
  onSubmit: () => {
    // console.log('submit');
  },
});

const ModalProvider: FC = ({ children }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const { mutate } = useReadNotificationsMutation();
  const [body, setBody] = useState<ModalBody>({
    content: '',
    receive_date: moment().format(FORMAT_MODELS.DATE),
    sender: '',
    title: '',
    hasFooter: true,
    id: '0',
  });
  const onShow = (bodyContent?: ModalBody) => {
    bodyContent && setBody(bodyContent);
    setVisible(true);
  };
  const onHide = () => {
    setVisible(false);
  };

  const onSubmit = () => {
    mutate({
      list_notification_id: [body.id],
    });
    setVisible(false);
  };

  return (
    <ModalContext.Provider
      value={{
        onHide,
        onShow,
        onSubmit,
      }}>
      {children}
      <Modal size="lg" centered scrollable show={visible}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">{body.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: body.content }}></div>
        </Modal.Body>
        {body.hasFooter && (
          <Modal.Footer>
            <Button form={'hook-form'} size="sm" variant="primary" type="submit" onClick={onSubmit}>
              Ok
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </ModalContext.Provider>
  );
};

export default ModalProvider;
export const useModalContext = () => useContext(ModalContext);
