export const COLORS = {
  white: 'white',
  border_grey: '#373a42',
  light_grey: '#e6e7e8 ',
  grey_1: 'rgb(182, 186, 207)',
  grey_2: '6E759F',
  orange: '#ff7b01',
  yellow: '#ffd900',
  green: '#04c142',
  blue: '#1c75ee',
  violet: '#9c6aff',
  violet_2: '#7066e0',
  dark_blue: '#001529',
  background_form_title: '#3F3B6C',
  violet_subTitle: 'rgb(63, 59, 108)',
  border_color: '#ced4da',
};

export const COLORS_STATISTIC = {
  orange: '#ff8100',
  yellow: '#ffc107',
  red: 'red',
  blue: '#198754',
  default: '#665d5d87',
};
