import { TODO, UnknownNumber } from '@/interfaces';
import { IIP } from '@/pages/agents/model';

export type AgentType = string;

export interface ListTreeResponse {
  id: string;
  agentName: string;
  initial: string;
  isDefaultValue: boolean;
  role: AgentType;
  children?: Array<ListTreeResponse>;
  checked?: boolean,
  ctr_id: string
}

export interface IRoleAgent {
  role_code?: string;
  role_name?: string;
}
export interface IPermission {
  permission_code?: string;
  permission_name?: string;
}

export interface UserInfoResponse {
  account_holder?: string;
  account_number?: string;
  agent_id?: string;
  agent_name?: string;
  alert_on_balance?: string;
  api_token?: string;
  bank_name?: string;
  callback_token?: string;
  callback_url?: string;
  cash_balance?: number;
  contact?: string;
  oper_mode?: null | number;
  created_date?: string;
  currency_code?: string;
  permissions?: Array<IPermission>;
  point_balance?: number;
  point_rate?: number;
  status?: string;
  role?: IRoleAgent;
  whitelist_ip?: Array<IIP>;
  note?: string[];
  blacklist_ip?: Array<IIP>;
  ip_filter_type?: string | null;
  ctr_id?: string;
}

export interface ToDoResponse {
  [key: string]: TODO;
}

export interface ChangePasswordPram {
  newPassword: string;
  newPasswordRetype: string;
  apiToken: string;
}

export interface AgentCreatePram {
  parent_agent: string;
  agent_name: string;
  created_agent: string;
  password: string;
  password_confirmation: string;
  commission_rate: number;
  role: number;
}

export interface BalanceChangePram {
  description: string;
  balance: number;
  uuid: string;
}

export enum TreeViewEnum {
  LIST_TREE_DATA = 'ListTreeData',
}

export interface AgentTreeItemProps {
  data?: ListTreeResponse;
}
