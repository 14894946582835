import { colorsText } from '@/helpers';
import { formatNumber } from '@/utils/helpers';
import { ICellRendererParams } from 'ag-grid-community';

const StatisticAgentPointEdit = (params: ICellRendererParams) => {
  return (
    <>
      <p style={{ color: colorsText(params.colDef?.colId) }} className="statisticVendorEdit-center">
        {formatNumber(Number(params.value) || 0)}{' '}
        {params.colDef?.field && ['ma_point_rate', 'rate_diff', 'agent_point_rate', 'parent_point_rate'].includes(params.colDef.field)
          ? '%'
          : null}
      </p>
    </>
  );
};
export default StatisticAgentPointEdit;
