import router from '@/router';
import { useEffect } from 'react';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline, Typography } from '@mui/material';
import { getMessaging, onMessage } from 'firebase/messaging';
import Cookies from 'js-cookie';

import { ACCESS_TOKEN } from '@/interfaces';
import { GlobalStyle } from './global-styles';
import { useAppDispatch } from './hooks/hooks';
import ThemeProvider from './theme/ThemeProvider';
import { toast } from 'react-toastify';
import ModalProvider from './components/ModalProvider';
import { useNotificationUncheck } from './apis/service/notifications';

function App() {
  const isLoggedIn = (localStorage.getItem(ACCESS_TOKEN) as string) || Cookies.get(ACCESS_TOKEN);
  const location = useLocation();
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const { refetch } = useNotificationUncheck();

  const content = useRoutes(router(Boolean(isLoggedIn)));

  useEffect(() => {
    localStorage.getItem('i18n') === undefined && localStorage.setItem('i18n', 'kr');
    if (location.pathname === '/' && !isLoggedIn) {
      navigation('/login');
    }
  }, [isLoggedIn, location.pathname, navigation]);

  useEffect(() => {
    // requestForToken().then((token) => {
    //   if (token) {
    //     dispatch(setFirebaseToken(token));
    //   }
    // });

    const unSubscribe = onMessage(getMessaging(), (payload) => {
      refetch();
      toast.success(
        <div>
          <p style={{ fontWeight: 'bold' }}>{payload.notification?.title}</p>
          <Typography // clamp 3 lines
            component="p"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '3',
              WebkitBoxOrient: 'vertical',
            }}>
            {payload.notification?.body}
          </Typography>
        </div>,
      );
    });

    return () => unSubscribe();
  }, [dispatch, refetch]);

  return (
    <ThemeProvider>
      <ModalProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <GlobalStyle />
          {content}
          {/* <NotificationAlert /> */}
        </LocalizationProvider>
      </ModalProvider>
    </ThemeProvider>
  );
}
export default App;
