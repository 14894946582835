import { IGroup, IParamsFilterGroupList } from '@/interfaces';

import { createSlice } from '@reduxjs/toolkit';

export interface GroupState {
  paramsFilter: Partial<IParamsFilterGroupList> | null;
  groupSelected: IGroup[];
  checkBoxSelected: boolean;
}

const initialState: GroupState = { paramsFilter: null, groupSelected: [], checkBoxSelected: false };
export const groupSlice = createSlice({
  name: 'groups',
  initialState: initialState,
  reducers: {
    setParamsFilter(state, action) {
      state.paramsFilter = action.payload;
    },
    setGroupSelected(state, action) {
      state.groupSelected = action.payload;
    },
    setCheckBoxSelected(state, action) {
      state.checkBoxSelected = action.payload;
    },
  },
});

export const { setParamsFilter, setGroupSelected, setCheckBoxSelected } = groupSlice.actions;
export default groupSlice.reducer;
