import { IDataPermission, IDataPermissionsById, UserInfoResponse } from '@/interfaces';
import { createSlice } from '@reduxjs/toolkit';

export interface IAgentInitialState {
  agentDetail: UserInfoResponse;
  permission: Array<IDataPermission>;
  searchPermission: string;
  permissionsById: IDataPermissionsById;
}

export const agentInitialState: IAgentInitialState = {
  agentDetail: {},
  permission: [],
  searchPermission: '',
  permissionsById:{}
};
export const agentSlice = createSlice({
  name: 'agents',
  initialState: agentInitialState,
  reducers: {
    agentDetail(state, action) {
      state.agentDetail = action.payload;
    },
    setAgentPermission(state, action) {
      state.permission = action.payload;
    },
    setSearchPermission(state, action) {
      state.searchPermission = action.payload;
    },
    setPermissionsById(state, action) {
      state.permissionsById = action.payload;
    },
  },
});

export const { agentDetail, setAgentPermission, setSearchPermission, setPermissionsById } = agentSlice.actions;

export default agentSlice.reducer;
