import requestApi from '@/apis/config';
import {
  IContentResponseApi,
  IDataCreateGroup,
  IGroupListResponse,
  IParamsFilterGroupList,
  IResponseUserList,
} from '@/interfaces';
import { MUTATIONS_KEY } from '@/interfaces/enum';
import { showError } from '@/utils/alert';
import { useMutation, useQuery } from '@tanstack/react-query';

const URL_CREATE_GROUP = '/noti-service/group/create';
const URL_GET_GROUP_LIST = '/noti-service/group/list';
const URL_DELETE_GROUP_LIST = '/noti-service/group/delete/list';
const URL_GROUP_DETAIL = '/noti-service/group/';

export const useCreateGroupMutation = (callback?: VoidFunction) => {
  return useMutation(
    async (data: IDataCreateGroup) => {
      const res = await requestApi.post(URL_CREATE_GROUP, data);
      return res;
    },
    {
      onError: () => {
        showError();
      },
    },
  );
};

export const useGetGroupListMutations = () => {
  return useMutation([MUTATIONS_KEY.GROUP_LIST], async (data: IParamsFilterGroupList) => {
    const res = await requestApi.post<IResponseUserList<IGroupListResponse>>(URL_GET_GROUP_LIST, data);
    return res;
  });
};

export const useDeleteGroupListMutation = () => {
  return useMutation(async (list_group_id: Array<string>) => {
    const res = await requestApi.delete(URL_DELETE_GROUP_LIST, { list_group_id });
    return res;
  });
};

export const useGroupDetail = (id: string) => {
  return useQuery([MUTATIONS_KEY.GROUP_DETAIL, id], async () => {
    const { data } = await requestApi.get<IContentResponseApi<Array<string>>>(URL_GROUP_DETAIL + id, {
      page_no: 0,
      page_size: 100,
      sort: 'desc',
    });
    return data;
  });
};
