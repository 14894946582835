import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStatisticPointByVendorChart } from '@/apis/service';
import { COLORS_STATISTIC } from '@/global-styles';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { ICellRendererParams } from 'ag-grid-community';
import { IChartData, IStatisticAgentByVendorChartDataResponse } from '@/interfaces';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const StatisticsChart: FC<ICellRendererParams & { game_type: string[] }> = (params) => {
  const { t } = useTranslation();
  const options = useMemo(
    () => ({
      responsive: true,
      plugins: {
        legend: {
          position: 'top' as const,
        },
      },
    }),
    [],
  );
  const { data: chartData } = useStatisticPointByVendorChart({
    agentId: params.colDef?.colId === 'company' ? null : params.data?.agentId,
    gameType: params.game_type,
    vendorId: params.data.vendorId,
  });
  const [data, setData] = useState<IChartData>({ labels: [], datasets: [] });
  useEffect(() => {
    (async () => {
      if (chartData) {
        const labels = Object.keys(chartData).map((e) => t('statistics.point_by_vendor.' + e));

        const totalBetting = [];
        const totalCancel = [];
        const totalProfit = [];
        const totalWin = [];

        for (const key in chartData) {
          const _key = key as keyof IStatisticAgentByVendorChartDataResponse;
          const element = chartData[_key];
          totalBetting.push(element.totalBetting || 0);
          totalCancel.push(element.totalCancel || 0);
          totalProfit.push(element.totalProfit || 0);
          totalWin.push(element.totalWin || 0);
        }
        const datasets = [
          {
            label: t('statistics.point_by_vendor.profit'),
            data: totalProfit,
            backgroundColor: COLORS_STATISTIC.orange,
          },
          {
            label: t('statistics.point_by_vendor.bet_money'),
            data: totalBetting,
            backgroundColor: COLORS_STATISTIC.yellow,
          },
          {
            label: t('statistics.point_by_vendor.prize_money'),
            data: totalWin,
            backgroundColor: COLORS_STATISTIC.red,
          },
          {
            label: t('statistics.point_by_vendor.cancellation_fee'),
            data: totalCancel,
            backgroundColor: COLORS_STATISTIC.blue,
          },
        ];
        setData({ labels, datasets });
      }
    })();
  }, [chartData, t]);

  return (
    <>
      <Bar options={options} data={data} />
    </>
  );
};
export default StatisticsChart;
